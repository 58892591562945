import { Tab, Tabs } from '@mui/material'
import React, { ReactElement } from 'react'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'

interface TabPanelProps {
  id: string
  selectedId: string
  children: React.ReactNode
  wrapperStyle?: React.CSSProperties
}

export const TabPanel = ({
  id,
  selectedId,
  children,
  wrapperStyle,
}: TabPanelProps): ReactElement | null => {
  if (id !== selectedId) {
    return null
  }
  return <Wrapper style={wrapperStyle}>{children}</Wrapper>
}

TabPanel.defaultProps = {
  wrapperStyle: {},
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: inherit;
`

export const StyledTab = styled(Tab)`
  font-family: Roboto, serif;
  text-transform: none;
  font-size: 18px;
  line-height: 24px;
  &.Mui-selected {
    font-weight: bold;
    color: ${defaultTheme.colors.black};
  }
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    font-size: 16px;
    padding: 0px;
    margin-right: 18px;
  }
`

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${defaultTheme.colors.primary};
    height: 3px;
  }
  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
`
