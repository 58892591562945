import {
  CartVehicle,
  OrderFormData,
  OrderLocation as ICartLocation,
} from 'src/store/models/ShoppingCartModels'
import { BaseServiceProvider } from './BaseServiceProvider'
import {
  LocationTransports,
  OrderCartData,
  OrderLabourInfo,
  OrderLocation,
  OrderPart,
  OrderRequestBody,
  OrderResponse,
  SpecsOrderInfo,
  Transport,
} from 'src/api/cart/interfaces'
import {
  Config,
  DeploymentEnvironment,
  IS_INTEGRATED_VERSION,
} from 'src/config/ConfigManager'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import {
  GetOrderHistoryFilters,
  OrderHistoryItem,
  OrderSearchCriteria,
  ResponseOrderHistory,
} from 'src/store/orderHistory/interfaces'
import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'
import { CatalogIndexTracker } from 'src/api/metrics/CatalogIndexTracker'
import { defaultCredentials } from 'src/aesInit'
import { orderTypeOptions } from 'src/helpers/orderTypes'
import { PUROLATOR } from 'src/features/cart/components/Purolator/constants'
import {
  isLocatePartMsg,
  mapCartProductsWithManyLocations,
  mapCartVehicleLaborsToLaborsInOrderRequestBody,
  mapCartVehicleSpecsToSpecsInOrderRequestBody,
} from 'src/store/cart/Utils'
import { getServiceName } from 'src/helpers/utils'

class OrderServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'order', '1.1', '')
  }

  makeOrder = async (
    vehicles: CartVehicle[]
  ): Promise<OrderResponse | undefined> => {
    const orderVehicles: Array<OrderCartData> = vehicles.map((v) => {
      const { locations: cartLocations } = v
      const orderProducts = mapCartProductsWithManyLocations(
        v.products,
        cartLocations
      )

      const updatedOrderParts = orderProducts.map((orderProduct) => {
        return {
          ...orderProduct,
          locatePartMsg: isLocatePartMsg(orderProduct),
        }
      })

      const specifications = mapCartVehicleSpecsToSpecsInOrderRequestBody(
        v.specifications
      )
      const labors = mapCartVehicleLaborsToLaborsInOrderRequestBody(
        v.laborResults
      )
      return {
        quoteId: v.quoteSourceId !== null ? Number(v.quoteSourceId) : null, // TODO: change quoteId type from string to number
        vehicle: {
          year: v?.vehicle?.year?.value,
          make: v?.vehicle?.make?.value,
          model: v?.vehicle?.model?.value,
          engine: v?.vehicle?.engine?.value,
        },
        locations: this.invertPartsLocations(
          updatedOrderParts,
          v.orderFormData,
          labors,
          specifications,
          cartLocations
        ),
        noteToStore: v.orderFormData.noteToStore,
        poNumber: v.orderFormData.poNumber,
        comment: v.orderFormData.personalNote,
      }
    })

    const orderRequest: OrderRequestBody = {
      isTestOrder:
        Config.deploymentEnvironment !== DeploymentEnvironment.production,
      cartData: orderVehicles,
    }
    const resp = await this.post<OrderResponse>(
      'multivehicleorder',
      orderRequest,
      {
        params: {
          buyerId:
            IS_INTEGRATED_VERSION &&
            EmbeddedCommunicationsManager.integrationInfo.allowAnyBuyer
              ? EmbeddedCommunicationsManager.getPartsBasketCreds().buyerId
              : undefined,
          catalogId: 111,
        },
        headers: {
          'Service-Name': getServiceName(),
          'Catalog-Index': CatalogIndexTracker.index,
          'Partner-Name': IS_INTEGRATED_VERSION
            ? EmbeddedCommunicationsManager.getPartsBasketCreds().partnerId
            : defaultCredentials.partnerId,
          // ...config.headers,
        },
      }
    )
    return resp?.data
  }

  invertPartsLocations = (
    parts: OrderPart[],
    form: OrderFormData,
    labors: Array<OrderLabourInfo>,
    specifications: Array<SpecsOrderInfo>,
    cartLocations: ICartLocation[]
  ): OrderLocation[] => {
    const locations: Record<string, OrderLocation> = {}
    parts.forEach((p) => {
      let location: OrderLocation = locations[p.locationId]
      if (!location) {
        location = this.createLocation(p, form, cartLocations)
        locations[p.locationId] = location
        location.labors = labors
        location.specifications = specifications
      }
      location.parts.push(p)
    })
    return Object.values(locations).sort(
      (l1, l2) => Number(l1.locationId) - Number(l2.locationId)
    )
  }

  createLocation = (
    p: OrderPart,
    form: OrderFormData,
    cartLocations: ICartLocation[]
  ): OrderLocation => {
    let orderType = p.orderType
    let deliveryMethod
    const {
      locationId,
      transportId,
      locationDescription,
      isBuyDirectLocation,
    } = p
    if (
      transportId === PUROLATOR &&
      StoreInstances.cart.checkPurolatorEligibilityOfLocation(
        Number(locationId)
      )
    ) {
      /*
        Note: In cart page, we set orderType = R, for a purolator transport, for UI purpose. Because in UI,
        We need to show the purolator transports under Regular order type. We are not showing Courier order type in UI.
        Here we need to change the orderType from 'R'(Regular) to 'C'(Courier) for a purolator transport.
        Because backend expects the order type to be 'C' and we need to get the courier transports 
        from availableTransports(transports API)
      */
      orderType = 'C'
      deliveryMethod = 'Courier'
    } else {
      deliveryMethod = orderTypeOptions.find(
        (option) => option.value === orderType
      ).label
    }
    const locationIdForOrderTypeTransport = isBuyDirectLocation
      ? locationId
      : '100'
    const availableTransports = StoreInstances.cart.getAvailableTransports(
      locationIdForOrderTypeTransport,
      orderType
    )

    let transport: Transport
    let courierMethod
    if (transportId === PUROLATOR) {
      const serviceId = cartLocations.find(
        (eachLocation) =>
          eachLocation.locationId.toString() === locationId.toString()
      )?.carrierService?.serviceId

      transport = (availableTransports || []).find(
        (eachTransport) => eachTransport.carrier === serviceId
      )
      courierMethod = serviceId
    } else {
      transport = (availableTransports || []).find(
        (eachTransport) => eachTransport.id === transportId
      )
    }

    const { carrier = '', shipCode = '', releaseCode = '' } = transport || {}

    const address = StoreInstances.cart.getAddressesByID(form.shipToAddress)

    return {
      locationId,
      locationDescription,
      deliveryDetail: {
        deliveryMethod,
        name: form.customerName,
        attentionToUser: form.personalNote,
        courierMethod,
        carrier,
        shipCode,
        releaseCode,
        address,
      },
      parts: [],
    }
  }

  getTransports = async (): Promise<LocationTransports[]> => {
    try {
      const resp = await this.get<LocationTransports[]>('transports', {
        params: [],
      })
      return resp?.data
    } catch (e) {
      return []
    }
  }

  getOrderHistory = async (
    orgId: string,
    start: number,
    limit: number,
    filters?: GetOrderHistoryFilters,
    sort?: 'orderedDate' | 'quantity' | 'totalCost'
  ): Promise<ResponseOrderHistory> => {
    const wdOrgId = StoreInstances.userStore.preferences?.wdorgId
    const treePath = StoreInstances.userStore.preferences?.treePath
    const orderSearchHistoryCriteria: OrderSearchCriteria = {
      orgId,
      wdOrgId,
      wdAdmin: false,
      treePath,
      searchTerm: filters?.searchTerm || '',
      poNumber: filters?.poNumber || '',
      confirmationNbr: filters?.confirmationNbr || '',
      location: filters?.location || '',
      partNumber: filters?.partNumber || '',
      noteTxt: filters?.noteTxt || '',
      vehicle: filters?.vehicle || {
        year: '',
        make: '',
        model: '',
        engine: '',
      },
      orderId: '',
      invoiceNbr: '',
      customerName: '',
      fromDate: filters?.fromDate ?? filters?.fromDate,
      toDate: filters?.toDate ?? filters?.toDate,
      start: start || 0,
      limit: limit || 10,
    }
    if (sort) {
      orderSearchHistoryCriteria.sortorder = {
        sortBy: sort,
        dir: 'desc',
      }
    }
    let resp
    const urlString = `order/history`
    const urlEncoded = encodeURI(urlString)
    try {
      resp = await this.post<ResponseOrderHistory>(
        urlEncoded,
        orderSearchHistoryCriteria
      )
      if (resp.status === 200) {
        const orderHistoryData: ResponseOrderHistory = {
          ...resp.data,
          orders: resp.data.orders?.map((order: OrderHistoryItem) => {
            return {
              ...order,
              header: {
                ...order.header,
                createdOn: order.isMyPlaceOrder
                  ? order.header?.createdOn
                  : order.ddsDetails[0]?.order?.orderDate,
                orderId: order.isMyPlaceOrder
                  ? order.header?.orderId
                  : order.ddsDetails[0]?.order?.uid,
                confirmationNbr: order.isMyPlaceOrder
                  ? order.header?.confirmationNbr
                  : order.ddsDetails[0]?.order?.confirmationNumber,
                totalCostPrice: order.isMyPlaceOrder
                  ? order.header?.totalCostPrice?.toFixed(2)
                  : order.ddsDetails[0]?.order?.totalCost?.toFixed(2),
                totalQty: order.isMyPlaceOrder
                  ? order.header?.totalQty
                  : order.ddsDetails[0]?.order?.totalCount,
                poNumber: order.isMyPlaceOrder
                  ? order.header?.poNumber
                  : order.ddsDetails[0]?.order?.purchaseOrderNumber,
              },
            }
          }),
        }
        return orderHistoryData
      }
      if (resp.status === 204) {
        return { totalCount: 0, orders: [] }
      }
    } catch (error) {
      throw new Error(error.toString())
    }
    return { totalCount: 0, orders: [] }
  }

  getOrderById = async (
    orderId: string,
    isMyPlaceOrder?: boolean
  ): Promise<OrderHistoryItem> => {
    const urlString = isMyPlaceOrder
      ? `order/${orderId}`
      : `/order/invoice/${orderId}`
    const urlEncoded = encodeURI(urlString)
    try {
      const resp = await this.get<OrderHistoryItem>(urlEncoded)
      if (resp.status === 200) {
        return resp.data
      }
    } catch (error) {
      throw new Error('Order not found')
    }
    return {} as OrderHistoryItem
  }
}

export default new OrderServiceProvider()
