import {
  IPartItemResponse,
  IdGroupsResponse,
  PartTypeItem,
} from 'src/features/partsCatalog/Selections/interfaces'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import axios from 'axios'
import { Config } from 'src/config/ConfigManager'
import { PartsBasketCreds } from 'src/api/security/interfaces'
import { PartsCatalogAPI } from './PartsCatalogAPI'
import authManager from '../../../api/security/Auth'

interface AstItem {
  label: string
  value: string
  count: number
  path: string
}

export interface IntegrationsInfoResponse {
  epeFlag: boolean
  allowAnyBuyer: boolean
  authAttributesId: string
}

export const defaultIntegrationInfo: IntegrationsInfoResponse = {
  epeFlag: true,
  allowAnyBuyer: false,
  authAttributesId: '',
}

export class AstPunchOutCatalogAPI extends PartsCatalogAPI {
  BRAND_ENDPOINT

  INTEGRATIONS_ENDPOINT

  constructor() {
    super()
    this.BRAND_ENDPOINT = 'astBrands'
    this.ENDPOINT_BASE = Config.urls.catalog
    this.CATEGORY_ENDPOINT = 'astCategories'
    this.GROUP_ENDPOINT = 'astGroups'
    this.TERMINOLOGY_ENDPOINT = 'astTerminologies'
    this.SESSION_STORAGE_KEY = 'selected-astpunchout-catalog-tree'
    this.INTEGRATIONS_ENDPOINT = 'integrationsInfo'
  }

  fetchBrands = async (): Promise<Array<IdValueGeneric<string, string>>> => {
    const resp = await axios.get<AstItem[]>(
      `${this.ENDPOINT_BASE}/${this.BRAND_ENDPOINT}`,
      {
        headers: {
          Authorization: await authManager.getCurrentToken(),
        },
      }
    )

    const brands = resp.data?.map((c): IdValueGeneric<string, string> => {
      return { id: c.value, value: c.label }
    })

    return brands
  }

  fetchCategories = async (): Promise<
    Array<IdValueGeneric<string, string>>
  > => {
    const categoryEndpoint = `${this.ENDPOINT_BASE}/${this.CATEGORY_ENDPOINT}`

    const resp = await axios.get<AstItem[]>(categoryEndpoint, {
      headers: {
        Authorization: await authManager.getCurrentToken(),
      },
    })

    if (resp.status === 204) return []

    const categories = resp?.data?.map((c): IdValueGeneric<string, string> => {
      return { id: c.value, value: c.label }
    })

    return categories
  }

  fetchGroups = async (
    categories: Array<IdValueGeneric<string, string>>
  ): Promise<IdGroupsResponse> => {
    const category = categories?.[0]
    const endcodedCategory = encodeURIComponent(category.id)
    const resp = await axios.get<AstItem[]>(
      `${this.ENDPOINT_BASE}/${this.GROUP_ENDPOINT}?category=${endcodedCategory}`,
      {
        headers: {
          Authorization: await authManager.getCurrentToken(),
        },
      }
    )

    const groupItems = resp.data?.map((g): PartTypeItem => {
      return {
        id: g.value,
        value: g.label,
        isConsolidated: true,
      }
    })

    return [
      {
        ...category,
        items: groupItems,
      },
    ]
  }

  fetchPartTypes = async (
    groups: Array<IdValueGeneric<string, string>>
  ): Promise<IPartItemResponse> => {
    const group = groups?.[0]
    const encodedGroup = encodeURIComponent(group.id)
    const resp = await axios.get<AstItem[]>(
      `${this.ENDPOINT_BASE}/${this.TERMINOLOGY_ENDPOINT}?group=${encodedGroup}`,
      {
        headers: {
          Authorization: await authManager.getCurrentToken(),
        },
      }
    )

    const partTypeItems = resp.data?.map((pt): PartTypeItem => {
      return {
        id: pt.value,
        value: pt.label,
        isConsolidated: true,
      }
    })

    return [
      {
        ...group,
        items: partTypeItems,
      },
    ]
  }

  fetchIntegrationsInfo = async (
    credentials: PartsBasketCreds
  ): Promise<IntegrationsInfoResponse> => {
    const integrationsEndpoint = `${this.ENDPOINT_BASE}/${this.INTEGRATIONS_ENDPOINT}`
    const parameters = {
      partnerId: credentials.partnerId,
      sellerId: credentials.sellerId,
      buyerId: credentials.buyerId,
    }

    try {
      const resp = await axios.get<IntegrationsInfoResponse>(
        integrationsEndpoint,
        {
          headers: {
            Authorization: await authManager.getCurrentToken(),
          },
          params: parameters,
        }
      )

      if (resp?.status === 204) {
        return defaultIntegrationInfo
      }
      return resp?.data
    } catch (e) {
      return defaultIntegrationInfo
    }
  }

  isGfxSupported = (): boolean => {
    return false
  }
}

export default new AstPunchOutCatalogAPI()
