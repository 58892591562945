import {
  DecodedVehicleResponse,
  VehicleDetail,
  VehicleHistoryFilters,
} from 'src/api/vehicle/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'
import {
  VehicleHistoryItem,
  VehiclePreviousSearches,
  VehicleResponseError,
  VehicleSearchCriteria,
  VehicleDetail as VehicleHistoryDetail,
} from 'src/store/vehicleHistory/interfaces'
import { IVehicleInfo } from 'src/store/vehicleHistory/VehicleHistoryStore'
import { AxiosError } from 'axios'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { ISearchHistorySearchCriteria } from 'src/features/vehicleHistory/components/SubVehicleRow'
import { IPartsBasketVehicleInfo } from 'src/api/embedded/types/partsBasket-1.1'
import { Vehicle } from 'src/store/models/Vehicles'
import { TableColumn } from 'src/features'
import UserDataServiceProvider from './UserDataServiceProvider'
import CatalogServiceProvider from './CatalogServiceProvider'
import { VehicleSelectorStep } from 'src/ui-components/util/Interfaces'

class VehicleServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'vehicle', '1.1', '')
  }

  /**
   * Wrapper to simplify API calls for all vehicle option steps
   * @param step
   * @param currentSelections
   */
  getSelectorOptions = async (
    step: VehicleSelectorStep,
    currentSelections?: Vehicle,
    filterMakes?: boolean
  ): Promise<Array<TableColumn>> => {
    switch (step) {
      case VehicleSelectorStep.TYPE:
        return UserDataServiceProvider.getTypeOptions()
      case VehicleSelectorStep.YEAR:
        return CatalogServiceProvider.getYearOptions()
      case VehicleSelectorStep.MAKE:
        return CatalogServiceProvider.getMakeOptions(
          currentSelections,
          filterMakes
        )
      case VehicleSelectorStep.MODEL:
        return CatalogServiceProvider.getModelOptions(currentSelections)
      case VehicleSelectorStep.ENGINE:
        return CatalogServiceProvider.getEngineOptions(currentSelections)
      default:
        return []
    }
  }

  getVehicleHistory = async (
    filters?: VehicleHistoryFilters
  ): Promise<VehicleHistoryItem[]> => {
    const today = new Date()
    const priorDate = new Date(new Date().setDate(today.getDate() - 30))
    const vehicleSearchCriteria: VehicleSearchCriteria = {
      isFavorite: false,
      name: '',
      groupName: '',
      lookupSource: filters?.lookupSource || '',
      fromDate: priorDate.toISOString(),
      toDate: today.toISOString(),
    }
    let resp
    const urlString = `vehicle/history?filter=${JSON.stringify(
      vehicleSearchCriteria
    )}&isSpecificVehicle=false`
    const urlEncoded = encodeURI(urlString)
    try {
      resp = await this.get<Array<VehicleHistoryItem>>(urlEncoded)
      if (resp.status === 200) {
        return resp.data && resp.data.length > 0 ? resp.data : []
      }
      if (resp.status === 204) {
        return []
      }
    } catch (error) {
      throw new Error(error.toString())
    }
    return []
  }

  getVehicleById = async (vehicleId: number): Promise<VehicleDetail> => {
    let resp
    try {
      resp = await this.get<VehicleDetail>(`vehicle/byId/${vehicleId}`)
      if (resp.status === 200) {
        return resp.data
      }
    } catch (error) {
      throw new Error(error.toString())
    }
    return null
  }

  updateVehicle = async (
    vehicleId: string,
    vehicle: IVehicleInfo
  ): Promise<void> => {
    try {
      await this.patch<void>(`vehicle/byId/${vehicleId}`, vehicle)
    } catch (error) {
      const { message }: VehicleResponseError = (error as AxiosError).response
        .data as VehicleResponseError
      if (message === null || message === '' || message === undefined) {
        StoreInstances.uiStore.displayErrorNotification(
          'errorOccurredWhileUpdatingVehicle'
        )
      } else {
        StoreInstances.uiStore.displayErrorNotification(message)
      }
      throw new Error(error.toString())
    }
  }

  getPreviousVehicleSearches = async (
    vehicleId: string,
    searchCriteria: ISearchHistorySearchCriteria
  ): Promise<Array<VehiclePreviousSearches>> => {
    try {
      const response = await this.get<VehiclePreviousSearches[]>(
        `vehicle/${vehicleId}/searchHistory`,
        {
          params: {
            filter: JSON.stringify(searchCriteria),
          },
        }
      )
      return response.data
    } catch (error) {
      throw new Error(error.toString())
    }
  }

  /**
   * Returns a decoded and validated Vehicle based on the information provided from the integration partner
   */
  getDecodedAndValidatedYMME = async (
    partsBasketVehicle: IPartsBasketVehicleInfo,
    catId?: number
  ): Promise<Vehicle> => {
    const decodedVehicleResp = await this.post<DecodedVehicleResponse>(
      'vehicle/decode',
      {
        vehicleInfo: partsBasketVehicle,
      },
      {
        params: {
          catalogId: catId ?? 111,
          covListId: StoreInstances.userStore.preferences?.mclOrgId,
          countryId: StoreInstances.userStore.country.countryCode,
          mclOrgId: StoreInstances.userStore.preferences?.mclOrgId,
        },
      }
    )

    const decodedVehicle = decodedVehicleResp.data
    return {
      id: decodedVehicle.id,
      name: decodedVehicle.name,
      vin: decodedVehicle.vin,
      state: decodedVehicle.state,
      plate: decodedVehicle.plate,
      decodeSource: decodedVehicle.decodeSource,
      type: decodedVehicle.type,
      year: {
        id: decodedVehicle.yearCode,
        value: decodedVehicle.year,
      },
      make: {
        id: decodedVehicle.makeCode,
        value: decodedVehicle.make,
      },
      modelType: {
        id: decodedVehicle.modelTypeCode,
        value: decodedVehicle.modelType,
      },
      model: {
        id: decodedVehicle.modelCode,
        value: decodedVehicle.model,
      },
      engine: {
        id: decodedVehicle.engineCode,
        value: decodedVehicle.engine,
      },
    }
  }

  getVehicleDetails = async (vin: string): Promise<VehicleHistoryDetail> => {
    try {
      const response = await this.get<VehicleHistoryDetail>(
        `vehicle/vin/${vin}/`,
        {
          params: {
            catalogId: 111,
            covListId: StoreInstances.userStore.preferences?.mclOrgId,
            countryId: StoreInstances.userStore.country.countryCode,
            mclOrgId: StoreInstances.userStore.preferences?.mclOrgId,
          },
        }
      )
      return response.data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  getSelectedMakes = async (): Promise<string[]> => {
    return await CatalogServiceProvider.getSelectedMakes()
  }

  getAllMakeOptions = async (): Promise<Array<TableColumn>> => {
    return await CatalogServiceProvider.getAllMakeOptions()
  }

  updateCustomMakes = async (makes: Array<string>): Promise<void> => {
    await CatalogServiceProvider.updateCustomMakes(makes?.join(','))
  }

  updateMakesFilterStatus = async (status: boolean): Promise<void> => {
    return await CatalogServiceProvider.updateMakesFilterStatus(status)
  }

  isMakesFilterEnabled = async (): Promise<boolean> => {
    return await CatalogServiceProvider.isMakesFilterEnabled()
  }
}

export default new VehicleServiceProvider()
