import { ReactElement } from 'react'
import { TopNavigationBar } from 'src/features'
import {
  LogoImage,
  WhiteBackgroundEnd,
  BlackBackground,
  RotatedBlackLine,
  ShoppingCartMenuIcon,
} from 'src/features/navigation/TopNavigation/components/CommonMenuItems'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { HeaderProps } from './HeaderProps'

const CartOnlyHeader = (props: HeaderProps): ReactElement => {
  const { logoUrl } = props
  const cart = useShoppingCart()
  return (
    <TopNavigationBar>
      <BlackBackground>
        <LogoImage src={logoUrl} alt="My place 4 parts logo" />
      </BlackBackground>
      <RotatedBlackLine />
      <WhiteBackgroundEnd>
        <ShoppingCartMenuIcon qty={cart.totalCartQty} />
      </WhiteBackgroundEnd>
    </TopNavigationBar>
  )
}

export default CartOnlyHeader
