import {
  DirectionsCar,
  ExpandLess,
  ExpandMore,
  Close,
} from '@mui/icons-material'

import { ReactElement } from 'react'
import { I18n, Translate } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
} from '../../VehicleSearch/store/VehicleWidgetStore'
import { defaultTheme } from 'src/theme'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useHistory } from 'react-router-dom'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import ClearVehicleMenu from './ClearVehicleMenu'

interface VehicleInfoProps {
  year?: string
  model?: string
  engine?: string
  onClick: () => void
  open: boolean
}

const VehicleInfo = (props: VehicleInfoProps): ReactElement => {
  const { year, model, engine, open, onClick } = props

  const searchStore = useSearchStore()
  const history = useHistory()
  const { currentVehicle } = searchStore

  const subModel = currentVehicle.vehicleSpecs?.find(
    (obj) => obj.label === 'Series'
  )?.value

  const primaryText =
    year && model ? `${year} ${model}` : Translate('addVehicle')

  const secondaryText =
    year && model ? `${engine}` : Translate('selectAVehicle')

  const engineInfo = year && model ? `${engine}` : ''

  const clearText = year && model ? true : false

  const vehicleWidgetStore = useVehicleWidgetStore()
  const headerText =
    vehicleWidgetStore.displayState === VehicleWidgetDisplayState.edit && open
      ? Translate('editVehicle')
      : `${year} ${model}`

  const handleDeleteVehicle = () => {
    if (
      location.pathname === '/partsCatalog' ||
      location.pathname === '/searchResults'
    )
      history.push('home')
    searchStore.deleteCurrentVehicle()
    vehicleWidgetStore.setIsDrawerOpened(false)
    vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.select)
    StoreInstances.uiStore.displaySuccessNotification(
      'vehicleSuccessfullyDeleted'
    )
  }

  return (
    <>
      <VehicleRow aria-controls="collapse-vehicle-widget" aria-expanded={open}>
        <DirectionsCarIcon />
        <VehicleInfoContainerDesktop>
          <SelectedVehicleRow>
            <MainText>{primaryText}</MainText>
            <ModelContainer>
              {subModel?.length > 0 && subModel && (
                <SubmodelText>{subModel}</SubmodelText>
              )}
              <SecondaryText>{engineInfo}</SecondaryText>
            </ModelContainer>
          </SelectedVehicleRow>
          <ClearVehicleRow>
            {!clearText ? (
              <SelectVehicleText onClick={onClick}>
                {Translate('selectAVehicle')}
              </SelectVehicleText>
            ) : (
              <>
                <ClearText
                  onClick={() => {
                    vehicleWidgetStore.setIsDrawerOpened(true)
                    vehicleWidgetStore.setDisplayState(
                      VehicleWidgetDisplayState.edit
                    )
                  }}
                >
                  <I18n name="change"></I18n>
                </ClearText>
                <ClearTextDevider></ClearTextDevider>
                <ClearText onClick={handleDeleteVehicle}>
                  <I18n name="clear"></I18n>
                </ClearText>
                <ClearVehicleMenu></ClearVehicleMenu>
              </>
            )}
            {!open ? (
              <ExpandMore onClick={onClick} />
            ) : (
              <ExpandLess onClick={onClick} />
            )}
          </ClearVehicleRow>
        </VehicleInfoContainerDesktop>
      </VehicleRow>

      <RowMobile
        onClick={() =>
          vehicleWidgetStore.setIsDrawerOpened(
            !vehicleWidgetStore.isDrawerOpened
          )
        }
        aria-controls="collapse-vehicle-widget"
        aria-expanded={open}
      >
        <VehicleInfoContainer>
          <InnerRowMobile>
            <DirectionsCarIcon />

            {year && model ? (
              <FlexWrapper>
                <MainText>{headerText}</MainText>

                <InnerRowMobile>
                  <SubmodelTextMobile>
                    {subModel && subModel}
                    {subModel && ' | '}
                    {secondaryText && (
                      <SecondaryTextMobile>{secondaryText}</SecondaryTextMobile>
                    )}
                  </SubmodelTextMobile>
                </InnerRowMobile>
              </FlexWrapper>
            ) : (
              <MainText>{primaryText}</MainText>
            )}
          </InnerRowMobile>
          <InnerRowMobile>
            {!open ? (
              <ExpandMore />
            ) : (
              <Cancel>
                <CloseIcon />
                <CancelText>Cancel</CancelText>{' '}
              </Cancel>
            )}
          </InnerRowMobile>
        </VehicleInfoContainer>
      </RowMobile>
    </>
  )
}

VehicleInfo.defaultProps = {
  year: '',
  model: '',
}

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
  @media print {
    display: none;
  }
`

const SelectedVehicleRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
  @media print {
    display: none;
  }
`

const ClearVehicleRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
  @media print {
    display: none;
  }
  @media (max-width: 1280px) {
    gap: 6px;
  }
`

const VehicleRow = styled(Row)`
  width: 527px;
  height: 56px;
  padding: 12px 16px;
  border: 1px solid ${defaultTheme.colors.greyBorder};
  border-radius: 3px;
  @media (max-width: 1280px) {
    width: 391px;
  }
`

const RowMobile = styled.div`
  display: none;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    border: 1px solid black;
    border-radius: 3px;
    width: 100%;
    height: 56px;
    padding: 10px 11px 11px 12px;
    font-size: 18px;
    cursor: pointer;
  }
`

const InnerRowMobile = styled.div`
  display: flex;
  cursor: pointer;
`

const VehicleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding-left: 15px;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: row;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const VehicleInfoContainerDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding-left: 10px;
  width: inherit;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    flex-direction: row;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const MainText = styled.div`
  align-items: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  min-width: 87px;
`

const SecondaryText = styled.div`
  align-items: left;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
`

const ClearText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  @media (max-width: 1280px) {
    display: none;
  }
`

const ClearTextDevider = styled.div`
  height: 24px;
  border: ${defaultTheme.fieldBorder};
  @media (max-width: 1280px) {
    display: none;
  }
`

const SelectVehicleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
`

const SecondaryTextMobile = styled.span`
  color: ${defaultTheme.colors.columnBorder};
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
const ModelContainer = styled.div`
  display: flex;
`

const SubmodelText = styled.div`
  color: ${defaultTheme.colors.almostBlack};
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  margin-right: 8px;
`
const SubmodelTextMobile = styled.span`
  color: ${defaultTheme.colors.black};
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DirectionsCarIcon = styled(DirectionsCar)`
  font-size: 32px;
  margin-left: -6px;
  font-family: 'Roboto';

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    font-size: 22px;
    margin: 3px 11px 0px 0px;
  }
`

const Cancel = styled.div`
  color: ${defaultTheme.colors.link};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const CloseIcon = styled(Close)`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2px;
`

const CancelText = styled.span`
  margin: 12px 0px 0px 4.75px;
`

export default VehicleInfo
