import { IPartsBasketVehicleInfo } from 'src/api/embedded/types/partsBasket-1.1'
import { ISelectedOption } from 'src/features/search/AllProductsSearch/LanguagesDropdown'
import { MultiPartInquiry } from 'src/features/search/AllProductsSearch/interfaces'
import { Vehicle } from 'src/store/models/Vehicles'
import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { getCxmlDetails } from './cxmlDetailsUtil'

enum ServiceName {
  AESV3 = 'AESV3',
  MYPLACEV3 = 'MyPlaceV3',
  AES_SERVER_V3 = 'aesServerV3',
  CXML = 'cXML',
}

export const random = (): string => {
  return Math.random().toString(36).slice(2)
}

export const getYMMENumberFromVehicle = (vehicle: Vehicle): string =>
  `${vehicle.year?.id},${vehicle.make?.id},${vehicle.model?.id},${vehicle.engine?.id}`

export const getYMMEFromVehicle = (vehicle: Vehicle): string =>
  `${vehicle.year?.value},${vehicle.make?.value},${vehicle.model?.value},${vehicle.engine?.value}`

export const getLaborRateFromCurrencyString = (laborRate: string): string =>
  laborRate.replace('$', '')

export const hasVehicleInfoDetails = (
  vehicleInfo?: IPartsBasketVehicleInfo
): boolean => {
  if (!vehicleInfo) {
    return false
  }
  return Boolean(
    vehicleInfo.ymmeNo ||
      vehicleInfo.ymme ||
      vehicleInfo.vin ||
      vehicleInfo.licencePlate ||
      vehicleInfo.aaia ||
      vehicleInfo.aces
  )
}

export const getSRCForImage = (urlOrBase64: string): string => {
  const isBase64 = !urlOrBase64?.startsWith('http')
  const src = isBase64 ? `data:image/jpeg;base64,${urlOrBase64}` : urlOrBase64
  return src
}

export const getBlobUrlFromBase64 = (urlOrBase64: string): string => {
  const base64ImageData = getSRCForImage(urlOrBase64)
  const contentType = 'image/jpeg'
  const byteCharacters = atob(
    base64ImageData.substr(`data:${contentType};base64,`.length)
  )
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: contentType })
  const blobUrl = URL.createObjectURL(blob)
  return blobUrl
}

export const capitaliseOption = (option: ISelectedOption): string => {
  return option.label.charAt(0).toUpperCase() + option.label.slice(1)
}

export const download = (
  data: string,
  filename: string,
  type: string
): void => {
  const file = new Blob([data], { type })
  // @ts-expect-error Code copied from aes To maintain compatibility.
  if (window.navigator.msSaveOrOpenBlob)
    // @ts-expect-error Code copied from aes To maintain compatibility.
    window.navigator.msSaveOrOpenBlob(file, filename)
  else {
    const a = document.createElement('a')
    const url = URL.createObjectURL(file)

    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export const createMultiPartInquiryBreadcrumb = (
  partsToSearch: MultiPartInquiry[]
): string => {
  const breadCrumbParts: string[] = []
  partsToSearch.forEach((part) => {
    breadCrumbParts.push(part.partNumber)
  })

  if (breadCrumbParts.length < 3) {
    return breadCrumbParts.join(' | ')
  } else {
    return `${breadCrumbParts.slice(0, 2).join(' | ')} +${
      breadCrumbParts.length - 2
    } more`
  }
}

export const getServiceName = (): ServiceName => {
  if (IS_INTEGRATED_VERSION) {
    return EmbeddedCommunicationsManager.isAesServer
      ? ServiceName.AES_SERVER_V3
      : ServiceName.AESV3
  }
  if (getCxmlDetails().cxmlData) {
    return ServiceName.CXML
  }

  return ServiceName.MYPLACEV3
}
