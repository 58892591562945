import { init, setUser } from '@sentry/react'
import { SENTRY_DSN } from 'src/config/constants'

// https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
interface ISentryUser {
  id?: string
  username?: string
  email?: string
}

const isSentryEnabled = (): boolean => {
  return (
    SENTRY_DSN &&
    !window.location.origin.includes('localhost') &&
    !window.location.origin.includes('127.0.0.1')
  )
}

export const initSentry = (): void => {
  if (isSentryEnabled()) {
    init({
      dsn: SENTRY_DSN,
      environment: import.meta.env.VITE_MODE,
    })
  }
}

export const addUserToSentry = (user: ISentryUser | null): void => {
  if (isSentryEnabled()) {
    setUser(user)
  }
}
