/**
 * Manages communications between the embedded partner app and this application.
 *
 * These communications take the form of postMessages between the wrapping third-party app and the
 * React app, which is embedded into the third-party app as an iFrame.
 *
 * If the third-party app is a native/desktop application, it can also open the react app using webview or similar technology.
 * In that case they mostly use `window.external` methods to communicate with the host app.
 *
 * Some integrations can also inject custom javascript to handle things.
 */
import {
  OrderSelection,
  ShoppingCartProduct,
} from 'src/store/models/ShoppingCartModels'

import { Config } from '../../config/ConfigManager'
import { GaTrackOption } from '../../config/analytics/GoogleTagManager'
import { PartsBasketCreds } from '../security/interfaces'
import {
  IPartsBasket11,
  IPartsBasketLine11,
  IPartsBasketSearchInfo,
  IPartsBasketVehicleInfo,
} from './types/partsBasket-1.1'
import { PartsBasketConverter } from './PartsBasketConverter'
import {
  CheckAvailabilityRequest,
  CheckAvailabilityResponse11,
  CheckAvailabilityResponse12,
} from './CheckAvailability'
import { CartOnlyCommunications } from './CartOnlyCommunications'
import {
  DeliveryDetail,
  OrderResponse,
  OrderResponseDetail,
} from '../cart/interfaces'
import { StoreInstances } from '../../store/StoreInstancesContainer'
import {
  CartButtonValues,
  IParsedGoShoppingParams,
  LineType,
  PartsBasketAction,
  partsBasketResponseType,
} from './types/partsBasketSpec'
import { searchResultsToPartsBasket } from './utils/localInventoryUtils'
import PartnerCommunicationManager, {
  IBasePartnerCommunicationManager,
} from './partnerCommunicationManager'
import {
  getYMMEFromVehicle,
  getYMMENumberFromVehicle,
} from '../../helpers/utils'
import { IPartsBasket12 } from './types/partsBasket-1.2'
import { IPartsBasketV10 } from './types/partsBasket-1.0'
import { VehicleSpecificationItem } from '../../store/models/VehicleSpecification'
import { LaborItem } from '../../store/models/LaborModel'
import { SearchResultsResponse } from '../../store/models/SearchModels'
import type { ProductModel } from '../../store/models/ProductModel'
import type { ShoppingCart } from '../../store/cart/ShoppingCart'
import AstPunchOutCatalog, {
  IntegrationsInfoResponse,
  defaultIntegrationInfo,
} from 'src/store/partsCatalog/api/AstPunchOutCatalogAPI'
import { getLocationById } from 'src/store/cart/Utils'

export class EmbeddedCommunications {
  private detectedPartsBasketVersion = '1.0'

  private partnerCommunicationManager?: IBasePartnerCommunicationManager

  private localInventory = false
  // The initial basket received from the host application. This can have useful info like action, uid etc.
  public incomingPartsBasket?: IPartsBasketV10 | IPartsBasket11 | IPartsBasket12

  private cartButton: CartButtonValues | undefined = CartButtonValues.BOTH

  private partsBasketCreds?: PartsBasketCreds

  public searchInfo?: IPartsBasketSearchInfo

  public vehicleInfo?: IPartsBasketVehicleInfo

  public integrationInfo: IntegrationsInfoResponse = defaultIntegrationInfo

  // if the app is launched from aesServer integration.
  private aesServer: boolean = false

  get isAstPunchOut(): boolean {
    return !this.integrationInfo.epeFlag
  }

  public init = async (data: IParsedGoShoppingParams): Promise<void> => {
    // To debug integrations opening the app by passing query params.
    // eslint-disable-next-line no-console
    console.log('Initialized with URI: ', window.location.href, data)

    if (!data || !data.partnerId) {
      throw new Error('Partner ID not provided')
    }

    let checkAvailabilityRequest: CheckAvailabilityRequest

    this.partsBasketCreds = {
      partnerId: data.partnerId,
      sellerId: data.sellerId,
      buyerId: data.buyerId,
    }

    this.aesServer = data.aesServer

    this.localInventory = data.localInventory
    this.cartButton = data.cartButton

    PartnerCommunicationManager.initializeCommunicationManager(data.partnerId)
    this.partnerCommunicationManager =
      PartnerCommunicationManager.getPartnercommunicationmanager()

    // Fetching the integration info.
    this.integrationInfo = await AstPunchOutCatalog.fetchIntegrationsInfo(
      this.partsBasketCreds
    )

    const partsBasket = EmbeddedCommunications.findPayload(data.partsBasket)

    if (partsBasket) {
      // If an integration is not passing version,
      // assume it as 1.0 as only integrations using the older version won't be passing the version info.
      this.detectedPartsBasketVersion = partsBasket.version || '1.0'

      this.incomingPartsBasket = partsBasket

      const { searchInfo, vehicleInfo } =
        PartsBasketConverter.getPartsBasketInfo(partsBasket)
      this.searchInfo = searchInfo
      this.vehicleInfo = vehicleInfo

      // TODO: Need to revisit the cart only mode once discussions resume with shopware.
      if (
        data.service === 'checkAvailability' ||
        ('action' in partsBasket &&
          // TODO: check if we are suposed to use `checkAvailabilityRequest` here.
          partsBasket?.action === 'checkAvailabilityResponse')
      ) {
        checkAvailabilityRequest = partsBasket
      }
    }

    // TODO: Need to revisit the cart only mode once discussions resume with shopware.
    if (data.checkAvailabilityRedirect) {
      Config.setCartOnlyMode()
      this.cartButton = CartButtonValues.ORDER
      this.detectedPartsBasketVersion =
        checkAvailabilityRequest?.version || '1.1'
      CartOnlyCommunications.checkAvailability(
        this.partsBasketCreds,
        checkAvailabilityRequest
      )
        .then((resp) => {
          EmbeddedCommunications.handleCartOnlyMessage(resp)
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error('Error getting checkAvailablity response', e)
        })
        .finally(() => {
          StoreInstances.cart.setCheckAvailabilityLoaded(true)
        })
    }
  }

  getPartsBasketVersion(): string {
    return this.detectedPartsBasketVersion
  }

  /**
   * Translates the response from the API Order endpoint into a flattened
   * format compatible with the Parts Basket specs, and transfers back to
   * the shop application
   * @param cart
   * @param orderResponse
   */
  public transferOrderResponse(
    cart: ShoppingCart,
    orderResponse: OrderResponse
  ): void {
    const lines = []
    let singleOrderDetail: OrderResponseDetail = {} as OrderResponseDetail
    let orderNumber = ''
    let deliveryDetail: DeliveryDetail = {}
    for (const orderDetail of orderResponse?.orderDetails || []) {
      singleOrderDetail = orderDetail
      for (const location of orderDetail?.locations || []) {
        for (const order of location?.orders || []) {
          for (const part of order?.parts || []) {
            const pbPart = PartsBasketConverter.orderRespItemToPBItem(part)
            const line = {
              type: LineType.part,
              ...pbPart,
              locationId: location.locationId,
              locationDescription: location.locationDescription,
              orderConfirmationNbr: order.orderNumber,
            }
            lines.push(line)
          }
          deliveryDetail = order.deliveryDetail
          orderNumber = order.orderNumber
        }
      }
    }

    const transferPB = this.prepareBasketForTransfer(
      lines,
      PartsBasketAction.placeOrderResponse,
      cart
    )
    transferPB.orderInfo = {
      deliveryMethod: deliveryDetail?.deliveryMethod ?? 'Regular',
      orderMessage: singleOrderDetail?.comment ?? '',
      orderNumber: orderNumber ?? '',
      poNumber: singleOrderDetail.poNumber ?? '',
    }

    const { specifications, laborResults } = cart.vehicles[0]

    this.performTransfer(transferPB, specifications, laborResults)
    cart.cleanAllCarts(GaTrackOption.doNotTrack)
    StoreInstances.cart.setDisplayOrderModal(false)
  }

  public transferCart(cart: ShoppingCart): void {
    const data = cart.getDataForTransfer()
    const firstVehicle =
      data.vehicles && data.vehicles.length > 0 ? data.vehicles[0] : undefined
    if (!firstVehicle) {
      throw new Error('No vehicle found')
    }
    const lines = EmbeddedCommunications.mapCartToPartsBasket(
      firstVehicle.products
    )
    const transferPB = this.prepareBasketForTransfer(
      lines,
      PartsBasketAction.goShoppingResponse,
      cart
    )

    const { specifications, laborResults } = cart.vehicles[0]

    this.performTransfer(transferPB, specifications, laborResults)
    cart.cleanAllCarts(GaTrackOption.doNotTrack)
  }

  private prepareBasketForTransfer(
    lines: IPartsBasketLine11[],
    action: PartsBasketAction,
    cart: ShoppingCart
  ): IPartsBasket12 | IPartsBasket11 {
    let partsBasket: IPartsBasket12 | IPartsBasket11 = {
      version: this.detectedPartsBasketVersion,
      uid: (this.incomingPartsBasket as IPartsBasket11)?.uid,
      action,
      lineInfo: {
        line: lines,
      },
      vehicleInfo: {
        ymmeNo: getYMMENumberFromVehicle(cart.vehicle),
        ymme: getYMMEFromVehicle(cart.vehicle),
        licencePlate: cart.vehicle.plate,
        vin: cart.vehicle.vin,
      },
    }
    partsBasket = PartsBasketConverter.setAction(partsBasket, action)
    return partsBasket
  }

  private performTransfer(
    partsBasket: IPartsBasket12 | IPartsBasket11,
    specifications: VehicleSpecificationItem[] = [],
    laborData: LaborItem[] = []
  ): void {
    if (this.detectedPartsBasketVersion === '1.0') {
      this.partnerCommunicationManager?.cartCallBack(
        JSON.stringify({
          part: [
            ...(partsBasket as IPartsBasket12).lineInfo.line.map((line) => ({
              ...line,
              status: 1,
            })),
            ...(laborData || []).map(
              PartsBasketConverter.get10LaborPartFromLaborItem
            ),
          ],
          responseType:
            partsBasketResponseType[(partsBasket as IPartsBasket12).action],
          searchQualifier: {
            ...(this.incomingPartsBasket as IPartsBasketV10).searchQualifier,
            vehicleQualifier: partsBasket.vehicleInfo,
          },
        })
      )
    } else {
      const updatedPartsBasket = {
        ...partsBasket,
        lineInfo: partsBasket.lineInfo || {
          line: [],
        },
      }
      if (laborData.length > 0) {
        updatedPartsBasket.lineInfo.line = [
          ...updatedPartsBasket.lineInfo.line,
          ...laborData.map(PartsBasketConverter.getLaborPartFromLaborItem),
        ]
      }

      if (specifications.length > 0) {
        updatedPartsBasket.lineInfo.line = [
          ...updatedPartsBasket.lineInfo.line,
          ...specifications.map(
            PartsBasketConverter.getSpecificationfromSpecificationItem
          ),
        ]
      }

      this.partnerCommunicationManager?.cartCallBack(
        JSON.stringify(updatedPartsBasket)
      )
    }
  }

  private static mapCartToPartsBasket(
    products: ShoppingCartProduct[]
  ): Array<IPartsBasketLine11> {
    const mapped: IPartsBasketLine11[] = []
    products.forEach((cartPart: ShoppingCartProduct) => {
      ;(cartPart.orderSelections || []).forEach(
        (locationSelection: OrderSelection) => {
          const { locationId } = locationSelection
          const location = getLocationById(cartPart, locationId)
          if (!location) {
            return
          }
          const { quantityRequested } = locationSelection
          const line: IPartsBasketLine11 = {
            seqNo: 1,
            status: cartPart.status || '',
            type: cartPart.type,
            brand: cartPart.brand || '',
            description: cartPart.description,
            lineNo: cartPart.lineNo,
            locationDescription: location.called,
            locationId: location.locationId,
            manufacturerCode: cartPart.lineCode,
            partCategory: cartPart.catLineCode,
            partNumber: cartPart.partNumber,
            priceOverride: false,
            priceOverrideMsg: '',
            quantityRequested,
            quantityAvailable: location.qtyAvailable,
            unitCorePrice: location.coreCost,
            unitCostPrice: location.cost,
            unitListPrice: location.list,
            unitListCore: location.coreList,
            unitOfMeasure: location.unitOfMeasure,
          }
          mapped.push(line)
        }
      )
    })
    return mapped
  }

  // TODO: We need to look into sending the proper `searchQualifier`.
  public getLocalInventory(
    results: SearchResultsResponse
  ): Promise<ProductModel[]> {
    return new Promise((resolve) => {
      this.partnerCommunicationManager?.getLocalInventory(
        searchResultsToPartsBasket(results),
        results.parts || [],
        resolve
      )
    })
  }

  private static findPayload = (partsBasketStr: string) => {
    try {
      // For version 1.2, the parts basket is nested under partsBasket.partsBasket. Not confusing at all.
      const partsBasketMaybe = JSON.parse(partsBasketStr)
      const partsBasket = partsBasketMaybe.partsBasket
        ? partsBasketMaybe.partsBasket
        : partsBasketMaybe

      return partsBasket
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      return {}
    }
  }

  get isLocalInventoryEnabled(): boolean {
    return this.localInventory
  }

  get cartButtonValue(): CartButtonValues | undefined {
    return this.cartButton
  }

  get isAesServer(): boolean {
    return this.aesServer
  }

  public static handleCartOnlyMessage = (
    message: CheckAvailabilityResponse11 | CheckAvailabilityResponse12
  ): void => {
    const messageTo12 = 'Data' in message ? message.Data : message
    const cart = CartOnlyCommunications.availabilityResponseToCart(messageTo12)
    StoreInstances.cart.fromEmbedded(cart)
    Config.setCartOnlyMode()
  }

  public getPartsBasketCreds = (): PartsBasketCreds => {
    if (!this.partsBasketCreds) {
      throw new Error('Credentials not found.')
    }
    return this.partsBasketCreds
  }

  public cancelCallBack(): void {
    this.partnerCommunicationManager?.cancelCallBack()
  }
}

export const EmbeddedCommunicationsManager = new EmbeddedCommunications()
