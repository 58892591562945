import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { I18n, Translate } from 'src/i18n'
import styled from 'styled-components'
import { breakpoints } from 'src/theme/breakpoints'

export interface IMenuItem {
  path: string
  text: string
  onClick?: () => void
}

const navLinkStyle = {
  textDecoration: 'none',
  marginLeft: '32px',
}

const activeStyle = {
  borderBottom: '4px solid #F9C100',
}

const navTextStyle = {
  fontFamily: 'Oswald',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '34px',
  color: '#ffffff',
  cursor: 'pointer',
}

const StyledNavLink = styled(NavLink)`
  @media screen and (width <= ${breakpoints.laptopMedium}px) {
    span {
      font-size: 24px !important;
    }
  }
  @media screen and (width <= ${breakpoints.laptop}px) {
    span {
      font-size: 16px !important;
      margin-left: -9px !important;
    }
  }
  @media screen and (${breakpoints.laptopMedium}px < width < ${breakpoints.laptopLarge}px) {
    span {
      font-size: 22px !important;
      margin-left: -9px !important;
    }
  }
`

export const MenuItem = (props: IMenuItem): ReactElement => {
  const { path, text, onClick } = props
  return (
    <StyledNavLink
      exact
      to={path}
      activeStyle={activeStyle}
      style={navLinkStyle}
      onClick={onClick}
    >
      <I18n name={Translate(text)} style={navTextStyle} />
    </StyledNavLink>
  )
}

MenuItem.defaultProps = {
  onClick: () => undefined,
}

export default MenuItem
