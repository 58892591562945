import { Config } from 'src/config/ConfigManager'
import { PartsCatalogAPI } from './PartsCatalogAPI'

export class LaborCatalogAPI extends PartsCatalogAPI {
  apiEndpointAttributesBase = Config.urls.attributes

  constructor() {
    super()
    this.ENDPOINT_BASE = Config.urls.catalog
    this.CATEGORY_ENDPOINT = 'laborCategories'
    this.GROUP_ENDPOINT = 'laborGroups'
    this.TERMINOLOGY_ENDPOINT = 'laborDescriptions'
    this.SESSION_STORAGE_KEY = 'selected-labor-catalog-tree'
  }

  isGfxSupported = (): boolean => {
    return false
  }
}
