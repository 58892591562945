import { ReactElement, useState } from 'react'
import { Menu, MenuItem, IconButton, ListItemIcon } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import styled from 'styled-components'
import { Delete, Edit } from '@mui/icons-material'
import {
  VehicleWidgetDisplayState,
  vehicleWidgetStore,
} from '../../VehicleSearch/store/VehicleWidgetStore'
import { useHistory } from 'react-router-dom'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { useSearchStore } from 'src/store/search/SearchStore'
import { I18n } from 'src/i18n'

const ClearVehicleMenu = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()
  const searchStore = useSearchStore()

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDeleteVehicle = () => {
    if (
      location.pathname === '/partsCatalog' ||
      location.pathname === '/searchResults'
    )
      history.push('home')
    searchStore.deleteCurrentVehicle()
    vehicleWidgetStore.setIsDrawerOpened(false)
    vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.select)
    StoreInstances.uiStore.displaySuccessNotification(
      'vehicleSuccessfullyDeleted'
    )
  }

  return (
    <MenuRow>
      <MoreIconButton
        aria-label="more"
        aria-controls="main-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </MoreIconButton>
      <Menu
        id="main-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItemRow
          onClick={() => {
            vehicleWidgetStore.setIsDrawerOpened(true)
            vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.edit)
            handleMenuClose()
          }}
        >
          <ListItemIconRow>
            <Edit />
          </ListItemIconRow>
          <I18n name="changeVehicle"></I18n>
        </MenuItemRow>
        <MenuItemRow
          onClick={() => {
            handleMenuClose()
            handleDeleteVehicle()
          }}
        >
          <ListItemIconRow>
            <Delete />
          </ListItemIconRow>
          <I18n name="clearVehicle"></I18n>
        </MenuItemRow>
      </Menu>
    </MenuRow>
  )
}

const MenuRow = styled.div`
  display: none;
  @media (max-width: 1280px) {
    display: block;
  }
`

const MenuItemRow = styled(MenuItem)`
  width: 217px;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 20px 8px 20px;
`

const ListItemIconRow = styled(ListItemIcon)`
  min-width: 34px !important;
`

const MoreIconButton = styled(IconButton)`
  padding: 8px 0px;
`
export default ClearVehicleMenu
