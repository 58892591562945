import { Config } from '../ConfigManager'
import {
  NonCachedSearchType,
  ProductCatalogInfo,
} from '../../store/search/SearchStore'
import { Vehicle as VehicleInterface } from '../../store/models/Vehicles'
import { ProductModel } from 'src/store/models/ProductModel'

declare const gtag: (...arg0: unknown[]) => void

export enum GaTrackOption {
  track,
  doNotTrack,
}

export enum CustomDimension {
  Vehicle = 'dimension1',
  Make = 'dimension2',
  Model = 'dimension3',
  Year = 'dimension4',
  Engine = 'dimension5',
  User = 'dimension6',
  Domain = 'dimension7',
}

type IDimensions = Record<CustomDimension, string | undefined>

class GTM {
  private gaSearchTypeMap?: Map<number, string>

  private dimensions?: IDimensions

  public init = (): void => {
    const gaId = Config.googleAnalyticsId

    this.dimensions = {} as IDimensions
    const gaScripts = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gaId}', { send_page_view: false });
    `
    const script = document.createElement('script')
    script.type = 'application/javascript'
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`
    script.onload = () => {
      const gaBody = document.createElement('script')
      gaBody.type = 'application/javascript'
      gaBody.innerHTML = gaScripts
      document.body.appendChild(gaBody)
    }
    document.body.appendChild(script)
    this.gaSearchTypeMap = new Map<number, string>([
      [NonCachedSearchType.searchByText, 'Search Results'],
      [NonCachedSearchType.searchBySaytCategories, 'SAYT'],
      [NonCachedSearchType.searchByInterchange, 'Interchange'],
      [NonCachedSearchType.searchByNavigation, 'Parts'],
      [NonCachedSearchType.searchGfxByNavigation, 'GFX'],
    ])
  }

  public trackEvent = (event: string, data: Record<string, unknown>): void => {
    try {
      if (typeof gtag !== 'undefined') {
        const dataWithDimensions = { ...data, ...this.dimensions }
        gtag('event', event, dataWithDimensions)
      }
    } catch (e) {
      // Prevents ad blockers from making the site unusable
    }
  }

  public pageView = (pageName: string): void => {
    this.trackEvent('page_view', {
      page_title: pageName,
      page_location: pageName,
      page_path: pageName,
    })
  }

  public trackSearchResults = (
    results: ProductModel[],
    searchType: NonCachedSearchType,
    getCatalogInfo: (product: ProductModel) => ProductCatalogInfo,
    searchTerm?: string,
    vehicleSystem?: string
  ): void => {
    const gaList = results.map((product, idx) => {
      const catalogInfo = getCatalogInfo(product)
      const productListName = this.buildTheProductListName(
        searchType,
        searchTerm,
        vehicleSystem,
        catalogInfo
      )

      return {
        id: product.allianceProductId, // aka SKU
        name: this.buildProductName(product),
        brand: product.brandName ?? product.manufacturerName,
        list_name: productListName,
        category: catalogInfo?.partType, // Same as terminology description
        list_position: idx + 1,
        quantity: product.perCarQty,
        price: product.location?.[0]?.cost,
        ...this.dimensions,
      }
    })
    try {
      gtag('event', 'view_item_list', {
        items: gaList,
      })
    } catch (e) {
      // Prevents ad blockers from making the site unusable
    }
  }

  public buildTheProductListName = (
    searchType: NonCachedSearchType,
    searchTerm?: string,
    vehicleSystem?: string,
    catalogInfo?: ProductCatalogInfo
  ): string => {
    const gaSearchType = this.gaSearchTypeMap?.get(searchType)
    if (searchType === NonCachedSearchType.searchByNavigation)
      return `${gaSearchType}/${catalogInfo?.category}/${catalogInfo?.group}/${catalogInfo?.partType}`

    if (searchType === NonCachedSearchType.searchGfxByNavigation)
      return `${gaSearchType}/${catalogInfo?.category}/${catalogInfo?.group}/${vehicleSystem}`

    if (searchType === NonCachedSearchType.searchBySaytCategories)
      return `${gaSearchType}/${searchTerm}`

    return `${gaSearchType}/${searchTerm}`
  }

  public setCustomDimension = (name: CustomDimension, value: string): void => {
    if (this.dimensions) {
      this.dimensions[name] = value
    }
  }

  public buildProductName = (product: ProductModel): string => {
    return `${product.description} ${
      product.brandName ?? product.manufacturerName
    } ${product.partNumber}`
  }

  public setVehicleDimensions = (
    v: VehicleInterface,
    regionId: string
  ): void => {
    const vehicleDimension = this.buildVehicleStringDimension(v, regionId)
    this.setCustomDimension(CustomDimension.Vehicle, vehicleDimension)
    this.setCustomDimension(CustomDimension.Year, v?.year?.value ?? '')
    this.setCustomDimension(CustomDimension.Make, v?.make?.value ?? '')
    this.setCustomDimension(CustomDimension.Model, v?.model?.value ?? '')
    this.setCustomDimension(CustomDimension.Engine, v?.engine?.value ?? '')
  }

  private buildVehicleStringDimension = (
    v: VehicleInterface,
    regionId: string
  ): string => {
    // TODO: check if we can use `isMiscellaneousVehicle` here.
    if (v?.make?.value === 'MISCELLANEOUS' || !v?.year?.value) {
      return '0-0-0-0-0-0' // To match B2C format when a vehicle is not selected
    }
    return `AC-${v.year?.id}-${v.make?.id}-${v.model?.id}-${regionId}-${v.engine?.id}`
  }
}

export const GoogleTagManager = new GTM()
