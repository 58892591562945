import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import EditIcon from '@mui/icons-material/Edit'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close'
import {
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { useUserStore } from 'src/store/user/UserStore'
import { defaultTheme } from 'src/theme'
import { SimpleModal, TextInput } from 'src/ui-components'
import { downloadCsv } from 'src/ui-components/util/csvDownloadUtils'
import styled from 'styled-components'
import { ImportListPartsModal } from './ImportListPartsModal'
import { breakpoints } from 'src/theme/breakpoints'
import PrintList from '../PrintList'
import { Print } from '@mui/icons-material'
import { useReactToPrint } from 'react-to-print'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { show as showNiceModal } from '@ebay/nice-modal-react'
import { AddProductsToGroup } from './AddProductsToGroup'
import { Sorting } from 'src/ui-components/util/Interfaces'

export interface CSVDownloadFile {
  Group: string
  MFR: string
  ['Part Number']: string
  ['Stocking Qty']: number
  Comments: string
}

export const ListGroupsHeader = observer((): ReactElement => {
  const listStore = useListsStore()
  const {
    getSelectedCategoryLabel,
    getSelectedListLabel,
    deleteListModal,
    moveListModal,
    nameListNotesField,
    setDeleteListModal,
    setMoveListModal,
    setDeleteSelectedList,
    selectedList,
    setMoveSelectedList,
    nameListModal,
    setShowListGroups,
    setNameListModal,
    setNameSelectedList,
    setListNameModalType,
    setNameListNameField,
    getListByIdAndGroupId,
    isInSearchMode,
    searchTerm,
    getMultiSearchParts,
    selectedGroup,
    getListGroupParts,
    selectedGroupParts,
  } = listStore

  const printRef = useRef(null)
  const [printData, setPrintData] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [name, setName] = useState('')
  const [note, setNote] = useState('')

  useEffect(() => {
    if (showEditModal === false) {
      setName('')
      setNote('')
    }
  }, [showEditModal])

  useEffect(() => {
    getAllListData().then(setPrintData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    StoreInstances.uiStore.listStoreProps.selectedList,
    selectedGroupParts.length,
  ])

  const userStore = useUserStore()
  const parentOrgId = userStore?.preferences?.parentOrgId
  const isAdmin = Number(parentOrgId) === 0
  const isSellerManagedCategory =
    getSelectedCategoryLabel() === Sorting.SELLER_MANAGED_LIST_LABEL
  const disableListTab = !isAdmin && isSellerManagedCategory

  const handleEditModal = () => {
    if (!disableListTab) {
      setNameListModal(!nameListModal)
      setNameSelectedList({
        id: selectedList.id,
        label: selectedList.value,
        category: selectedList.getParent().value,
      })
      setListNameModalType('rename')
      setNameListNameField(selectedList.value)
    }
  }

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuAnchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleDeleteListModal = () => {
    if (!disableListTab) {
      setDeleteListModal(!deleteListModal)
      setDeleteSelectedList({
        id: selectedList?.id,
        label: selectedList?.value,
      })
      handleMenuClose()
    }
  }

  const handleMoveListModal = () => {
    if (!disableListTab) {
      setMoveListModal(!moveListModal)
      setMoveSelectedList({
        id: selectedList?.id,
        label: selectedList?.value,
        category: selectedList?.getParent()?.id,
      })
      handleMenuClose()
    }
  }

  const getAllListData = async () => {
    const selectedListGroups = selectedList.getChildren()
    const jsonListData = await Promise.all(
      selectedListGroups.map(async (group) => {
        const groupParts = (
          (await getListByIdAndGroupId(selectedList?.id, group.id)).items || []
        ).map(
          ({ lineCode, partNumber, stockQty, comment }): CSVDownloadFile => ({
            Group: group.value,
            MFR: lineCode || '',
            'Part Number': partNumber || '',
            'Stocking Qty': stockQty || 1,
            Comments: comment || '',
          })
        )
        return groupParts
      })
    ).then((data) => data.filter((item) => item.length > 0).flat())
    return jsonListData
  }

  const handleExportToCSV = () => {
    downloadCsv({
      data: printData,
      filename: selectedList ? selectedList.value : 'list_parts',
    })
  }

  const printList = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${Translate(
      'list'
    )} ${getSelectedCategoryLabel()} ${getSelectedListLabel()}`,
    pageStyle:
      '@media print { @page { size: A4; margin: 4rem 2rem 4rem 2rem !important }}',
  })

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          {isInSearchMode() ? (
            <>
              <StyledText>
                {`${Translate('showingResultsFor')} "${searchTerm}" `}
              </StyledText>
              <StyledText lightText style={{ marginLeft: '10px' }}>
                {`(${getMultiSearchParts()?.length} ${Translate('items')})`}
              </StyledText>
            </>
          ) : (
            <>
              <ArrowBackIosIconStyled
                onClick={() => {
                  setShowListGroups(false)
                }}
              />
              <StyledTextDesktop>
                {getSelectedCategoryLabel()}
              </StyledTextDesktop>
              <StyledTextMobile>{getSelectedListLabel()}</StyledTextMobile>
              <CustomDivider
                orientation="vertical"
                flexItem
                style={{
                  height: '80%',
                  width: '1px',
                  marginLeft: '15px',
                  marginRight: '15px',
                  marginTop: '5px',
                }}
              />
              <SelectedListLabel>
                <StyledText>{getSelectedListLabel()}</StyledText>
              </SelectedListLabel>
            </>
          )}
        </TitleContainer>
        {!isInSearchMode() && (
          <div style={{ display: 'flex', marginTop: '2px' }}>
            <EditContainer>
              <IconButton onClick={handleEditModal} title={Translate('Edit')}>
                <StyledEditIcon disabled={disableListTab} />
              </IconButton>
              <IconButton
                onClick={handleMenuClick}
                style={{ marginRight: '15px' }}
                title={Translate('moreOptions')}
              >
                <StyledMoreVertIcon />
              </IconButton>
              <AddItemsContainer
                onClick={() =>
                  showNiceModal(AddProductsToGroup, {
                    categoryLabel: getSelectedCategoryLabel(),
                    listLabel: getSelectedListLabel(),
                    selectedGroup,
                    selectedList,
                    getListGroupParts,
                  })
                }
              >
                <AddIcon />
                {Translate('addItemsToGroup')}
              </AddItemsContainer>
            </EditContainer>
            <IconButton
              onClick={handleMenuClick}
              style={{ padding: '0px' }}
              title={Translate('moreOptions')}
            >
              <StyledMoreHorizIcon />
            </IconButton>
          </div>
        )}
      </HeaderContainer>
      {!isInSearchMode() && (
        <StyledText normal>{nameListNotesField}</StyledText>
      )}

      {/* Desktop Modal */}
      <MenuContainer disabled={disableListTab}>
        <StyledMenu
          disabled={disableListTab}
          style={{ marginTop: '40px' }}
          anchorEl={menuAnchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={6}
        >
          <MenuContainer disabled={disableListTab}>
            <StyledMenuItem
              onClick={handleMoveListModal}
              disabled={disableListTab}
            >
              <SyledListItemIcon disabled={disableListTab}>
                <DriveFileMoveIcon fontSize="small" />
              </SyledListItemIcon>
              <StyledTextUser normal disableListTab={disableListTab}>
                <I18n name="move" />
              </StyledTextUser>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={handleDeleteListModal}
              disabled={disableListTab}
            >
              <SyledListItemIcon disabled={disableListTab}>
                <DeleteIcon fontSize="small" />
              </SyledListItemIcon>
              <StyledTextUser normal>
                <I18n name="delete" />
              </StyledTextUser>
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem
              onClick={() => {
                if (!disableListTab) {
                  handleMenuClose()
                  showNiceModal(ImportListPartsModal, {
                    listId: selectedList?.id?.toString(),
                    store: listStore,
                  })
                }
              }}
              disabled={disableListTab}
            >
              <SyledListItemIcon disabled={disableListTab}>
                <FileUploadIcon fontSize="small" />
              </SyledListItemIcon>
              <StyledTextUser normal>
                <I18n name="importList" />
              </StyledTextUser>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                if (!disableListTab) {
                  handleMenuClose()
                  handleExportToCSV()
                }
              }}
              disabled={!printData?.length}
            >
              <SyledListItemIcon disabled={!printData?.length}>
                <DownloadIcon fontSize="small" />
              </SyledListItemIcon>
              <StyledTextUser normal>
                <I18n name="exportList" />
              </StyledTextUser>
            </StyledMenuItem>
            <StyledMenuItem onClick={printList} disabled={!printData?.length}>
              <SyledListItemIcon disabled={!printData?.length}>
                <Print fontSize="small" />
              </SyledListItemIcon>
              <StyledTextUser normal>
                <I18n name="printList" />
              </StyledTextUser>
            </StyledMenuItem>
          </MenuContainer>
        </StyledMenu>
      </MenuContainer>

      {/* Mobile Drawer */}

      <MobileDrawer anchor="bottom" open={openMenu} onClose={handleMenuClose}>
        <DrawerContainer>
          <DrawerHeader>
            <StyledTextMobile>{getSelectedListLabel()}</StyledTextMobile>
            <CloseIconStyled onClick={handleMenuClose} />
          </DrawerHeader>
          <ListWrapper>
            <ListItem
              onClick={() =>
                showNiceModal(AddProductsToGroup, {
                  categoryLabel: getSelectedCategoryLabel(),
                  listLabel: getSelectedListLabel(),
                  selectedGroup,
                  selectedList,
                  getListGroupParts,
                })
              }
            >
              <AddIcon />
              <p> {Translate('addItemsToGroup')}</p>
            </ListItem>
            <ListItem>
              <IconButton onClick={handleEditModal} title={Translate('Edit')}>
                <StyledEditIcon disabled={disableListTab} />

                <p style={{ marginLeft: '10px' }}> {Translate('editList')}</p>
              </IconButton>
            </ListItem>

            <ListItem>
              <StyledMenuItem
                onClick={handleMoveListModal}
                disabled={disableListTab}
              >
                <SyledListItemIcon disabled={disableListTab}>
                  <DriveFileMoveIcon fontSize="small" />
                </SyledListItemIcon>
                <StyledTextUser normal disableListTab={disableListTab}>
                  <I18n name="move" />
                </StyledTextUser>
              </StyledMenuItem>
            </ListItem>

            <ListItem>
              <StyledMenuItem
                onClick={handleDeleteListModal}
                disabled={disableListTab}
              >
                <SyledListItemIcon disabled={disableListTab}>
                  <DeleteIcon fontSize="small" />
                </SyledListItemIcon>
                <StyledTextUser normal>
                  <I18n name="delete" />
                </StyledTextUser>
              </StyledMenuItem>
            </ListItem>
          </ListWrapper>
        </DrawerContainer>
      </MobileDrawer>

      {/* Check this features  */}

      <SimpleModal
        show={showEditModal}
        title={Translate('editList')}
        primaryActionText={Translate('saveChanges')}
        secondaryActionText={Translate('cancel')}
        disablePrimaryButton={name.length === 0 && note.length === 0}
        primaryButtonVariant="primary"
        size="lg"
        onSecondaryAction={() => setShowEditModal(false)}
      >
        <div>
          <TitleContainer style={{ fontSize: '16px' }}>
            <I18n name="category" style={{ fontWeight: '700' }} />:{' '}
            {getSelectedCategoryLabel()}
          </TitleContainer>

          <StyledText normal style={{ marginTop: '10px' }}>
            <I18n name="name" />
          </StyledText>
          <TextInput
            value={name}
            onChange={(value) => setName(value)}
            noMargin
            width="100%"
          />

          <StyledText normal style={{ marginTop: '10px' }}>
            <I18n name="note" />
          </StyledText>
          <TextArea
            value={note}
            onChange={(e) => {
              setNote(e.target.value)
            }}
          />
        </div>
      </SimpleModal>

      <div style={{ display: 'none' }}>
        <div ref={printRef}>
          <PrintList
            title={`${getSelectedCategoryLabel()}, ${getSelectedListLabel()}`}
            data={printData}
          />
        </div>
      </div>
    </Container>
  )
})

interface DisableListTabProps {
  disabled: boolean
}

const MenuContainer = styled.div<DisableListTabProps>`
  ${({ disabled }) =>
    disabled &&
    `
      color:${defaultTheme.colors.greyMedium};
      &:hover {
      cursor: not-allowed;
    }
    `}

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

interface ContainerProps {
  noMargin?: boolean
}

const Container = styled.div<ContainerProps>`
  margin: ${(p) => (p.noMargin ? '0px' : '32px')};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin: 16px 12px;
    justify-content: baseline;
  }
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-bottom: 26px;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const StyledMenu = styled(Menu)<DisableListTabProps>`
  ${({ disabled: disableListTab }) =>
    disableListTab &&
    `
      span, svg {
        fill: ${defaultTheme.colors.greyMedium};
        color:${defaultTheme.colors.greyMedium};
      }
    `}

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const StyledMenuItem = styled(MenuItem)<DisableListTabProps>`
  ${({ disabled: disableListTab }) =>
    disableListTab &&
    `
      &:hover {
      background-color: white;
      cursor: not-allowed;
      }
    `}
`

interface StyledTextProps {
  lightText?: boolean
  normal?: boolean
  disableListTab?: boolean
}
const StyledText = styled.span<StyledTextProps>`
  font-size: ${(p) => (p.normal ? '14px' : '22px')};
  font-family: ${(p) => (p.normal ? 'Roboto, serif' : 'Oswald, serif')};
  color: ${(p) => (p.lightText ? 'gray' : 'black')};
  font-weight: ${(p) => (p.normal ? '500' : 'bold')};
  display: block;
  text-align: left;
`

const StyledTextUser = styled(StyledText)`
  ${({ disableListTab }) =>
    disableListTab &&
    `
      &:hover { 
      cursor: not-allowed;
      } 
    `}
`

const SyledListItemIcon = styled(ListItemIcon)<DisableListTabProps>`
  ${({ disabled: disableListTab }) =>
    disableListTab &&
    `
      &:hover {
      cursor: not-allowed;
      }
    `}
`

const StyledEditIcon = styled(EditIcon)<DisableListTabProps>`
  color: gray;
  margin-left: 10px;
  margin-right: 10px;
  &:hover {
    color: black;
    cursor: pointer;
  }
  ${({ disabled: disableListTab }) =>
    disableListTab &&
    `
      &:hover {
      color: ${defaultTheme.colors.greyMedium};
      cursor: not-allowed;
    }
    `}
`
const StyledMoreVertIcon = styled(MoreVertIcon)`
  color: gray;
  &:hover {
    color: black;
    cursor: pointer;
  }

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const StyledMoreHorizIcon = styled(MoreHorizIcon)`
  color: gray;
  &:hover {
    color: black;
    cursor: pointer;
  }

  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: block;
    align-self: center;
  }
`

const AddItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  text-transform: uppercase;
  font-family: Oswald, serif;
  font-weight: bold;
  font-size: 16px;
  &:hover {
    background-color: black;
    cursor: pointer;
    color: white;
  }

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  resize: none;
`

const SelectedListLabel = styled.div`
  display: flex;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const CustomDivider = styled(Divider)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const ArrowBackIosIconStyled = styled(ArrowBackIosIcon)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: block;
    color: #6b6b6b;
    margin: 0px 12px 0px 2px;
    cursor: pointer;
  }
`

const CloseIconStyled = styled(CloseIcon)`
  display: none;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    color: #6b6b6b;
  }
`

const StyledTextDesktop = styled(StyledText)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const StyledTextMobile = styled(StyledText)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
  }
`

const DrawerContainer = styled.div``

const DrawerHeader = styled.div`
  padding: 24px 24px 21px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e4e1;
`

const ListWrapper = styled.div`
  margin: 16px 0px 88px;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;

  span {
  }

  button {
    padding: 0px;
  }

  li {
    padding: 0px;
    min-height: 0px;
  }

  svg {
    margin: 0px;
    color: #adadaa;
  }

  p,
  li span {
    color: var(--black, #000);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    margin: 0px;
  }
`
const MobileDrawer = styled(Drawer)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
  }
`
