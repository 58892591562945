import {
  AddGroupPart,
  Categories,
  DeleteGroupParts,
  GroupPartRequest,
  GroupPartResponse,
  ListImportDetails,
  MoveGroupParts,
  RequestBodyNewList,
  ResponseCategoriesAndLists,
  ResponseListDetails,
  ResponseNewList,
  UpdateGroupPart,
  ValidateListParts,
  ValidatePartsRequest,
  VerifyStockedPartsRequest,
  VerifyStockedPartsResponse,
} from 'src/store/lists/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'
import { IdNamePair } from 'src/store/models/KeyValuePair'
import { PartMatchingResponse } from 'src/api/lists/interfaces'
import { AxiosResponse } from 'axios'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'

export interface ListImportResponse {
  queueId: string
}

class ListServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'list', '1.1', '')
  }

  getCategoriesAndLists = async (): Promise<ResponseCategoriesAndLists> => {
    try {
      const resp = await this.get<Categories>('list')

      if (resp.status === 200) {
        return { categories: resp.data }
      }
      if (resp.status === 204) {
        return { categories: [] }
      }
    } catch (error) {
      throw new Error(error.toString())
    }
    return { categories: [] }
  }

  getListDetails = async (
    categoryId: number,
    listId: number
  ): Promise<ResponseListDetails> => {
    try {
      const resp = await this.get<ResponseListDetails>(
        `category/${categoryId}/list/${listId}`
      )
      if (resp.status === 200) {
        return resp.data
      }
    } catch (error) {
      throw new Error(error.toString())
    }
    return {}
  }

  importListParts = async (
    listId: string,
    file: File
  ): Promise<ListImportResponse> => {
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    const resp = await this.post<ListImportResponse>(
      `list/${listId}/import`,
      formData,
      config
    )
    return resp.data
  }

  validateParts = async (
    reqData: ValidatePartsRequest
  ): Promise<ValidateListParts> => {
    const resp = await this.post<ValidateListParts>('part/validate', reqData, {
      params: {
        catalogId: 99,
        buyerId:
          IS_INTEGRATED_VERSION &&
          EmbeddedCommunicationsManager.integrationInfo.allowAnyBuyer
            ? EmbeddedCommunicationsManager.getPartsBasketCreds().buyerId
            : undefined,
      },
    })
    return resp.data
  }

  createCategory = async (
    name: string
  ): Promise<IdNamePair<number, string>> => {
    const resp = await this.post<IdNamePair<number, string>>('category', {
      name,
    })
    return resp.data
  }

  getMatchingParts = async (
    searchTxt: string
  ): Promise<PartMatchingResponse> => {
    const resp = await this.get<PartMatchingResponse>('part', {
      params: {
        searchTxt,
      },
    })
    return resp.data || []
  }

  updateCategory = async (
    name: string,
    categoryId: number
  ): Promise<IdNamePair<number, string>> => {
    const resp = await this.patch<IdNamePair<number, string>>(
      `category/${categoryId}`,
      { name }
    )
    return resp.data
  }

  createList = async (
    requestData: RequestBodyNewList
  ): Promise<ResponseNewList> => {
    const resp = await this.post<ResponseNewList>('list', requestData)
    return resp.data
  }

  updateList = async (
    requestData: RequestBodyNewList,
    listId: number
  ): Promise<ResponseNewList> => {
    const resp = await this.patch<ResponseNewList>(
      `list/${listId}`,
      requestData
    )
    return resp.data
  }

  createGroup = async (
    listId: number,
    name: string
  ): Promise<IdNamePair<number, string>> => {
    const resp = await this.post<IdNamePair<number, string>>(
      `list/${listId}/group`,
      { name }
    )
    return resp.data
  }

  deleteCategory = async (categoryId: number): Promise<AxiosResponse<void>> => {
    return this.delete<void>(`category/${categoryId}`)
  }

  deleteList = async (listId: number): Promise<AxiosResponse<void>> => {
    return this.delete<void>(`list/${listId}`)
  }

  updateGroup = async (
    name: string,
    groupId: number
  ): Promise<IdNamePair<number, string>> => {
    const resp = await this.patch<IdNamePair<number, string>>(
      `group/${groupId}`,
      { name }
    )
    return resp.data
  }

  deleteGroup = async (groupId: number): Promise<number> => {
    const resp = await this.delete<number>(`group/${groupId}`)
    return resp.status
  }

  getGroupDetails = async (
    listId: number,
    groupId: number,
    reqData: GroupPartRequest
  ): Promise<GroupPartResponse | undefined> => {
    const resp = await this.get<GroupPartResponse>(
      `list/${listId}/group/${groupId}/part`,
      { params: reqData }
    )
    return resp?.data
  }

  addPartToGroup = async (
    listId: number,
    groupId: number,
    reqData: Array<AddGroupPart>
  ): Promise<number> => {
    const resp = await this.post<IdNamePair<number, string>>(
      `list/${listId}/group/${groupId}/part`,
      reqData
    )
    return resp.status
  }

  updatePartInGroup = async (
    listId: number,
    groupId: number,
    reqData: Array<UpdateGroupPart>
  ): Promise<number> => {
    const resp = await this.put<IdNamePair<number, string>>(
      `list/${listId}/group/${groupId}/part`,
      reqData
    )
    return resp.status
  }

  deleteGroupPart = async (
    deleteGroupParts: DeleteGroupParts
  ): Promise<number> => {
    const resp = await this.post<number>('part/delete', deleteGroupParts)
    return resp.status
  }

  movePartsToAnotherGroup = async (
    targetGroupId: number,
    moveGroupParts: MoveGroupParts
  ): Promise<number> => {
    const resp = await this.post<number>(
      `part/moveToGroup/${targetGroupId}`,
      moveGroupParts
    )
    return resp.status
  }

  moveListToAnotherCategory = async (
    listId: number,
    targetCategoryId: number
  ): Promise<number> => {
    const resp = await this.post<number>(
      `list/${listId}/moveToCategory/${targetCategoryId}`,
      {
        listId,
        targetCategoryId,
      }
    )
    return resp.status
  }

  getListImportDetails = async (
    queueId: string
  ): Promise<Array<ListImportDetails>> => {
    const resp = await this.get<Array<ListImportDetails>>(`queue/${queueId}`)
    return resp?.data
  }

  deleteListQueue = async (queueId: string): Promise<void> => {
    await this.delete(`queue/${queueId}`)
  }

  verifyStockedParts = async (
    verifyStockedParts: VerifyStockedPartsRequest[]
  ): Promise<VerifyStockedPartsResponse[]> => {
    try {
      const resp = await this.post<VerifyStockedPartsResponse[]>(
        'part/verifyStockedParts',
        verifyStockedParts
      )
      return resp?.data || []
    } catch (e) {
      return []
    }
  }
}

export default new ListServiceProvider()
