import { AddCircle, Edit } from '@mui/icons-material'
import { Grid, Grow } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { I18n } from 'src/i18n'
import AttributesServiceProvider from 'src/services/AttributesServiceProvider'
import { useLaborLookup } from 'src/store/labor/LaborStore'
import { PartsCatalogType } from 'src/store/partsCatalog/PartsCatalog'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useUserStore } from 'src/store/user/UserStore'
import { defaultTheme } from 'src/theme'
import { Button, TextInput } from 'src/ui-components'
import styled from 'styled-components'
import { StoreInstances } from '../../../store/StoreInstancesContainer'
import { SpecificConditionI } from '../../../store/models/SpecificConditions'
import {
  DomType,
  OrgType,
  UuidType,
} from 'src/services/attributes/AttributesModel'
import { breakpoints } from 'src/theme/breakpoints'

const LaborResultHeader = observer((): ReactElement => {
  const {
    showFitmentConditionsDialog,
    setShowLaborResultModal,
    hideFitmentConditionsDialog,
  } = useUiStateStore()
  const userStore = useUserStore()
  const { currentVehicle } = useSearchStore()
  const { fetchSpecificConditionQuestions } = useSpecificConditionStore()
  const { lookup: laborStore, setLaborRate: setStoreLaborRate } =
    useLaborLookup()

  const [showLaborRate, setShowLaborRate] = useState<boolean>(false)
  const [updateLaborSpinner, setUpdateLaborSpinner] = useState<boolean>(false)
  const [specificConditionQuestions, setSpecificConditionQuestions] = useState<
    SpecificConditionI[]
  >([])

  const [laborRateValue, setLaborRateValue] = useState<string>(
    userStore.preferences.epeFeatures_laborRate
  )

  const [updatedLaborRate, setUpdatedLaborRate] = useState<string>('')

  const [laborRates, setLaborRate] = useState<string>(
    userStore.preferences.epeFeatures_laborRate
  )

  const getSpecificConditions = useCallback(async () => {
    const formattedVehicle = {
      ...StoreInstances.searchStore.currentVehicle,
      initialized: undefined,
    }

    const specificCondQ = await fetchSpecificConditionQuestions(
      formattedVehicle,
      laborStore.terminologies,
      PartsCatalogType.LABOR_SEARCH
    )
    setSpecificConditionQuestions(specificCondQ)
  }, [fetchSpecificConditionQuestions, laborStore.terminologies])

  useEffect(() => {
    if (specificConditionQuestions && specificConditionQuestions.length > 0)
      return
    getSpecificConditions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSetLaborRateClick = () => {
    setUpdateLaborSpinner(true)
    saveOrUpdateLabourRates(updatedLaborRate)
  }

  const saveOrUpdateLabourRates = async (
    updatedLaborRate: string
  ): Promise<void> => {
    const { orgId, orgType } = StoreInstances.userStore.preferences
    const domType = () => {
      if (orgType === OrgType.LOC) {
        return DomType.LOC
      } else if (orgType === OrgType.REG) {
        return DomType.REG
      } else {
        return DomType.WD
      }
    }

    try {
      const resp = await AttributesServiceProvider.saveOrUpdateAttribute([
        {
          uuid: UuidType.LABOR_RATE,
          domId: Number(orgId),
          domType: Number(domType()),
          attrValue: updatedLaborRate,
        },
      ])
      if (resp === 200) {
        StoreInstances.uiStore.displaySuccessNotification(
          'laborRateUpdatedSuccessfully'
        )
        setStoreLaborRate(updatedLaborRate)
        setLaborRate(laborRateValue)
      }
    } catch (e) {
      StoreInstances.uiStore.displayErrorNotification('errorUpdatingLaborRate')
    }
    setUpdateLaborSpinner(false)
    setShowLaborRate(false)
  }

  const onEditFitmentConditionClicked = () => {
    if (specificConditionQuestions.length > 0) {
      setShowLaborResultModal(false)
      showFitmentConditionsDialog({
        proceed: () => {
          setShowLaborResultModal(true)
          hideFitmentConditionsDialog()
        },
        cancel: () => {
          hideFitmentConditionsDialog()
          setShowLaborResultModal(true)
        },
      })
    }
  }

  return (
    <>
      <Grid container columnSpacing={1}>
        <Grid xs={12} item>
          <GridItemCarModel style={{ fontWeight: 700 }}>
            {`${currentVehicle.year?.value} ${currentVehicle.make?.value} ${currentVehicle.model?.value}  `}
          </GridItemCarModel>
          <GridItemCarModel>{currentVehicle.engine?.value}</GridItemCarModel>
        </Grid>

        <Grid xs={12} item>
          <DetailsInfo>
            {specificConditionQuestions?.length > 0 && (
              <DetailsInfoAction onClick={onEditFitmentConditionClicked}>
                <Edit fontSize="inherit" />
                <I18n name="editFitmentConditions" />
              </DetailsInfoAction>
            )}
            {specificConditionQuestions?.length <= 0 && (
              <DetailsInfoActionNotAllowed>
                <Edit fontSize="inherit" />
                <I18n name="editFitmentConditions" />
              </DetailsInfoActionNotAllowed>
            )}

            <DetailsInfoAction onClick={() => setShowLaborRate(!showLaborRate)}>
              <AddCircle fontSize="inherit" />
              <I18n name="setLaborRate" style={{ marginRight: '5px' }} />
              {laborRates && laborRates !== '' && <>- {laborRates}</>}
            </DetailsInfoAction>
          </DetailsInfo>
        </Grid>
      </Grid>
      {showLaborRate && (
        <Grow in={showLaborRate}>
          <LaborRateInput container className="mt-3">
            <Grid item>
              <TextInput
                type="currency"
                onChange={(value) => setLaborRateValue(value)}
                value={laborRateValue}
                placeholder="$0.00"
                onValueChange={(value) => setUpdatedLaborRate(value)}
              />
            </Grid>
            <Grid item className="pt-3 my-1 mx-3 py-0">
              <Button
                text="setLaborRate"
                loading={updateLaborSpinner}
                onClick={onSetLaborRateClick}
              />
            </Grid>
          </LaborRateInput>
        </Grow>
      )}
    </>
  )
})

export default LaborResultHeader

const GridItemCarModel = styled.span`
  font-size: 18px;
  line-height: 24px;
  font-family: 'Roboto', serif;
`

const DetailsInfoAction = styled.span`
  cursor: pointer;
  svg {
    margin-right: 6px;
    font-size: 17px;
  }
  span {
    margin-left: 0;
    margin-right: 24.67px;
  }
`

const DetailsInfoActionNotAllowed = styled.span`
  cursor: not-allowed;
  svg {
    margin-right: 6px;
    font-size: 17px;
    color: ${defaultTheme.colors.disabledButtonText};
  }
  span {
    margin-left: 0;
    margin-right: 24.67px;
    color: ${defaultTheme.colors.disabledButtonText};
  }
`

const DetailsInfo = styled.div`
  display: flex;
  margin-top: 9px;
  justify-content: baseline;
  color: ${defaultTheme.colors.link};
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  @media (max-width: ${breakpoints.mobileLarge}px) {
    flex-direction: column;
  }
`

const LaborRateInput = styled(Grid)`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
`
