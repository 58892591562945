import { Close, Search as SearchIcon } from '@mui/icons-material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import { Drawer } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import miscellaneousVehicle from 'src/features/search/Results/utils/miscellaneousVehicle'
import { I18n } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { Button } from 'src/ui-components'
import styled from 'styled-components'
import { PartsBody } from 'src/api/part/interface'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { Sorting } from 'src/ui-components/util/Interfaces'

export const BottomDrawer = observer((): ReactElement => {
  const history = useHistory()
  const listStore = useListsStore()
  const SearchStore = useSearchStore()

  const { addListPartsToCart } = useListsStore()

  const { isThereOnlyTheMiscellaneousCart } = useShoppingCart()

  const {
    showBottomDrawer,
    setShowBottomDrawer,
    setShowDeleteItemsFromListsModal,
    selectedPartsList,
    getSelectedCategoryItems,
    resetSelectedGroupParts,
    setCartSelectorModal,
    setSelectedVehicle,
    getSelectedCategoryLabel,
    arePartsBeingAddedToCart,
    getMultiSearchSelectedParts,
    isInSearchMode,
  } = listStore
  const { searchByLookupItems } = SearchStore

  const { setMoveToAnotherListDrawer } = useUiStateStore()

  const handleOnClose = useCallback(() => {
    setShowBottomDrawer(false)
  }, [setShowBottomDrawer])

  const isSellerManagedCategory =
    getSelectedCategoryLabel() === Sorting.SELLER_MANAGED_LIST_LABEL

  const numberOfLists = getSelectedCategoryItems()?.length

  const prepareLookupItems = () => {
    const partList = isInSearchMode()
      ? getMultiSearchSelectedParts()
      : selectedPartsList

    const lookUpItems: PartsBody[] = partList
      .filter((part) => part.isSelected === true)
      .map((item) => {
        return {
          partNumber: item.partNumber.toString(),
          lineCode: item.lineCode,
          qty: item.stockQty,
          description: item.description,
        }
      })

    searchByLookupItems(lookUpItems)
  }

  return (
    <Drawer
      anchor="bottom"
      BackdropProps={{ invisible: true }}
      open={showBottomDrawer}
      variant="persistent"
      onClose={handleOnClose}
    >
      <Container>
        <Left>
          <ItemsSelectedAmountInfo>
            <ItemsAmount>{selectedPartsList.length} items</ItemsAmount>{' '}
            {'\u00a0'}
            <I18n name="selectedAcross" />
            <ListsAmount>{numberOfLists} lists</ListsAmount>
          </ItemsSelectedAmountInfo>

          {/* Mobile Version  ItemsSelectedAmountInfo */}

          <MobileItemsSelectedAmountInfo>
            <CloseIcon onClick={() => setShowBottomDrawer(false)} />
            <ItemsAmount>{selectedPartsList.length} items</ItemsAmount>{' '}
            {'\u00a0'}
            <ListsAmount style={{ fontWeight: '400', marginRight: '20px' }}>
              {`(${numberOfLists} lists)`}
            </ListsAmount>
          </MobileItemsSelectedAmountInfo>

          <ActionsWrapper>
            <ActionsContainer
              isSellerManagedLink={isSellerManagedCategory}
              onClick={() => {
                if (!isSellerManagedCategory) setMoveToAnotherListDrawer(true)
              }}
            >
              <DriveFileMoveIcon />

              <ActionText>
                <span>
                  {' '}
                  <I18n name="moveToAnotherList" />
                </span>
              </ActionText>
            </ActionsContainer>

            <ActionsContainer
              isSellerManagedLink={isSellerManagedCategory}
              onClick={() => {
                if (!isSellerManagedCategory)
                  setShowDeleteItemsFromListsModal(true)
              }}
            >
              <DeleteIcon />

              <ActionText>
                <span>
                  <I18n name="deleteItemFromList" />
                </span>
              </ActionText>
            </ActionsContainer>
          </ActionsWrapper>
        </Left>

        <ButtonsWrapper>
          <Button
            text="lookupItems"
            size="medium"
            variant="secondary"
            icon={<SearchButtonIcon />}
            onClick={() => {
              prepareLookupItems()
              history.push('/searchResults')
              resetSelectedGroupParts()
            }}
          />
          <Button
            text="addToCart"
            size="medium"
            disabled={arePartsBeingAddedToCart === true}
            loading={arePartsBeingAddedToCart}
            onClick={() => {
              if (isThereOnlyTheMiscellaneousCart()) {
                setSelectedVehicle(miscellaneousVehicle)
                addListPartsToCart()
              } else {
                setCartSelectorModal(true)
              }
            }}
            icon={
              <AddShoppingCartIcon
                fontSize="small"
                sx={{ marginRight: '5px' }}
              />
            }
          />
        </ButtonsWrapper>
      </Container>
    </Drawer>
  )
})

const Container = styled.div`
  height: 100%;
  background-color: ${defaultTheme.colors.white};
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  font-family: Roboto;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 18px 12px 48px;
    height: fit-content;
  }
`

const Left = styled.div`
  display: flex;
`

const ItemsSelectedAmountInfo = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  margin-right: 17.5px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const MobileItemsSelectedAmountInfo = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    font-size: 14px;
    span {
      margin: 1px 0px 0px 0px;
    }
  }
`

const ItemsAmount = styled.span`
  font-weight: bold;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
  }
`

const ListsAmount = styled(ItemsAmount)`
  margin-left: 4px;
`

const ActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    span {
      display: none;
    }
  }
`
interface ActionsContainerProps {
  isSellerManagedLink?: boolean
}

const ActionsContainer = styled.div<ActionsContainerProps>`
  display: flex;
  color: ${(p) =>
    p.isSellerManagedLink
      ? defaultTheme.colors.greyLight
      : defaultTheme.colors.link};
  cursor: ${(p) => (p.isSellerManagedLink ? 'auto' : 'pointer')};
  font-size: 16px;
`

const ActionText = styled.div<ActionsContainerProps>`
  margin-left: 5.5px;
  &:hover {
    text-decoration: ${(p) => (p.isSellerManagedLink ? 'none' : 'underline')};
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const SearchButtonIcon = styled(SearchIcon)`
  margin-right: 5px;
`

const CloseIcon = styled(Close)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    cursor: pointer;
    margin-right: 6px;
  }
`
