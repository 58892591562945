import { ReactElement, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RouteRequiresLogin } from './RouteRequiresLogin'
import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'
// TODO: convert signout into an util or a custom hook.
import SignOut from 'src/routes/SignOut'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import Lists from 'src/features/lists'
import TermsAndConditions from 'src/features/login/TermsConditions'
import { FullPageRoute } from './FullPageRoute'

interface AuthenticatedRoutesInterface {
  landingPage: string
  appHasLoaded: boolean
  redirectToLogin: boolean
}

const AuthenticatedRoutes = (
  props: AuthenticatedRoutesInterface
): ReactElement => {
  const { landingPage, appHasLoaded, redirectToLogin } = props

  const Home = lazy(() => import('src/routes/Home'))
  const PartsCatalog = lazy(() => import('src/routes/PartsCatalog'))
  const AstPartsCatalog = lazy(() => import('src/routes/AstPartsCatalog'))
  const Cart = lazy(() => import('src/routes/CartRoute'))
  const SearchResults = lazy(() => import('src/routes/SearchResults'))
  const ProductDetails = lazy(
    () => import('../../features/search/Results/ProductDetails')
  )
  const VehicleHistory = lazy(() => import('src/routes/VehicleHistory'))
  const OrderHistory = lazy(() => import('src/features/orderHistory'))
  const OrderConfirmation = lazy(
    () => import('src/features/orderConfirmation/components')
  )
  const OrderDetails = lazy(
    () => import('src/features/orderHistory/components/OrderDetails')
  )

  const QuotesPage = lazy(() => import('src/routes/Quotes'))
  const QuotesDetails = lazy(() => import('src/routes/QuotesDetails'))

  const NotFoundPage = lazy(() => import('src/routes/NotFound'))

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (redirectToLogin) return <></>

  if (!appHasLoaded)
    return (
      <Wrapper>
        <Spinner animation="border" />
      </Wrapper>
    )

  return (
    <Switch>
      <RouteRequiresLogin exact path="/">
        <Redirect to={landingPage} />
      </RouteRequiresLogin>
      <RouteRequiresLogin exact path="/home">
        {EmbeddedCommunicationsManager.isAstPunchOut && (
          <Redirect to={landingPage} />
        )}
        {!EmbeddedCommunicationsManager.isAstPunchOut && <Home />}
      </RouteRequiresLogin>
      <Route exact path="/signOut">
        <SignOut />
      </Route>
      <RouteRequiresLogin exact path="/partsCatalog">
        <PartsCatalog />
      </RouteRequiresLogin>
      <RouteRequiresLogin exact path="/astPartsCatalog">
        <AstPartsCatalog />
      </RouteRequiresLogin>
      <RouteRequiresLogin exact path="/cart">
        <Cart />
      </RouteRequiresLogin>
      {/* <RouteRequiresLogin exact path="/profile">
              This will be the future user profile screen
            </RouteRequiresLogin> */}
      <RouteRequiresLogin exact path="/searchResults">
        <SearchResults />
      </RouteRequiresLogin>
      <RouteRequiresLogin exact path="/vehicleHistory">
        <VehicleHistory />
      </RouteRequiresLogin>
      <RouteRequiresLogin path="/orderHistory">
        <OrderHistory />
      </RouteRequiresLogin>
      <RouteRequiresLogin path="/quotes">
        <QuotesPage />
      </RouteRequiresLogin>

      <RouteRequiresLogin path="/quotes-details/:id">
        <QuotesDetails />
      </RouteRequiresLogin>
      <RouteRequiresLogin exact path="/lists">
        <Lists />
      </RouteRequiresLogin>
      {import.meta.env.VITE_FEATURE_ORDER_DETAILS_ENABLED === 'true' && (
        <RouteRequiresLogin path="/orderDetails/:orderId">
          <OrderDetails />
        </RouteRequiresLogin>
      )}
      <RouteRequiresLogin exact path="/product">
        <ProductDetails />
      </RouteRequiresLogin>
      <RouteRequiresLogin exact path="/orderConfirmation">
        <OrderConfirmation />
      </RouteRequiresLogin>
      <FullPageRoute exact path="/terms-and-conditions">
        <TermsAndConditions />
      </FullPageRoute>
      <Route path="*">
        <RouteRequiresLogin>
          <NotFoundPage />
        </RouteRequiresLogin>
      </Route>
    </Switch>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

export default AuthenticatedRoutes
