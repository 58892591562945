import { Grow } from '@mui/material'
import {
  Delete,
  Edit,
  Assignment,
  Build,
  HomeRepairService,
} from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { ReactElement, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { I18n, Translate } from 'src/i18n'
import { useVehicleSpecification } from 'src/store/vehicleSpecification/VehicleSpecificationStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUserStore } from 'src/store/user/UserStore'
import { UserAttributeKey } from 'src/store/user/interfaces'
import {
  PartsCatalogType,
  usePartsCatalog,
} from 'src/store/partsCatalog/PartsCatalog'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { defaultTheme } from 'src/theme'
import LaborLookupDrawer from 'src/features/labor/lookup/LookupDrawer'
import { ExperianAutocheckButton } from 'src/features/customizeSearch/components/ExperianAutocheckButton'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
} from './store/VehicleWidgetStore'
import { StoreInstances } from '../../../store/StoreInstancesContainer'
import { VehicleSpecificationDrawer } from './components/VehicleSpecificationDrawer'
import LaborResultDrawer from '../../labor/results/LaborResultDrawer'
import { ServiceIntervalsDrawer } from './components/ServiceIntervalsDrawer'
import { Button } from 'src/ui-components'
import { useSearchStore } from 'src/store/search/SearchStore'
import { show as showNiceModal } from '@ebay/nice-modal-react'
import { VinDetails } from 'src/features/vehicleHistory/components/VinDetails'

interface SelectedVehicleProps {
  onEditVehicle: () => void
}

export const SelectedVehicle = observer(
  (props: SelectedVehicleProps): ReactElement => {
    const location = useLocation()
    const history = useHistory()
    const searchStore = useSearchStore()
    const enableGrowTransition =
      searchStore.showVehicleTransition &&
      location.pathname !== '/home' &&
      location.pathname !== '/searchResults'

    const { onEditVehicle } = props
    const { currentVehicle } = searchStore
    const { terminologies } = usePartsCatalog()
    const {
      fetchSpecificConditionQuestions,
      specificConditionsLoading,
      setSpecificConditionsLoading,
    } = useSpecificConditionStore()

    const {
      fetchVehicleSpecification,
      showVehicleSpecificationDrawer,
      setShowVehicleSpecificationDrawer,
    } = useVehicleSpecification()

    const vehicleWidgetStore = useVehicleWidgetStore()

    const {
      showLaborLookupModal,
      setShowLaborLookupModal,
      setShowLaborResultModal,
      showLaborResultModal,
      showServiceIntervalsDrawer,
      setShowServiceIntervalsDrawer,
    } = useUiStateStore()

    const userStore = useUserStore()
    const showLaborLookup =
      userStore.getUserAttribute(UserAttributeKey.epeFeatures_labor) === 'true'

    const [openSpecDrawerExcludeGroups, setOpenSpecDrawerExcludeGroups] =
      useState(false)

    const goToSearchResults = (): void => {
      StoreInstances.uiStore.hideFitmentConditionsDialog()
      searchStore.searchByNavigation()
      history.push('/searchResults')
    }

    const goBack = (): void => {
      StoreInstances.uiStore.hideFitmentConditionsDialog()
    }

    const handleEditFitmentConditions = async () => {
      try {
        setSpecificConditionsLoading(true)
        const formattedVehicle = {
          ...searchStore.currentVehicle,
          initialized: undefined,
        }
        const specificConditionQuestions =
          await fetchSpecificConditionQuestions(
            formattedVehicle,
            terminologies,
            PartsCatalogType.PARTS_SEARCH
          )
        if (specificConditionQuestions.length > 0) {
          StoreInstances.uiStore.showFitmentConditionsDialog({
            proceed: goToSearchResults,
            cancel: goBack,
          })
        }
      } finally {
        setSpecificConditionsLoading(false)
      }
    }

    const handleDeleteVehicle = () => {
      if (
        location.pathname === '/partsCatalog' ||
        location.pathname === '/searchResults'
      )
        history.push('home')
      searchStore.deleteCurrentVehicle()
      vehicleWidgetStore.setIsDrawerOpened(false)
      vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.select)
      StoreInstances.uiStore.displaySuccessNotification(
        'vehicleSuccessfullyDeleted'
      )
    }

    const handleShowSpecification = () => {
      fetchVehicleSpecification()
      setOpenSpecDrawerExcludeGroups(true)
    }

    const handleChangeVehicle = () => {
      vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.edit)
    }

    const handleLaborLookupClick = () => {
      StoreInstances.uiStore.setShowLaborLookupModal(true)
    }

    const handleShowSeviceIntervals = () => {
      setShowServiceIntervalsDrawer(true)
    }
    const showEditFitmentConditions =
      (searchStore?.currentVehicle?.specificConditions?.length || 0) > 0
    const subModel = currentVehicle.vehicleSpecs?.find(
      (obj) => obj.label === 'Series'
    )?.value

    // other motives changes
    const disableDetailsInfo = currentVehicle?.type?.id !== 111

    return (
      <Grow in appear={enableGrowTransition} timeout={1000}>
        <Wrapper>
          <VehicleInfo>
            <LeftSide>
              <FlexColumn>
                <Title>
                  {currentVehicle.year?.value}&nbsp;
                  {currentVehicle.make?.value}&nbsp;
                  {currentVehicle.model?.value}&nbsp;
                  {currentVehicle.engine?.value && (
                    <>
                      <VehicleDetailLabel>
                        <I18n name="engine" />:
                      </VehicleDetailLabel>
                      <VehicleDetail>
                        {currentVehicle.engine.value}
                      </VehicleDetail>
                    </>
                  )}
                  {currentVehicle.vin && (
                    <>
                      <VehicleDetailLabel>
                        <I18n name="vin" />:
                      </VehicleDetailLabel>
                      <VehicleDetailLink
                        onClick={() =>
                          showNiceModal(VinDetails, {
                            VIN: currentVehicle?.vin,
                          })
                        }
                        type="button"
                      >
                        {currentVehicle.vin}
                      </VehicleDetailLink>
                    </>
                  )}
                  {currentVehicle.plate && (
                    <>
                      <VehicleDetailLabel>
                        <I18n name="license" />:
                      </VehicleDetailLabel>
                      <VehicleDetail>
                        {currentVehicle.state}, {currentVehicle.plate}
                      </VehicleDetail>
                    </>
                  )}
                  {subModel?.length > 0 && subModel && (
                    <>
                      <VehicleDetailLabel>
                        <I18n name="subModel" />:
                      </VehicleDetailLabel>
                      <VehicleDetail>{subModel}</VehicleDetail>
                    </>
                  )}
                </Title>
                {showEditFitmentConditions &&
                  location.pathname === `/searchResults` && (
                    <EditFitmentConditions
                      onClick={() => {
                        handleEditFitmentConditions()
                      }}
                    >
                      {specificConditionsLoading ? (
                        <StyledSpinner animation="border" size="sm" />
                      ) : (
                        <StyledEditIcon />
                      )}
                      {Translate('editFitmentConditions')}
                    </EditFitmentConditions>
                  )}
              </FlexColumn>

              {import.meta.env.VITE_FEATURE_VEHICLE_LINKS_ENABLED ===
                'true' && (
                <>
                  <MobileVehicleInfo>
                    <MobileVehicleInfoChild>
                      <VehicleDetailLabel>
                        <I18n name="Vehicle" />: {currentVehicle.year?.value}
                        &nbsp;
                        {currentVehicle.make?.value}&nbsp;
                        {currentVehicle.model?.value}&nbsp;
                      </VehicleDetailLabel>
                    </MobileVehicleInfoChild>
                    <MobileVehicleInfoChild>
                      <VehicleDetailLabel>
                        <I18n name="engine" />:
                      </VehicleDetailLabel>
                      <VehicleDetail>
                        {currentVehicle.engine?.value}
                      </VehicleDetail>
                    </MobileVehicleInfoChild>
                    {currentVehicle.vin && (
                      <MobileVehicleInfoChild>
                        <VehicleDetailLabel>
                          <I18n name="vin" />:
                        </VehicleDetailLabel>
                        <VehicleDetail>{currentVehicle?.vin}</VehicleDetail>
                      </MobileVehicleInfoChild>
                    )}
                    {currentVehicle.plate && (
                      <MobileVehicleInfoChild>
                        <VehicleDetailLabel>
                          <I18n name="licensePlate" />:
                        </VehicleDetailLabel>
                        <VehicleDetail>
                          {currentVehicle?.state}, {currentVehicle?.plate}
                        </VehicleDetail>
                      </MobileVehicleInfoChild>
                    )}
                    {subModel?.length > 0 && subModel && (
                      <MobileVehicleInfoChild>
                        <VehicleDetailLabel>
                          <I18n name="subModel" />:
                        </VehicleDetailLabel>
                        <VehicleDetail>{subModel}</VehicleDetail>
                      </MobileVehicleInfoChild>
                    )}
                  </MobileVehicleInfo>
                  {!disableDetailsInfo && (
                    <DetailsInfo>
                      <DetailsInfoAction onClick={handleShowSpecification}>
                        <Assignment fontSize="inherit" />
                        <I18n name="specifications" />
                      </DetailsInfoAction>
                      <DetailsInfoAction onClick={handleShowSeviceIntervals}>
                        <Build fontSize="inherit" />
                        <I18n name="serviceIntervals" />
                      </DetailsInfoAction>
                      {showLaborLookup && (
                        <DetailsInfoAction onClick={handleLaborLookupClick}>
                          <HomeRepairService fontSize="inherit" />
                          <I18n name="laborLookup" />
                        </DetailsInfoAction>
                      )}
                      <DetailsInfoAction>
                        <ExperianAutocheckButton vin={currentVehicle?.vin} />
                      </DetailsInfoAction>
                    </DetailsInfo>
                  )}
                </>
              )}
            </LeftSide>

            <RightSide>
              <EditButton onClick={onEditVehicle}>
                <EditIcon>
                  <Edit style={{ marginRight: 10 }} />
                </EditIcon>
                <I18n name="changeVehicle" />
              </EditButton>
              <EditButton onClick={handleDeleteVehicle}>
                <DeleteIcon>
                  <Delete style={{ marginRight: 10 }} />
                </DeleteIcon>
                <I18n name="clearVehicle" />
              </EditButton>
            </RightSide>
            <MobileRightSide>
              <MobileDeleteButton>
                <Button
                  style={{ width: '100%' }}
                  onClick={handleChangeVehicle}
                  text="changeVehicle"
                />
              </MobileDeleteButton>
              <MobileEditButton>
                <Button
                  style={{ width: '100%' }}
                  text="clearVehicle"
                  variant="secondary"
                  onClick={handleDeleteVehicle}
                />
              </MobileEditButton>
            </MobileRightSide>
          </VehicleInfo>
          <LaborLookupDrawer
            open={showLaborLookupModal}
            setLaborLookup={setShowLaborLookupModal}
            setLaborResult={setShowLaborResultModal}
          />
          {showLaborResultModal && (
            <LaborResultDrawer
              open={showLaborResultModal}
              setOpen={setShowLaborResultModal}
              setLaborOpen={setShowLaborLookupModal}
            />
          )}
          <VehicleSpecificationDrawer
            openWithFilters={showVehicleSpecificationDrawer}
            open={openSpecDrawerExcludeGroups}
            onClose={() => {
              setShowVehicleSpecificationDrawer(false)
              setOpenSpecDrawerExcludeGroups(false)
            }}
          />
          {showServiceIntervalsDrawer && (
            <ServiceIntervalsDrawer
              open={showServiceIntervalsDrawer}
              onClose={() => {
                setShowServiceIntervalsDrawer(false)
              }}
              showEditFitmentConditions={showEditFitmentConditions}
              handleEditFitmentConditions={handleEditFitmentConditions}
            />
          )}
        </Wrapper>
      </Grow>
    )
  }
)

const MobileVehicleInfo = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`
const MobileVehicleInfoChild = styled.div`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    width: 342px;
    height: 24px;
    flex-direction: row;
    padding: 8px 0px 24px 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
`

const Wrapper = styled.div`
  flex-grow: 1;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: column;
    align-items: baseline;
  }
`

const LeftSide = styled.div`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
  }
`

const DetailsInfoAction = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  svg {
    margin-right: 6px;
    font-size: 17px;
  }
  span {
    margin-left: 0;
    margin-right: 24.67px;
  }
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    height: 44px;
    width: 390px;
    margin-left: 24px;
    margin-right: 27px;
    display: flex;
    align-items: flex-start;
    gap: 13px;
    span {
      height: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 15px;
    }
    svg {
      width: 18px;
      height: 20px;
    }
  }
`

const DetailsInfo = styled.div`
  display: flex;
  margin-top: 9px;
  justify-content: baseline;
  color: ${defaultTheme.colors.link};
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: column;
    align-items: baseline;
    height: 100%;
  }
`

const RightSide = styled.div`
  display: flex;
  gap: 37px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const MobileRightSide = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
`

const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: column;
  }
`
const MobileEditButton = styled.div`
  display: flex;
  justify-content: center;
`
const MobileDeleteButton = styled.div`
  display: flex;
  justify-content: center;
`
const VehicleInfo = styled(SpaceBetweenRow)``

const Title = styled.span`
  display: flex;
  font-family: Oswald, serif;
  font-weight: bold;
  font-size: 24px;
`

const VehicleDetailLabel = styled.span`
  align-self: flex-end;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: bold;
  margin-left: 16px;
  margin-right: 4px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-left: 24px;
  }
`

const VehicleDetail = styled.span`
  align-self: flex-end;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: normal;
`
const VehicleDetailLink = styled.button`
  align-self: flex-end;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: normal;
  background: none;
  border: none;
  color: ${defaultTheme.colors.blueLight};
`

const EditButton = styled.div`
  display: flex;
  align-items: center;
  font-family: Oswald, serif;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100;
  }
`

const EditFitmentConditions = styled.div`
  padding-top: 0.5rem;
  align-self: flex-start;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #3c91e6;
  cursor: pointer;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
    width: 100;
  }
`
const EditIcon = styled.div`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`
const DeleteIcon = styled.div`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const StyledEditIcon = styled(Edit)`
  margin-top: -5px;
  padding-right: 5px;
`
const StyledSpinner = styled(Spinner)`
  margin-right: 5px;
`
