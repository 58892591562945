import { ReactElement } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { breakpoints } from 'src/theme/breakpoints'
import { useHistory } from 'react-router-dom'
import { CustomizeSearchContext } from 'src/store/search/CustomizeSearchStore'
import { Collapse } from 'react-bootstrap'
import { AdvancedVehicleSearch } from './AdvancedVehicleSearch'
import { SelectedVehicle } from './SelectedVehicle'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
  VehicleWidgetErrorState,
  VehicleWidgetStoreContext,
} from './store/VehicleWidgetStore'
import { SimpleModal } from '../../../ui-components'
import { StoreInstances } from '../../../store/StoreInstancesContainer'
import { CustomizeSearchModal } from 'src/features/customizeSearch/components/CustomizeSearchModal'
import Sticky from 'react-stickynode'
import { isMobile } from 'react-device-detect'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { SearchOptions } from 'src/features/customizeSearch/interfaces'

export const VehicleWidget = observer((): ReactElement => {
  const vehicleWidgetStore = useVehicleWidgetStore()
  const { showCustomizeSearchModal } = useUiStateStore()
  const history = useHistory()

  const viewMode =
    vehicleWidgetStore.displayState === VehicleWidgetDisplayState.view

  return (
    <Sticky top={160} innerZ={2} enabled={isMobile}>
      <Collapse in={vehicleWidgetStore.isDrawerOpened}>
        <div id="collapse-vehicle-widget">
          <VehicleWidgetStoreContext.Provider value={vehicleWidgetStore}>
            <CustomizeSearchContext.Provider
              value={StoreInstances.customizeSearchStore}
            >
              <Wrapper>
                {viewMode ? (
                  <SelectedVehicle
                    onEditVehicle={() => {
                      vehicleWidgetStore.setDisplayState(
                        VehicleWidgetDisplayState.edit
                      )
                    }}
                  />
                ) : (
                  vehicleWidgetStore.isDrawerOpened && <AdvancedVehicleSearch />
                )}
                <SimpleModal
                  show={
                    vehicleWidgetStore.errorState ===
                    VehicleWidgetErrorState.vehicleNotFound
                  }
                  title="vehicleNotFound"
                  primaryActionText="vehicleSearch"
                  onPrimaryAction={() => {
                    vehicleWidgetStore.clearErrorState()
                    history.push('/home')
                    StoreInstances.customizeSearchStore.setSelectedTab(
                      SearchOptions.vehicle_ymme
                    )
                  }}
                  onSecondaryAction={vehicleWidgetStore.clearErrorState}
                >
                  aVehicleWasNotFound
                </SimpleModal>
                <SimpleModal
                  show={
                    vehicleWidgetStore.errorState ===
                    VehicleWidgetErrorState.vehicleIncomplete
                  }
                  title="vehicleIncomplete"
                  primaryActionText="continue"
                  onPrimaryAction={vehicleWidgetStore.clearErrorState}
                  onSecondaryAction={vehicleWidgetStore.clearErrorState}
                >
                  vehicleInformationWasIncomplete
                </SimpleModal>
                <SimpleModal
                  show={
                    vehicleWidgetStore.errorState ===
                    VehicleWidgetErrorState.vinDigitCheckFailed
                  }
                  title="invalidVin"
                  primaryActionText="continue"
                  onPrimaryAction={vehicleWidgetStore.clearErrorState}
                  onSecondaryAction={vehicleWidgetStore.clearErrorState}
                >
                  thisVinIsInvalidTryAgain
                </SimpleModal>
              </Wrapper>
              {showCustomizeSearchModal && <CustomizeSearchModal />}
            </CustomizeSearchContext.Provider>
          </VehicleWidgetStoreContext.Provider>
        </div>
      </Collapse>
    </Sticky>
  )
})

// TODO fix pixel top realtive position
const Wrapper = styled.div`
  background-color: #fafafa;
  padding: 24px 35px 24px 44px;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 25px 18px 0 16px;
  }
  @media print {
    display: none;
  }
`
