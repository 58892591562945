import { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { Autorenew, CallSplit, CompareArrows } from '@mui/icons-material'
import { I18n } from 'src/i18n'

interface TagProps {
  children: ReactNode
  className?: string
}

export const ReplacementTag = ({
  children,
  className,
}: TagProps): ReactElement => {
  return (
    <Tag
      icon={<Autorenew htmlColor="#3C91E6" fontSize="small" />}
      color="#E2EFFB"
      message="replacementFor"
      className={className}
    >
      {children}
    </Tag>
  )
}
ReplacementTag.defaultProps = {
  className: '',
}

export const InterchangeTag = ({
  children,
  className,
}: TagProps): ReactElement => {
  return (
    <Tag
      icon={<CompareArrows htmlColor="#D9001B" fontSize="small" />}
      color="#FBE6E8"
      message="interchangeFor"
      className={className}
    >
      {children}
    </Tag>
  )
}
InterchangeTag.defaultProps = {
  className: '',
}

export const AlternateTag = ({
  children,
  className,
}: TagProps): ReactElement => {
  return (
    <Tag
      icon={<CallSplit htmlColor="#000" fontSize="small" />}
      color="#FEF6D9"
      message="alternateFor"
      className={className}
    >
      {children}
    </Tag>
  )
}
AlternateTag.defaultProps = {
  className: '',
}

interface BaseTagProps {
  icon: ReactElement
  color: string
  message: string
  className?: string
  children: ReactNode
}

const Tag = ({
  icon,
  color,
  message,
  className,
  children,
}: BaseTagProps): ReactElement => {
  return (
    <Wrapper className={className} color={color}>
      {icon}
      &nbsp;
      <I18n name={message} />
      &nbsp;
      <PartNumber>{children}</PartNumber>
    </Wrapper>
  )
}

Tag.defaultProps = {
  className: '',
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  height: 38px;
  font-size: 14px;
  font-family: Roboto;
  border-radius: 2px;
  background-color: ${(p) => p.color};
  margin-top: 12px;
`
const PartNumber = styled.span`
  font-weight: bold;
`
