import { Settings } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import styled from 'styled-components'
import { Translate } from 'src/i18n'

export const CustomizeSearchButton = observer((): ReactElement => {
  const { setShowCustomizeSearchModal } = useUiStateStore()

  return (
    <Wrapper onClick={() => setShowCustomizeSearchModal(true)}>
      <SettingsIcon />
      {Translate('customizeSearch')}
    </Wrapper>
  )
})

const SettingsIcon = styled(Settings)`
  padding-right: 0.2rem;
  font-size: 25px;
`

const Wrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: ${(p) => p.theme.colors.link};
  cursor: pointer;
`
