import {
  TransferPartsData,
  TransferPartsDataResponse,
} from 'src/store/models/ShoppingCartModels'
import BaseServiceProvider from './BaseServiceProvider'

class OsnServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_OP_URL, 'osn', '', '')
  }

  transferCartParts = async (
    transferPartsData: TransferPartsData,
    token: string
  ): Promise<TransferPartsDataResponse | undefined> => {
    try {
      const resp = await this.post<TransferPartsDataResponse>(
        'partsBinJsonTocXml',
        transferPartsData,
        {
          params: {
            token,
          },
        }
      )
      return resp.data
    } catch (error) {
      return undefined
    }
  }
}

export default new OsnServiceProvider()
