import ArrowBack from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@mui/material/Drawer'
import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect, useState, useRef } from 'react'
import { useClickOutsideDetector } from 'src/hooks'
import { I18n } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { IdValuePair } from 'src/store/models/KeyValuePair'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { Button, RadioGroup } from 'src/ui-components'
import styled from 'styled-components'

const MoveListConfirmation = observer((): ReactElement => {
  const {
    moveListModal,
    setMoveListModal,
    moveSelectedList,
    onMoveList,
    root,
  } = useListsStore()

  const show = moveListModal
  const ref = useRef<HTMLInputElement>()

  const [targetCategoryId, setTargetCategoryId] = useState(0)

  const onBack = () => {
    setMoveListModal(false)
  }

  const handleMoveConfirmed = () => {
    onMoveList(moveSelectedList.id, targetCategoryId)
  }
  useClickOutsideDetector([ref], show, () => setMoveListModal(false))

  useEffect(() => {
    if (moveListModal === false) {
      setTargetCategoryId(0)
    }
  }, [moveListModal])

  return (
    <ResponsiveDrawer
      anchor="bottom"
      open={show}
      onClose={() => setMoveListModal(false)}
      PaperProps={{
        square: false,
      }}
      style={{
        width: '800px',
      }}
    >
      <Container ref={ref}>
        <HeaderWrapper>
          <StyledBackIcon onClick={onBack} />
          <TitleHeader>
            <div>
              <I18n name="moveList" />:{'\u00a0'}
              {moveSelectedList?.label}
            </div>

            <StyledCloseIcon onClick={() => setMoveListModal(false)} />
          </TitleHeader>
        </HeaderWrapper>
        <ConfirmationTextContainer>
          <ConfirmationText>
            <I18n name="Categories" />
          </ConfirmationText>

          <RadioGroup
            name=""
            id="1_1"
            options={root
              .getChildren()
              .filter((cat) => cat.id !== moveSelectedList?.category)
              .map((category) => ({
                id: category.id.toString(),
                value: category.value,
              }))}
            onChange={(sel: IdValuePair<string>): void => {
              setTargetCategoryId(parseInt(sel.id, 10))
            }}
          />
        </ConfirmationTextContainer>

        <ButtonContainer>
          <CancelButton onClick={onBack} text="cancel" variant="secondary" />
          <Button
            onClick={handleMoveConfirmed}
            text="moveList"
            variant="primary"
            disabled={targetCategoryId === 0}
          />
        </ButtonContainer>
      </Container>
    </ResponsiveDrawer>
  )
})

export default MoveListConfirmation

const ConfirmationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  margin: 10px 0 0px 0px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin: 10px 0 0px 0px;
  }
`

const ConfirmationText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 24px;
  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin-left: 0px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
  padding: 24px;
  margin-bottom: 56px;

  button {
    width: 294px;
  }

  @media screen and (min-width: ${breakpoints.laptop}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: none;
    gap: 16px;
    margin: 47px 0px 0px 0px;
    padding: 0px;
    button {
      width: 107.43px;
    }
  }
`

const CancelButton = styled(Button)`
  display: none;
  @media screen and (min-width: ${breakpoints.laptop}px) {
    display: block;
  }
`

const ResponsiveDrawer = styled(Drawer)``

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-top: 5.5px;
`
const StyledBackIcon = styled(ArrowBack)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 27px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    display: none;
  }
`

const HeaderWrapper = styled.div`
  padding: 24px 24px 12px 24px;
  @media screen and (min-width: ${breakpoints.laptop}px) {
    padding: 0px;
    margin-bottom: 0px;
  }
`

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: ${defaultTheme.colors.primaryText};
  margin-left: 30px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin-left: 0px;
    font-size: 24px;
  }
`
interface ContainerProps {
  height?: string
  width?: string
}

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${defaultTheme.colors.componentsBackground};
  max-height: 70%;
  padding: 24px;
  overflow-y: auto;
  border-radius: 8px 8px 0px 0px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0px;
    width: 500px;
    min-height: 228px;
    padding: 24px 32px;
  }
`
