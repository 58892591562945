import { ReactElement, useRef, useState } from 'react'
import { Box, Grow } from '@mui/material'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { Overlay } from 'react-bootstrap'
import styled from 'styled-components'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { zIndex } from 'src/theme/zIndex'
import { useClickOutsideDetector } from 'src/hooks'
import { defaultTheme } from 'src/theme'
import { I18n } from 'src/i18n'

interface SelectProps {
  options: Array<IdValueGeneric<string | number, string>>
  selectedOption: IdValueGeneric<string | number, string>
  onSelect: (selected: IdValueGeneric<string | number, string>) => void
  width?: string
  optionFontSize?: string
  defaultValue?: string
  optionsBoxRef?: React.RefObject<HTMLInputElement>
}

export const Select = (selectProps: SelectProps): ReactElement => {
  const {
    options,
    selectedOption,
    onSelect,
    width,
    optionFontSize,
    defaultValue,
    optionsBoxRef,
  } = selectProps
  const [isActive, setIsActive] = useState(false)
  const overlayRef = useRef<HTMLDivElement>(null)
  const fieldRef = useRef<HTMLInputElement>(null)
  useClickOutsideDetector([overlayRef, fieldRef], isActive, () =>
    setIsActive(false)
  )

  const optionsWidth = fieldRef?.current?.offsetWidth

  const handleSelect = (selected: IdValueGeneric<string | number, string>) => {
    onSelect(selected)
    setIsActive(false)
  }

  const enableSelect = options && options.length > 0

  const showOptions = () => {
    if (enableSelect) {
      setIsActive(!isActive)
    }
  }

  const arrowColor = enableSelect ? '#000' : defaultTheme.colors.greyMedium

  return (
    <Wrapper>
      <SelectElement
        width={width}
        onClick={showOptions}
        ref={fieldRef}
        disable={!enableSelect}
      >
        <SelectElementText>
          {selectedOption?.value ? (
            <I18n name={selectedOption?.value} />
          ) : defaultValue ? (
            <b>
              <I18n name={defaultValue} />
            </b>
          ) : null}
        </SelectElementText>
        {isActive ? <ArrowDropUp /> : <ArrowDropDown htmlColor={arrowColor} />}
      </SelectElement>

      <Overlay
        show={isActive}
        placement="bottom-start"
        popperConfig={{ strategy: 'absolute' }}
        target={fieldRef.current}
      >
        {({ ...props }) => (
          <OptionsWrapper
            {...props}
            style={{
              ...props.style,
              zIndex: zIndex.modal,
            }}
          >
            <Box>
              <Grow in={isActive}>
                <InnerWrapper
                  ref={optionsBoxRef ?? overlayRef}
                  style={{
                    width: `${optionsWidth}px`,
                  }}
                >
                  {options?.map((option) => {
                    return (
                      <Option
                        optionFontSize={optionFontSize}
                        key={option.id}
                        onClick={() => handleSelect(option)}
                      >
                        <I18n name={option.value} />
                      </Option>
                    )
                  })}
                </InnerWrapper>
              </Grow>
            </Box>
          </OptionsWrapper>
        )}
      </Overlay>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const SelectElement = styled.span<{ width?: string; disable: boolean }>`
  border: ${(props) => props.theme.fieldBorder};
  display: flex;
  font-size: 14px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  min-width: ${(props) => (props.width ? props.width : '200px')};
  width: ${(props) => (props.width ? props.width : undefined)};
  margin-right: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  ${(props) =>
    props.disable &&
    `color: ${defaultTheme.colors.greyMedium}; 
    background-color:  ${defaultTheme.colors.disabledBackground};; 
     cursor: not-allowed;
   `}
`

const SelectElementText = styled.span`
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Option = styled.div<{ optionFontSize?: string }>`
  padding: 10px;
  font-weight: 400;
  font-size: ${(props) =>
    props.optionFontSize ? props.optionFontSize : '12px'};
  color: #000;
  cursor: pointer;
  &:hover {
    background: #f3f2ee;
  }
  &:active {
    background: #777777;
  }
`

const OptionsWrapper = styled.div`
  display: inline-block;
  z-index: ${zIndex.dropdown};
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #fff;
  color: ${(p) => p.theme.colors.text};
  border-radius: 3px;
  border: ${(p) => p.theme.fieldBorder};
  max-height: 300px;
  overflow: auto;
`
