import { enqueueSnackbar, closeSnackbar } from 'notistack'
import { Link } from 'src/ui-components'
import styled from 'styled-components'
import NotificationMessage from './NotiStackMessage'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { Close } from '@mui/icons-material'
import { ToastNotificationVariant } from '../util/Interfaces'

export interface ToastNotificationProps {
  open: boolean
  variant?: ToastNotificationVariant
  message: string
  messageParams?: Array<string | number>
  link?: Link
  time?: number
  onClose?: () => void
}

const ShowNotification = (props: ToastNotificationProps): void => {
  const NotificationVariant =
    props.variant === ToastNotificationVariant.ERROR
      ? 'error'
      : props.variant === ToastNotificationVariant.SUCCESS
        ? 'success'
        : props.variant === ToastNotificationVariant.WARNING
          ? 'warning'
          : 'info'

  const handleClickedLink = () => {
    props.link?.action()
    closeSnackbar()
  }

  const action = () => {
    return (
      <>
        {props.link && (
          <StyledAction onClick={() => handleClickedLink()}>
            {props.link.text}
          </StyledAction>
        )}
        <StyledClose onClick={() => closeSnackbar()} />
      </>
    )
  }

  const topPosition =
    StoreInstances.uiStore.bannerMessage !== '' ? '3.5rem' : '0px'

  enqueueSnackbar(
    <NotificationMessage
      message={props.message}
      messageParams={props.messageParams}
    />,
    {
      variant: NotificationVariant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      action,
      style: { top: `${topPosition}`, position: 'relative' },
    }
  )
}

const StyledAction = styled.span`
  padding: 8px 0.5rem 0 3rem;
  text-decoration: underline;
  align-content: end;
  white-space: nowrap;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
`

const StyledClose = styled(Close)`
  cursor: pointer;
  margin: 8px 0 0 0;
`

export default ShowNotification
