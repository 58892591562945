import {
  IdGroupsResponse,
  IdsEnginesResponse,
  IPartItemResponse,
  JobsResponse,
  JobTerminologiesResponse,
} from 'src/features/partsCatalog/Selections/interfaces'
import { Config } from 'src/config/ConfigManager'
import AxiosInstance from '../../../api/axios'
import { CatalogNode } from '../CatalogNode'
import { CatalogAPI } from './ApiInterface'
import { CatalogTreeParser } from '../CatalogTreeParser'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { Vehicle } from 'src/store/models/Vehicles'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

export class JobsCatalogAPI implements CatalogAPI {
  CATEGORY_ENDPOINT

  GROUP_ENDPOINT

  TERMINOLOGY_ENDPOINT

  ENDPOINT_BASE

  SESSION_STORAGE_KEY

  constructor() {
    this.ENDPOINT_BASE = Config.urls.catalog
    this.CATEGORY_ENDPOINT = 'categories'
    this.GROUP_ENDPOINT = 'jobs'
    this.TERMINOLOGY_ENDPOINT = 'jobTerminologies'
    this.SESSION_STORAGE_KEY = 'selected-jobs-catalog-tree'
  }

  fetchCategories = async (): Promise<
    Array<IdValueGeneric<string, string>>
  > => {
    const resp = await AxiosInstance.post<
      Array<IdValueGeneric<string, string>>
    >(
      `${this.ENDPOINT_BASE}/${this.CATEGORY_ENDPOINT}`,
      StoreInstances.searchStore.currentVehicle,
      {
        params: {
          catalogId: StoreInstances.searchStore.currentVehicle?.type
            ? StoreInstances.searchStore.currentVehicle?.type.id
            : 111,
        },
      }
    )
    return resp.data
  }

  parseJobsResponseIntoIdGroupsItem = (
    resp: JobsResponse
  ): IdGroupsResponse => {
    const data = resp.map((item) => {
      return {
        id: item.categoryId,
        value: item.categoryName,
        items: item.items.map((i) => {
          return {
            id: i.jobId,
            value: i.jobName,
          }
        }),
      }
    })
    return data
  }

  parsePartTypesJobsResponseIntoIdGroupsItem = (
    resp: JobTerminologiesResponse
  ): IdGroupsResponse => {
    const data = resp.map((item) => {
      return {
        id: item.jobId,
        value: item.jobName,
        items: item.terminologies.map((i) => {
          return {
            id: i.id,
            value: i.value,
          }
        }),
      }
    })
    return data
  }

  fetchGroups = async (
    categories: Array<IdValueGeneric<string, string>>
  ): Promise<IdGroupsResponse> => {
    try {
      const resp = await AxiosInstance.post<JobsResponse>(
        `${this.ENDPOINT_BASE}/${this.GROUP_ENDPOINT}`,
        {
          categories,
        },
        {
          params: {
            catalogId: StoreInstances.searchStore.currentVehicle?.type
              ? StoreInstances.searchStore.currentVehicle?.type.id
              : 111,
          },
        }
      )
      const data = this.parseJobsResponseIntoIdGroupsItem(resp.data)
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log(data)
      return data
    } catch (error) {
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log(error)
    }
    return []
  }

  fetchPartTypes = async (
    _groups: Array<IdValueGeneric<string, string>>,
    _vehicle: Vehicle,
    catalogNode: CatalogNode[]
  ): Promise<IPartItemResponse | IdsEnginesResponse> => {
    try {
      const newGroups = catalogNode.map((node) => ({
        jobId: Number(node.id),
        categoryId: Number(node.getParent().id),
      }))
      const resp = await AxiosInstance.post<JobTerminologiesResponse>(
        `${this.ENDPOINT_BASE}/${this.TERMINOLOGY_ENDPOINT}`,
        newGroups,
        {
          params: {
            catalogId: StoreInstances.searchStore.currentVehicle?.type
              ? StoreInstances.searchStore.currentVehicle?.type.id
              : 111,
          },
        }
      )
      const data = this.parsePartTypesJobsResponseIntoIdGroupsItem(resp.data)
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log(data)
      return data
    } catch (error) {
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log(error)
    }
    return []
  }

  retrieveSelectionsFromSession = (parser: CatalogTreeParser): CatalogNode => {
    const savedJson = sessionStorage.getItem(this.SESSION_STORAGE_KEY)
    if (savedJson?.length > 3) {
      return parser.parse(savedJson || '')
    }
    return undefined
  }

  storeSelectionsOnSession = (selections: string): void => {
    sessionStorage.setItem(this.SESSION_STORAGE_KEY, selections)
  }

  isGfxSupported = (): boolean => {
    return false
  }
}
