import { UserAccessDetails } from 'src/api/user/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'

import { StoreInstances } from 'src/store/StoreInstancesContainer'

class UserInfoServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'userinfo', '1.0', '')
  }

  acceptTermsAndConditions = async (): Promise<number> => {
    try {
      const response = await this.put('accept-eula')
      if (response.status === 200) {
        StoreInstances.userStore.setUserPreferences({
          ...StoreInstances.userStore.preferences,
          EULA_accepted: 'true',
        })
      }
      return response.status
    } catch (e) {
      StoreInstances.uiStore.displayErrorNotification(
        'failedToAcceptTermsAndConditions'
      )
      return null
    }
  }

  getUserAccess = async (userId: string): Promise<UserAccessDetails> => {
    // Using axios as this API doesn't require authentication.
    const { data } = await this.get<UserAccessDetails>('useraccess', {
      params: { userId },
    })
    return data
  }
}

export default new UserInfoServiceProvider()
