import { FormikProps } from 'formik'
import { makeAutoObservable, reaction } from 'mobx'
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
} from 'react'
import { IEstimateReqData } from 'src/api/shipment/interfaces'
import { Address } from 'src/api/user/interfaces'
import { PUROLATOR } from 'src/features/cart/components/Purolator/constants'
import { VehicleWidgetDisplayState } from 'src/features/search/VehicleSearch/store/VehicleWidgetStore'
import OrderServiceProvider from 'src/services/OrderServiceProvider'
import OsnServiceProvider from 'src/services/OsnServiceProvider'
import ShipmentServiceProvider from 'src/services/ShipmentServiceProvider'
import UserDataServiceProvider from 'src/services/UserDataServiceProvider'
import {
  LocationTransports,
  OrderResponse,
  OrderResponseDetail,
  Transport,
  Vehicle as VehicleResponse,
} from '../../api/cart/interfaces'
import { EmbeddedCommunicationsManager } from '../../api/embedded/EmbeddedCommunicationsManager'
import authManager from '../../api/security/Auth'
import { Optional } from '../../common-interfaces/generic-interfaces'
import {
  GaTrackOption,
  GoogleTagManager,
} from '../../config/analytics/GoogleTagManager'
import { Config, IS_INTEGRATED_VERSION } from '../../config/ConfigManager'
import { CantBeFulfilledException } from '../../exceptions/CantBeFulfilledException'
import { OrderFormValidationException } from '../../exceptions/OrderFormValidationException'
import miscellaneousVehicle, {
  MISCELLANEOUS_CART_ID,
} from '../../features/search/Results/utils/miscellaneousVehicle'
import { getTotalPerOrder } from '../../helpers/orderConfirmationUtils'
import { timeout } from '../../theme/timeout'
import { LaborItem } from '../models/LaborModel'
import {
  AvailabilityError,
  AvailabilityErrorType,
  ProductLocationModel,
  ProductModel,
} from '../models/ProductModel'
import { Region } from '../models/Regions'
import {
  CartMode,
  CartPartDetails,
  CartVehicle,
  OrderFormData,
  OrderSelection,
  ShoppingCartData,
  ShoppingCartOnlyData,
  ShoppingCartProduct,
  TransferPart,
  TransferPartsDataResponse,
} from '../models/ShoppingCartModels'
import { Vehicle } from '../models/Vehicles'
import { VehicleSpecificationCartItem } from '../models/VehicleSpecification'
import { StoreInstances } from '../StoreInstancesContainer'
import { buildAvailabilityError, validatePrices } from './CartValidations'
import {
  allowOrderWithoutAvailableInventory,
  currentLocationHasQuantity,
  findOrderSelection,
  getGoogleAnalyticsCategory,
  getLocationById,
  getUpdatedPartsList,
  makeNewCartVehicle,
  productsMatch,
  selectLocationByQty,
} from './Utils'

const getCartStorageKey = () => {
  return `cart_${authManager.getUserId()}`
}

export const ShoppingCartContext = createContext<ShoppingCart | undefined>(
  undefined
)

export const useShoppingCart = (): ShoppingCart => {
  const shoppingCartStore = useContext(ShoppingCartContext)
  if (!shoppingCartStore) {
    throw new Error(
      'No ShoppingCartContext.Provider found when calling useShoppingCart.'
    )
  }
  return shoppingCartStore
}

export interface ISetQtyProps {
  product: ProductModel
  locationId: string
  quantity: number
  onLocationChange?: (selectedLocation: ProductLocationModel) => void
  autoLocationChange?: boolean
  vehicle: Vehicle
  setLocationChangeTooltip?: Dispatch<SetStateAction<boolean>>
}

export class ShoppingCart {
  private data: ShoppingCartData

  public cartValidations: Record<
    string,
    MutableRefObject<FormikProps<OrderFormData>>
  >

  commonPoNumber = ''

  commonNoteToStore = ''

  displayOrderModal = false

  disableStores = false

  onLocationChangeTooltip = false

  locationTooltipForProduct: { partNumber: string; active: boolean }

  replacedLocationName?: string = undefined

  replacementLocationName?: string = undefined

  quantitySelected = 0

  checkAvailabilityLoaded = false

  removeAllVehiclesModal = false

  availableTransports: Array<LocationTransports> = []

  addresses: Address[] = []

  /** Property to track the selected vehicle while redirecting to the product details page from cart. */
  private selectedVehicle?: Vehicle

  orderFormData: OrderFormData = {
    poNumber: '',
    noteToStore: '',
    customerName: '',
    personalNote: '',
    shipToAddress: '',
  }

  cartPartDetails?: CartPartDetails

  processingOrder = false

  purolatorEligibileLocations: Array<number> = null

  constructor() {
    makeAutoObservable(this)
    this.data = {
      version: Config.cartVersion,
      vehicle: undefined,
      vehicles: [],
    }
    this.cartValidations = {}
    this.locationTooltipForProduct = { partNumber: '', active: false }
  }

  getMode = (): CartMode => {
    if (!Config.isNotCartOnlyMode) {
      return CartMode.CART_ONLY
    }
    if (IS_INTEGRATED_VERSION) {
      return CartMode.SINGLE_VEHICLE
    }
    if (StoreInstances.userStore.preferences.cart_multiCart === 'true') {
      return CartMode.MULTI_VEHICLE
    }
    return CartMode.SINGLE_VEHICLE
  }

  public fetchTransports = async (): Promise<void> => {
    this.availableTransports = await OrderServiceProvider.getTransports()
    this.availableTransports = (this.availableTransports || []).map(
      (locationTransport) => {
        return {
          ...locationTransport,
          transports: locationTransport.transports?.map((t) => {
            const id = this.hashTransport(t)
            return { ...t, id }
          }),
        }
      }
    )
  }

  public getSelectedVehicle(): Vehicle | undefined {
    return this.selectedVehicle
  }

  public setLocationTooltipForProduct(
    partNumber: string,
    active: boolean
  ): void {
    this.locationTooltipForProduct = { partNumber, active }
  }

  public setSelectedVehicle(selectedVehicle?: Vehicle): void {
    this.selectedVehicle = selectedVehicle
  }

  public setProcessingOrder = (state: boolean): void => {
    this.processingOrder = state
  }

  public setVehicles = (vehicles: Array<CartVehicle>): void => {
    this.data = { ...this.data, vehicles }
  }

  public setTestPo = (value: string): void => {
    this.commonPoNumber = value
  }

  public emptyTestPo = (): void => {
    this.commonPoNumber = ''
  }

  public setCommonNoteToStore = (value: string): void => {
    this.commonNoteToStore = value
  }

  public emptyCommonNoteToStore = (): void => {
    this.commonNoteToStore = ''
  }

  public saveAllAsQuotes = (): void => {
    this.data.vehicles.forEach((cartVehicle) => {
      if (cartVehicle.products?.length > 0) {
        StoreInstances.quoteStore.saveAsQuote(cartVehicle)
      }
    })
  }

  public addVehicleToCart = (vehicle: Vehicle): void => {
    function arrayMove(arr, fromIndex, toIndex) {
      const element = arr[fromIndex]
      arr.splice(fromIndex, 1)
      arr.splice(toIndex, 0, element)
    }

    const reuseCart =
      this.getMode() === CartMode.SINGLE_VEHICLE &&
      this.data?.vehicles?.length > 0
    if (reuseCart) {
      const v = this.data.vehicles[0]
      v.vehicle = vehicle
      return
    }

    const vehicleAlreadyInTheList = this.getCurrentVehicleIndex(vehicle)
    if (vehicleAlreadyInTheList > -1) {
      arrayMove(this.data.vehicles, vehicleAlreadyInTheList, 0)
    } else {
      const newCartVehicle = makeNewCartVehicle(vehicle)
      this.data.vehicles.unshift(newCartVehicle)
    }
    StoreInstances.vehicleWidget.setIsDrawerOpened(false)
    StoreInstances.vehicleWidget.setDisplayState(VehicleWidgetDisplayState.view)
  }

  public setOrderFormDataFieldValue = (field: string, value: string): void => {
    this.orderFormData[field] = value
  }

  public setDisplayOrderModal = (show: boolean): void => {
    this.displayOrderModal = show
  }

  public setFieldOnVehicleCart = (
    field: string,
    vehicle: Vehicle,
    value: string
  ): void => {
    this.data.vehicles[this.getCurrentVehicleIndex(vehicle)].orderFormData[
      field
    ] = value
  }

  public validateForm = async (
    ref: MutableRefObject<FormikProps<OrderFormData>>
  ): Promise<void> => {
    const form = ref.current
    const validation = await ref.current.validateForm()

    if (Object.keys(validation).length > 0) {
      form.setTouched({
        ...form.touched,
        poNumber: true,
        noteToStore: true,
      })
      throw new OrderFormValidationException('orderFormIsIncomplete')
    }
  }

  public orderAllCarts = async (): Promise<OrderResponse> => {
    const cartsWithProducts = this.data.vehicles.filter(this.isCartOrderAble)
    const validations = cartsWithProducts.map(
      (c) => this.cartValidations[c.vehicle?.id]
    )
    await Promise.all(validations.map(this.validateForm))

    const orderResponse = await this.placeOrder(cartsWithProducts)
    this.removeVehicleCarts(cartsWithProducts)
    return orderResponse
  }

  public setRemoveAllVehiclesModal = (show: boolean): void => {
    this.removeAllVehiclesModal = show
  }

  public addSpecificationAndLaborToOrderResponse = (
    currentOrder: OrderResponse
  ): OrderResponse => {
    return {
      ...currentOrder,
      orderDetails: currentOrder.orderDetails.map((orderDetail) => {
        const cartVehicle =
          this.vehicles[
            this.findVehicleIndexByOrderResponse(orderDetail.vehicle)
          ]

        return {
          ...orderDetail,
          specifications:
            cartVehicle?.specifications?.length > 0
              ? cartVehicle.specifications
              : [],
          laborData:
            cartVehicle?.laborResults?.length > 0
              ? cartVehicle.laborResults
              : [],
        }
      }),
    }
  }

  private findVehicleIndexByOrderResponse = (
    vehicleResponse: VehicleResponse
  ): number => {
    return (this.data.vehicles || []).findIndex((item) => {
      return (
        item?.vehicle?.year?.value === vehicleResponse?.year &&
        item?.vehicle?.make?.value === vehicleResponse?.make &&
        item?.vehicle?.model?.value === vehicleResponse?.model &&
        item?.vehicle?.engine?.value === vehicleResponse?.engine
      )
    })
  }

  public placeOrder = async (
    vehicles: CartVehicle[]
  ): Promise<OrderResponse> => {
    const response = await OrderServiceProvider.makeOrder(vehicles)
    const responseWithSpecsAndLabor =
      this.addSpecificationAndLaborToOrderResponse(response)

    this.trackPurchase(responseWithSpecsAndLabor.orderDetails) // before emptying the cart because it uses the cart to complete the vehicle information
    if (IS_INTEGRATED_VERSION) {
      EmbeddedCommunicationsManager.transferOrderResponse(
        StoreInstances.cart,
        responseWithSpecsAndLabor
      )
    }
    return responseWithSpecsAndLabor
  }

  // Use this method to track transferred items only, not ordered items
  public trackCartTransfer = (): void => {
    const { countryCode } = StoreInstances.userStore.country || {}
    const regionId = Region[countryCode]
    this.data.vehicles.forEach((v) => {
      const gaItems = v.products.map((p) => {
        const category = getGoogleAnalyticsCategory(
          p?.allianceterminologyId ?? '',
          p?.description ?? ''
        )
        const gaProductName = GoogleTagManager.buildProductName(p)
        const loc = getLocationById(p, p.orderSelections?.[0]?.locationId)
        return {
          id: p.allianceProductId, // aka SKU
          name: gaProductName,
          brand: p.brandName ?? p.manufacturerName,
          location: loc.called,
          price: loc.cost,
          category,
          quantity: p.orderSelections?.[0]?.quantityRequested,
        }
      })
      const cartTotal =
        this.cartVehicleCostSubTotal(v.vehicle) +
        this.cartVehicleCostCoreTotal(v.vehicle)
      GoogleTagManager.setVehicleDimensions(v.vehicle, regionId)
      GoogleTagManager.trackEvent('begin_checkout', {
        value: cartTotal,
        items: gaItems,
        // Hardcoding below values for now as requested in MP4P-830
        coupon: 0,
        currency: 'USD', // Harcoded until internacionalization is implemented
        shipping: 0,
        tax: 0,
      })
    })
  }

  public trackPurchase = (orders: Array<OrderResponseDetail>): void => {
    const { countryCode } = StoreInstances.userStore.country
    const regionId = Region[countryCode]
    orders.forEach((order) => {
      let fullVehicleInfo = this.data.vehicles.find(
        (vehicleInCart) =>
          vehicleInCart.vehicle?.year?.value === order.vehicle.year &&
          vehicleInCart.vehicle?.make?.value === order.vehicle.make &&
          vehicleInCart.vehicle?.model?.value === order.vehicle.model &&
          vehicleInCart.vehicle?.engine?.value === order.vehicle.engine
      )
      if (!fullVehicleInfo) {
        fullVehicleInfo = { vehicle: miscellaneousVehicle, products: [] }
      }
      order.locations.forEach((location) =>
        location.orders.forEach((orderPerLocation) => {
          const gaItems = orderPerLocation.parts.map((part) => {
            return {
              id: part.allianceProductId, // aka SKU
              name: GoogleTagManager.buildProductName(part),
              brand: part.manufacturerName, // The brand name is missing in the API response, the manufacturer name equivalent
              // MP4P-1212 - the allianceTerminologyID returns whatever value we previously sent to the API, so we send the category description instead of the ID to avoid some extra steps
              category: part?.allianceTerminologyId?.toString(),
              price: part.unitListPrice,
              quantity: part.quantityRequested,
            }
          })
          const orderTotal = getTotalPerOrder(orderPerLocation)
          GoogleTagManager.setVehicleDimensions(
            fullVehicleInfo.vehicle,
            regionId
          )
          GoogleTagManager.trackEvent('purchase', {
            transaction_id: orderPerLocation.orderNumber,
            affiliation: location.locationDescription,
            items: gaItems,
            value: orderTotal,
            // Hardcoding below values for now as requested in MP4P-830
            coupon: 0,
            currency: 'USD', // Harcoded until internacionalization is implemented
            shipping: 0,
            tax: 0,
          })
        })
      )
    })
  }

  public placeIndividualVehicleOrder = async (
    vehicle: CartVehicle
  ): Promise<OrderResponse> => {
    const resp = await this.placeOrder([vehicle])
    this.removeVehicleCart(vehicle.vehicle, GaTrackOption.doNotTrack)
    return resp
  }

  public decreaseQtyAtLocation = (
    product: ProductModel,
    locationId: string,
    quantityToRemove = 1,
    vehicle: Vehicle
  ): void => {
    const currentQty = this.getQtyAtLocation(product, locationId, vehicle)
    this.setQtyAtLocationBulk([
      {
        product,
        locationId,
        quantity: currentQty - quantityToRemove,
        autoLocationChange: false,
        vehicle,
      },
    ])
  }

  private addProductToCart = (
    product: ShoppingCartProduct,
    vehicle: Vehicle
  ): void => {
    const vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    this.data.vehicles[vehicleIdx].products.push(product)
  }

  public setQtyAtLocationBulk = async (
    productDetails: ISetQtyProps[],
    validate = false
  ): Promise<void> => {
    let updatedParts: ProductModel[] = []
    // Fetch the updated part information to validate against the parts being added to cart.
    if (validate) {
      updatedParts = await getUpdatedPartsList(productDetails)
    }
    for (let i = 0; i < productDetails.length; i++) {
      const productDetail = productDetails[i]
      this.setQtyAtLocation(
        {
          ...productDetail,
          product: validate ? updatedParts[i] : productDetail.product,
        },
        getLocationById(productDetail.product, productDetail.locationId),
        validate
      )
    }
    return Promise.resolve()
  }

  private setQtyAtLocation = (
    {
      product,
      locationId,
      quantity,
      onLocationChange,
      autoLocationChange = true,
      vehicle,
      setLocationChangeTooltip,
    }: ISetQtyProps,
    originalLocation: ProductLocationModel,
    validate = false
  ): void => {
    // Part can't be added to cart without a location.
    if (locationId === undefined || locationId === '') {
      throw new Error('No location selected')
    }

    //Remove the part from cart if the quantity is invalid.
    if (quantity <= 0) {
      this.removeProductFromLocation(product, locationId, vehicle)
      return
    }

    const updatedProduct: ShoppingCartProduct = product

    updatedProduct.availabilityErrors = []

    let locationToSet = locationId
    if (autoLocationChange) {
      const firstLocationWithQty = selectLocationByQty(
        updatedProduct,
        quantity,
        true
      )
      /* Here we can see the ALS(II)/ALS(II.a)/ALS(II.a2) being applied */
      if (
        firstLocationWithQty.qtyAvailable < quantity &&
        !allowOrderWithoutAvailableInventory(firstLocationWithQty) &&
        !IS_INTEGRATED_VERSION
      ) {
        if (validate) {
          updatedProduct.availabilityErrors.push(
            buildAvailabilityError(AvailabilityErrorType.CANT_BE_FULFILLED)
          )
          this.addProductToCart(updatedProduct, vehicle)
        }
        throw new CantBeFulfilledException('productCantBeFulfilled')
      }
      /* In this conditional below, you can see the ALS(I) being checked.
      if the location manually selected (locationId) has enough quantity
      (currentLocationHasQuantity(updatedProduct, quantity, locationId)),
      the flow will not enter the conditional, therefore the location will
      be selected. ALS(I.a) or if the location doesn't have enough quantity,
      the conditional will check for new locations ALS(I.b)
      */
      if (
        firstLocationWithQty.locationId !== locationId &&
        !currentLocationHasQuantity(updatedProduct, quantity, locationId)
      ) {
        this.removeProductFromLocation(updatedProduct, locationId, vehicle)
        if (onLocationChange) {
          onLocationChange(firstLocationWithQty)
        }
        locationToSet = firstLocationWithQty.locationId
        if (validate) {
          updatedProduct.availabilityErrors.push(
            buildAvailabilityError(AvailabilityErrorType.LOCATION_OVERRIDE)
          )
        }
        const replacedLocationName = getLocationById(updatedProduct, locationId)
        const replacementLocationName = getLocationById(
          updatedProduct,
          locationToSet
        )
        this.replacedLocationName = replacedLocationName?.called
        this.replacementLocationName = replacementLocationName?.called
        if (setLocationChangeTooltip) {
          this.setLocationTooltipForProduct(updatedProduct?.partNumber, true)
          setLocationChangeTooltip(true)
        }
        setTimeout(() => {
          if (setLocationChangeTooltip) {
            this.setLocationTooltipForProduct(updatedProduct?.partNumber, false)
            setLocationChangeTooltip(false)
          }
        }, timeout.locationChangeTooltip)
      }
    }

    const location = getLocationById(updatedProduct, locationToSet)

    if (
      location.qtyAvailable < quantity &&
      !allowOrderWithoutAvailableInventory(location) &&
      !IS_INTEGRATED_VERSION
    ) {
      // Check if the quantity is available at other locations
      const findIfProductAvaliableInAnyLocation =
        updatedProduct.location.filter((item) => item.qtyAvailable >= quantity)

      if (updatedProduct.validationStatus === 'INVALID') {
        // Comes from  Abhilash's MR to support list validations
        updatedProduct.availabilityErrors.push(
          buildAvailabilityError(AvailabilityErrorType.CANT_BE_FULFILLED)
        )
      } else if (validate && !findIfProductAvaliableInAnyLocation.length) {
        updatedProduct.availabilityErrors.push(
          buildAvailabilityError(AvailabilityErrorType.CANT_BE_FULFILLED)
        )
      } else {
        updatedProduct.availabilityErrors.push(
          buildAvailabilityError(
            AvailabilityErrorType.CANT_BE_FULFILLED_FROM_SELECTED_LOCATION
          )
        )
      }
      this.addProductToCart(updatedProduct, vehicle)
    }

    if (
      location.qtyAvailable < quantity &&
      allowOrderWithoutAvailableInventory(location) &&
      validate
    ) {
      updatedProduct.availabilityErrors.push(
        buildAvailabilityError(AvailabilityErrorType.OUT_OF_STOCK_ALL_LOCATIONS)
      )
    }
    // @TODO: This makes sense for the current UI, because there is no way to select multiple locations. This will eventually need to be replaced.
    const previouslyAddedProduct = this.findProductMatch(
      updatedProduct,
      vehicle
    )
    if (previouslyAddedProduct?.orderSelections?.length) {
      this.removeProductFromLocation(
        updatedProduct,
        previouslyAddedProduct.orderSelections[0].locationId,
        vehicle
      )
      previouslyAddedProduct.availabilityErrors =
        updatedProduct.availabilityErrors
    } else {
      this.trackAddToCart(updatedProduct, vehicle, location, quantity)
    }

    if (validate) {
      const priceValidations = validatePrices(originalLocation, location)
      updatedProduct.availabilityErrors = priceValidations
    }

    const validatePrimaryLocation =
      StoreInstances.userStore.preferences.cart_nonPrimaryLocWarningEnabled?.toLowerCase() ===
      'true'

    if (validatePrimaryLocation) {
      const validatedProductLocation = this.validateLocation(
        updatedProduct,
        location?.locationId
      )
      updatedProduct.availabilityErrors =
        validatedProductLocation?.availabilityErrors
    }

    /* Here is where the ALS(III.a) is called */
    this.setOrderSelection(
      updatedProduct,
      location,
      quantity,
      vehicle,
      validate
    )

    // @TODO: Revisit this logic after MVP.  Since we will support multiple 'sub-carts' for different vehicles,
    // each cart needs to be associated with a vehicle, but only one vehicle will ever be active in the search
    // context at a time, and that vehicle might be edited by the user after they have items in the cart.
    // Updating the cart vehicle with the vehicle in the current search context each time an item is added
    // may make sense, but there may be a better way to approach this.
    this.setVehicle(StoreInstances.searchStore.currentVehicle)
  }

  // Commenting for Reference. Moved the usage to another method.
  // public setQtyAtLocation = async ({
  //   product,
  //   locationId,
  //   quantity,
  //   onLocationChange,
  //   autoLocationChange = true,
  //   vehicle,
  //   setLocationChangeTooltip,
  //   collectErrors = false,
  // }: SetQtyProps): Promise<void> => {
  //   // Part can't be added to cart without a location.
  //   if (locationId === undefined || locationId === '') {
  //     throw new Error('No location selected')
  //   }

  //   //Remove the part from cart if the quantity is invalid.
  //   if (quantity <= 0) {
  //     this.removeProductFromLocation(product, locationId, vehicle)
  //     return
  //   }

  //   const originalLocation = getLocationById(product, locationId)

  //   // We need to get the updated part if we going to collect the errors.
  //   const updatedProduct: ShoppingCartProduct = collectErrors
  //     ? await updatePartInfo(product, quantity, originalLocation)
  //     : product

  //   updatedProduct.availabilityErrors = []

  //   let locationToSet = locationId
  //   if (autoLocationChange) {
  //     const firstLocationWithQty = selectLocationByQty(
  //       updatedProduct,
  //       quantity,
  //       true
  //     )
  //     /* Here we can see the ALS(II)/ALS(II.a)/ALS(II.a2) being applied */
  //     if (
  //       firstLocationWithQty.qtyAvailable < quantity &&
  //       !allowOrderWithoutAvailableInventory(firstLocationWithQty) &&
  //       !IS_INTEGRATED_VERSION
  //     ) {
  //       if (collectErrors) {
  //         updatedProduct.availabilityErrors.push(
  //           buildAvailabilityError(AvailabilityErrorType.CANT_BE_FULFILLED)
  //         )
  //         this.addProductToCart(updatedProduct, vehicle)
  //       }
  //       throw new CantBeFulfilledException('productCantBeFulfilled')
  //     }
  //     /* In this conditional below, you can see the ALS(I) being checked.
  //     if the location manually selected (locationId) has enough quantity
  //     (currentLocationHasQuantity(updatedProduct, quantity, locationId)),
  //     the flow will not enter the conditional, therefore the location will
  //     be selected. ALS(I.a) or if the location doesn't have enough quantity,
  //     the conditional will check for new locations ALS(I.b)
  //     */
  //     if (
  //       firstLocationWithQty.locationId !== locationId &&
  //       !currentLocationHasQuantity(updatedProduct, quantity, locationId)
  //     ) {
  //       this.removeProductFromLocation(updatedProduct, locationId, vehicle)
  //       if (onLocationChange) {
  //         onLocationChange(firstLocationWithQty)
  //       }
  //       locationToSet = firstLocationWithQty.locationId
  //       if (collectErrors) {
  //         updatedProduct.availabilityErrors.push(
  //           buildAvailabilityError(AvailabilityErrorType.LOCATION_OVERRIDE)
  //         )
  //       }
  //       const replacedLocationName = getLocationById(updatedProduct, locationId)
  //       const replacementLocationName = getLocationById(
  //         updatedProduct,
  //         locationToSet
  //       )
  //       this.replacedLocationName = replacedLocationName?.called
  //       this.replacementLocationName = replacementLocationName?.called
  //       if (setLocationChangeTooltip) {
  //         this.setLocationTooltipForProduct(updatedProduct?.partNumber, true)
  //         setLocationChangeTooltip(true)
  //       }
  //       setTimeout(() => {
  //         if (setLocationChangeTooltip) {
  //           this.setLocationTooltipForProduct(updatedProduct?.partNumber, false)
  //           setLocationChangeTooltip(false)
  //         }
  //       }, timeout.locationChangeTooltip)
  //     }
  //   }

  //   const location = getLocationById(updatedProduct, locationToSet)

  //   if (
  //     location.qtyAvailable < quantity &&
  //     !allowOrderWithoutAvailableInventory(location) &&
  //     !IS_INTEGRATED_VERSION
  //   ) {
  //     // Check if the quantity is available at other locations
  //     const findIfProductAvaliableInAnyLocation =
  //       updatedProduct.location.filter((item) => item.qtyAvailable >= quantity)

  //     if (updatedProduct.validationStatus === 'INVALID') {
  //       // Comes from  Abhilash's MR to support list validations
  //       updatedProduct.availabilityErrors.push(
  //         buildAvailabilityError(AvailabilityErrorType.CANT_BE_FULFILLED)
  //       )
  //     } else if (collectErrors && !findIfProductAvaliableInAnyLocation.length) {
  //       updatedProduct.availabilityErrors.push(
  //         buildAvailabilityError(AvailabilityErrorType.CANT_BE_FULFILLED)
  //       )
  //     } else {
  //       updatedProduct.availabilityErrors.push(
  //         buildAvailabilityError(
  //           AvailabilityErrorType.CANT_BE_FULFILLED_FROM_SELECTED_LOCATION
  //         )
  //       )
  //     }
  //     this.addProductToCart(updatedProduct, vehicle)
  //   }

  //   if (
  //     location.qtyAvailable < quantity &&
  //     allowOrderWithoutAvailableInventory(location) &&
  //     collectErrors
  //   ) {
  //     updatedProduct.availabilityErrors.push(
  //       buildAvailabilityError(AvailabilityErrorType.OUT_OF_STOCK_ALL_LOCATIONS)
  //     )
  //   }
  //   // @TODO: This makes sense for the current UI, because there is no way to select multiple locations. This will eventually need to be replaced.
  //   const previouslyAddedProduct = this.findProductMatch(
  //     updatedProduct,
  //     vehicle
  //   )
  //   if (previouslyAddedProduct?.orderSelections?.length) {
  //     this.removeProductFromLocation(
  //       updatedProduct,
  //       previouslyAddedProduct.orderSelections[0].locationId,
  //       vehicle
  //     )
  //     previouslyAddedProduct.availabilityErrors =
  //       updatedProduct.availabilityErrors
  //   } else {
  //     this.trackAddToCart(updatedProduct, vehicle, location, quantity)
  //   }

  //   if (collectErrors) {
  //     const priceValidations = validatePrices(originalLocation, location)
  //     updatedProduct.availabilityErrors = priceValidations
  //   }

  //   const validatePrimaryLocation =
  //     StoreInstances.userStore.preferences.cart_nonPrimaryLocWarningEnabled?.toLowerCase() ===
  //     'true'

  //   if (validatePrimaryLocation) {
  //     const validatedProductLocation = this.validateLocation(
  //       updatedProduct,
  //       location?.locationId
  //     )
  //     updatedProduct.availabilityErrors =
  //       validatedProductLocation?.availabilityErrors
  //   }

  //   /* Here is where the ALS(III.a) is called */
  //   this.setOrderSelection(
  //     updatedProduct,
  //     location,
  //     quantity,
  //     vehicle,
  //     collectErrors
  //   )

  //   // @TODO: Revisit this logic after MVP.  Since we will support multiple 'sub-carts' for different vehicles,
  //   // each cart needs to be associated with a vehicle, but only one vehicle will ever be active in the search
  //   // context at a time, and that vehicle might be edited by the user after they have items in the cart.
  //   // Updating the cart vehicle with the vehicle in the current search context each time an item is added
  //   // may make sense, but there may be a better way to approach this.
  //   this.setVehicle(StoreInstances.searchStore.currentVehicle)
  // }

  private setOrderSelection(
    product: ShoppingCartProduct,
    location: ProductLocationModel,
    qtyToSet: number,
    vehicle: Vehicle,
    collectErrors: boolean
  ): void {
    if (!product.availabilityErrors) product.availabilityErrors = [] // Initialize errors array if undefined
    const { locationId, minQty } = location
    const cartProduct = this.findOrAddCartProduct(product, locationId, vehicle)
    // MPV3-3212 Sync updated location info.
    cartProduct.location = product.location
    let orderSelection = findOrderSelection(cartProduct, locationId)
    if (!orderSelection) {
      orderSelection = {
        locationId,
        quantityRequested: qtyToSet,
      }
      const orderSelections = []
      orderSelections.push(orderSelection)
      cartProduct.orderSelections = orderSelections
    }
    orderSelection.quantityRequested = qtyToSet
    if (qtyToSet < minQty && qtyToSet !== 0) {
      if (collectErrors) {
        cartProduct.availabilityErrors.push(
          buildAvailabilityError(AvailabilityErrorType.MIN_QTY_CHANGE)
        )
      }
      throw new Error('quantityRequestedIsLessThanMinimum')
    }
  }

  public isThereOnlyTheMiscellaneousCart = (): boolean => {
    const cartVehicleCheck = this.data.vehicles.some(
      (vehicles) => vehicles.vehicle.engine.id !== MISCELLANEOUS_CART_ID
    )
    if (cartVehicleCheck) return false
    if (this.vehicles?.length > 1) return false
    return true
  }

  public switchLocation = async (
    product: ShoppingCartProduct | ProductModel,
    orderSelection: OrderSelection,
    toLocation: ProductLocationModel,
    vehicle: Vehicle,
    setLocationChangeTooltip?: Dispatch<SetStateAction<boolean>>
  ): Promise<void> => {
    await this.setQtyAtLocationBulk([
      {
        product,
        locationId: toLocation.locationId,
        /* Here you can see ALS(II.a1)/ALS(II.a3) being applied.
          If the selected location is the primary and the user has a
          orderIfNotAvailable preference, then the autoLocationChange will be
          skipped and the primary location will be selected. */
        autoLocationChange: !allowOrderWithoutAvailableInventory(toLocation),
        quantity: orderSelection.quantityRequested,
        vehicle,
        setLocationChangeTooltip,
      },
    ])
    // this.removeProductFromLocation(product, orderSelection.locationId, vehicle)
    // this.setVehicles(this.vehicles) // I know! but it'll force the vehicle cart UI to refresh
    this.save()
  }

  public removeProductFromLocation(
    product: ProductModel,
    locationId: string,
    vehicle: Vehicle
  ): void {
    const cartProduct = this.findProductMatch(product, vehicle)
    if (!cartProduct) {
      return
    }
    cartProduct.orderSelections = cartProduct.orderSelections?.filter(
      (selection) => selection.locationId !== locationId
    )
  }

  public cleanEmptyCarts = (): void => {
    this.data.vehicles = this.data.vehicles?.filter((vehicle) => {
      return (
        !this.isCartEmpty(vehicle) ||
        this.vehiclesMatch(
          vehicle.vehicle,
          StoreInstances.searchStore.currentVehicle
        )
      )
    })
  }

  public cleanAllCarts = (trackOption: GaTrackOption): void => {
    if (trackOption === GaTrackOption.track) {
      this.data.vehicles.forEach((v) =>
        v.products.forEach((p) => this.trackRemoveFromCart(p, v.vehicle))
      )
    }
    this.data.vehicles = []
  }

  public removeVehicleCart = (
    vehicle: Vehicle,
    trackOption: GaTrackOption
  ): void => {
    const vehicleIndex = this.getCurrentVehicleIndex(vehicle)
    if (trackOption === GaTrackOption.track) {
      this.data.vehicles?.[vehicleIndex]?.products?.forEach((p) =>
        this.trackRemoveFromCart(p, vehicle)
      )
    }
    this.data.vehicles?.splice(vehicleIndex, 1)
    delete this.cartValidations[vehicle.id]
  }

  public removeVehicleCarts = (vehicles: Array<CartVehicle>): void => {
    vehicles.forEach(({ vehicle }) =>
      this.removeVehicleCart(vehicle, GaTrackOption.doNotTrack)
    )
  }

  public getQtyAtLocation = (
    product: ProductModel,
    locationId: string,
    vehicle: Vehicle
  ): number => {
    const matchingProduct = this.findProductMatch(product, vehicle)
    if (matchingProduct) {
      const matchingOrderSelection = matchingProduct.orderSelections.find(
        (l) => l.locationId.toString() === locationId.toString()
      )
      return matchingOrderSelection?.quantityRequested ?? 0
    }
    return 0
  }

  public getMinQtyAtLocation = (
    product: ProductModel,
    locationId: string,
    vehicle: Vehicle
  ): number => {
    const matchingProduct = this.findProductMatch(product, vehicle)
    if (matchingProduct) {
      const minQtyOfSelectedLocation = matchingProduct.location.find(
        (location) => location.locationId.toString() === locationId.toString()
      )
      return minQtyOfSelectedLocation?.minQty ?? 1
    }
    return 1
  }

  getTotalProductQuantityInCart = (
    product: ProductModel,
    vehicle: Vehicle
  ): number => {
    const match = this.findProductMatch(product, vehicle)
    let total = 0
    if (!match) {
      return total
    }
    /* This array refers to a list of availability errors. We don't show products having any of these errors in 
    the cart table. We won't count such products for arriving at cart cost, cart products count.
     */
    const excludedProducts = [
      AvailabilityErrorType.NOT_TRANSFERRED,
      AvailabilityErrorType.CANT_BE_FULFILLED,
      AvailabilityErrorType.CANT_BE_FULFILLED_FROM_SELECTED_LOCATION,
      AvailabilityErrorType.MIN_QTY_CHANGE,
    ]

    const productHasAvailabilityError = (match.availabilityErrors || []).find(
      (error) => excludedProducts.includes(error.errorType)
    )

    if (productHasAvailabilityError) {
      return 0
    }
    match.orderSelections.forEach((os) => {
      total += os.quantityRequested
    })
    return total
  }

  getTotalQuantityAvailableOnLocations = (product: ProductModel): number => {
    const locations = product?.location || []
    return locations.reduce((acc, location) => {
      const qtyAvailable = location?.qtyAvailable
      const total = acc + qtyAvailable
      return total
    }, 0)
  }

  getDisableStore = (qtyAvailable: number): boolean => {
    return qtyAvailable < this.quantitySelected
  }

  setDisableStores = (value: boolean): void => {
    this.disableStores = value
  }

  setQuantitySelected = (value: number): void => {
    this.quantitySelected = value
  }

  public setVehicleSpecification = (
    vehicle: Vehicle,
    specifications: Array<VehicleSpecificationCartItem>
  ): void => {
    let vehicleIdx = this.getCurrentVehicleIndex(vehicle)

    if (vehicleIdx < 0) {
      this.addVehicleToCart(vehicle)
      vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    }

    this.data.vehicles[vehicleIdx] = {
      ...this.data.vehicles[vehicleIdx],
      specifications,
    }

    this.save()
  }

  public getVehicleSpecification = (
    vehicle: Vehicle
  ): Array<VehicleSpecificationCartItem> => {
    return this.data.vehicles[this.getCurrentVehicleIndex(vehicle)]
      ?.specifications
  }

  public setLaborItems = (
    vehicle: Vehicle,
    selectedLaborsResults: LaborItem[]
  ): void => {
    let vehicleIdx = this.getCurrentVehicleIndex(vehicle)

    if (vehicleIdx < 0) {
      this.addVehicleToCart(vehicle)
      vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    }

    this.data.vehicles[vehicleIdx] = {
      ...this.data.vehicles[vehicleIdx],
      laborResults: selectedLaborsResults,
    }

    this.save()
  }

  public getLaborItems = (vehicle: Vehicle): LaborItem[] => {
    const vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    return this.data?.vehicles?.[vehicleIdx]?.laborResults
  }

  public areAllCartsEmpty = (): boolean => {
    return this.data.vehicles?.find((v) => !this.isCartEmpty(v)) === undefined
  }

  public isCartEmpty = (vehicle: CartVehicle): boolean => {
    return (
      vehicle.products?.length === 0 &&
      vehicle.specifications?.length === 0 &&
      vehicle.laborResults?.length === 0
    )
  }

  public getCountOfOrderAbleCarts = (): number => {
    const orderAbleCarts = this.data.vehicles.filter(this.isCartOrderAble)
    return orderAbleCarts?.length ?? 0
  }

  public isCartOrderAble = (vehicle: CartVehicle): boolean => {
    return this.vehicleProducts(vehicle.vehicle)?.length > 0
  }

  public vehicleProducts = (vehicle: Vehicle): Array<ShoppingCartProduct> => {
    const vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    const vehicleCart = this.data.vehicles?.[vehicleIdx]

    return vehicleCart?.products
      ?.filter(
        (p) =>
          !p.availabilityErrors?.find(
            (e) => e.errorType === AvailabilityErrorType.NOT_TRANSFERRED
          )
      )
      ?.filter(
        (p) =>
          !p.availabilityErrors?.find(
            (e) => e.errorType === AvailabilityErrorType.CANT_BE_FULFILLED
          )
      )
      ?.filter(
        (p) =>
          !p.availabilityErrors?.find(
            (e) =>
              e.errorType ===
              AvailabilityErrorType.CANT_BE_FULFILLED_FROM_SELECTED_LOCATION
          )
      )
  }

  public getVehicleFormData = (vehicle: Vehicle): OrderFormData => {
    const vehicleCart = this.findCartVehicle(vehicle)

    return vehicleCart.orderFormData
  }

  public removeProductFromVehicle = (
    vehicle: Vehicle,
    product: ShoppingCartProduct
  ): void => {
    const cartVehicle = this.findCartVehicle(vehicle)
    const productIndex = this.findProductIndex(cartVehicle, product)
    cartVehicle.products.splice(productIndex, 1)
    if (this.isCartEmpty(cartVehicle)) {
      this.removeVehicleCart(vehicle, GaTrackOption.track)
    }

    this.trackRemoveFromCart(product, vehicle)
  }

  public trackAddToCart = (
    product: ProductModel,
    vehicle: Vehicle,
    location: ProductLocationModel,
    quantity: number
  ): void => {
    const { countryCode } = StoreInstances.userStore.country
    const regionId = Region[countryCode]
    const category = getGoogleAnalyticsCategory(
      product?.allianceterminologyId ?? '',
      product?.description ?? ''
    )

    GoogleTagManager.setVehicleDimensions(vehicle, regionId)
    GoogleTagManager.trackEvent('add_to_cart', {
      items: [
        {
          id: product.allianceProductId, // aka SKU
          name: GoogleTagManager.buildProductName(product),
          brand: product.brandName ?? product.manufacturerName,
          category,
          price: location.cost,
          quantity,
        },
      ],
    })
  }

  public trackRemoveFromCart = (
    product: ShoppingCartProduct,
    vehicle: Vehicle
  ): void => {
    const { countryCode } = StoreInstances.userStore.country
    const regionId = Region[countryCode]
    const category = getGoogleAnalyticsCategory(
      product?.allianceterminologyId ?? '',
      product?.description ?? ''
    )
    GoogleTagManager.setVehicleDimensions(vehicle, regionId)
    GoogleTagManager.trackEvent('remove_from_cart', {
      items: [
        {
          id: product.allianceProductId, // aka SKU
          name: GoogleTagManager.buildProductName(product),
          brand: product.brandName ?? product.manufacturerName,
          category,
          price: product.location?.[0]?.cost,
          quantity: product.perCarQty,
        },
      ],
    })
  }

  public findProductIndex = (
    cartVehicle: CartVehicle,
    product: ShoppingCartProduct
  ): number => {
    return cartVehicle.products.findIndex((p) => productsMatch(p, product))
  }

  private validateLocation = (p, locationId): ShoppingCartProduct => {
    const defaultError: AvailabilityError = {
      errorType: AvailabilityErrorType.ORDER_FROM_NON_PRIMARY_LOCATION,
      fieldUpdates: [],
    }

    const validatedProduct: ShoppingCartProduct = { ...p }
    if (!validatedProduct.availabilityErrors)
      validatedProduct.availabilityErrors = []

    if (Number(locationId) !== 100) {
      validatedProduct.availabilityErrors.push(defaultError)
    } else {
      // Clear any existing error if the location is changed back to primary.
      validatedProduct.availabilityErrors =
        validatedProduct.availabilityErrors.filter(
          (error) =>
            error.errorType !==
            AvailabilityErrorType.ORDER_FROM_NON_PRIMARY_LOCATION
        )
    }

    return validatedProduct
  }

  public getProductAvailabilityErrors = (
    vehicle: Vehicle
  ): Array<ShoppingCartProduct> => {
    const cartVehicle = this.findCartVehicle(vehicle)
    return cartVehicle?.products?.filter((p) => p.availabilityErrors?.length)
  }

  get totalCartQty(): number {
    return this.cartAccumulator((_p, os) => {
      return os.quantityRequested
    })
  }

  get cartCostSubTotal(): number {
    return this.cartAccumulator((p, os) => {
      const loc = getLocationById(p, os.locationId)

      const yourCostAfterPriceBreaks = () => {
        if (loc?.priceBreaks?.length > 0) {
          const lastPriceBreak = loc?.priceBreaks[loc?.priceBreaks?.length - 1]
          for (const priceBreak of loc?.priceBreaks || []) {
            if (
              priceBreak.minQty <= os.quantityRequested &&
              priceBreak.maxQty >= os.quantityRequested
            ) {
              return priceBreak.unitCost
            }
            if (
              lastPriceBreak?.maxQty &&
              os?.quantityRequested > lastPriceBreak?.maxQty
            ) {
              return lastPriceBreak?.unitCost
            }
          }
        }
        return loc?.cost
      }
      return os.quantityRequested * yourCostAfterPriceBreaks()
    })
  }

  get cartLaborCostSubtotal(): number {
    let laborCostSubtotal = 0
    this.data.vehicles.forEach((cartVehicle) => {
      if (cartVehicle.laborResults) {
        laborCostSubtotal +=
          StoreInstances.laborStore.calculateLaborCostFromItems(
            cartVehicle.laborResults
          )
      }
    })
    return laborCostSubtotal
  }

  public cartVehicleCostSubTotal(vehicle: Vehicle): number {
    return this.cartAccumulatorVehicle((p, os) => {
      const loc = getLocationById(p, os.locationId)

      const yourCostAfterPriceBreaks = () => {
        if (loc?.priceBreaks?.length > 0) {
          const lastPriceBreak = loc?.priceBreaks[loc?.priceBreaks?.length - 1]
          for (const priceBreak of loc?.priceBreaks || []) {
            if (
              priceBreak.minQty <= os.quantityRequested &&
              priceBreak.maxQty >= os.quantityRequested
            ) {
              return priceBreak.unitCost
            }
            if (
              lastPriceBreak?.maxQty &&
              os?.quantityRequested > lastPriceBreak?.maxQty
            ) {
              return lastPriceBreak?.unitCost
            }
          }
        }
        return loc?.cost
      }
      return os.quantityRequested * yourCostAfterPriceBreaks()
    }, vehicle)
  }

  public cartCostTotal = (currentVehicle: Vehicle): number => {
    return (
      this.cartVehicleCostSubTotal(currentVehicle) +
      this.cartVehicleCostCoreTotal(currentVehicle) +
      this.cartLaborTotal(currentVehicle)
    )
  }

  public cartLaborTotal = (currentVehicle: Vehicle): number => {
    const vehicleIndex = this.getCurrentVehicleIndex(currentVehicle)
    const laborResults = this.data.vehicles?.[vehicleIndex]?.laborResults
    return laborResults
      ? StoreInstances.laborStore.calculateLaborCostFromItems(laborResults)
      : 0
  }

  public cartShipmentTotal = (currentVehicle: Vehicle): number => {
    const vehicleIndex = this.getCurrentVehicleIndex(currentVehicle)
    let shipmentTotal = 0
    ;(this.data.vehicles?.[vehicleIndex]?.locations || []).forEach(
      (location) => {
        if (
          location.transportId === PUROLATOR &&
          !isNaN(location.carrierService?.totalPrice)
        ) {
          shipmentTotal += location.carrierService?.totalPrice
        }
      }
    )
    return shipmentTotal
  }

  get allCartsTotalShipmentCost(): number {
    let grandShipmentCost = 0
    this.vehicles.forEach(
      (vehicle) =>
        (grandShipmentCost += this.cartShipmentTotal(vehicle.vehicle))
    )
    return grandShipmentCost
  }

  get cartCostCoreTotal(): number {
    return this.cartAccumulator((p, os) => {
      const loc = getLocationById(p, os.locationId)
      return os.quantityRequested * loc.coreCost
    })
  }

  public cartVehicleCostCoreTotal(vehicle: Vehicle): number {
    return this.cartAccumulatorVehicle((p, os) => {
      const loc = getLocationById(p, os.locationId)
      return os.quantityRequested * loc.coreCost
    }, vehicle)
  }

  get cartListSubTotal(): number {
    return this.cartAccumulator((p, os) => {
      const loc = getLocationById(p, os.locationId)
      return os.quantityRequested * loc.list
    })
  }

  get cartListCoreTotal(): number {
    return this.cartAccumulator((p, os) => {
      const loc = getLocationById(p, os.locationId)
      return os.quantityRequested * loc.coreList
    })
  }

  private cartAccumulator = (
    orderSelectionFunc: (prod, orderSelection) => number
  ): number => {
    let sum = 0
    this.data.vehicles?.forEach((v) => {
      this.vehicleProducts(v.vehicle)?.forEach((p) => {
        p.orderSelections?.forEach((os) => {
          sum += orderSelectionFunc(p, os)
        })
      })
    })
    return sum
  }

  private cartAccumulatorVehicle = (
    orderSelectionFunc: (prod, orderSelection) => number,
    vehicle: Vehicle
  ): number => {
    let sum = 0
    this.vehicleProducts(vehicle)?.forEach((p) => {
      p.orderSelections?.forEach((os) => {
        sum += orderSelectionFunc(p, os)
      })
    })
    return sum
  }

  public findProductMatch = (
    product: ProductModel,
    vehicle: Vehicle
  ): Optional<ShoppingCartProduct> => {
    return this.data.vehicles?.[
      this.getCurrentVehicleIndex(vehicle)
    ]?.products.find((p2) => productsMatch(product, p2))
  }

  public findNumberOfEntries = (coverage: string): number => {
    let counterEntries = 0
    this.data.vehicles?.forEach((vehicle) => {
      vehicle?.products?.forEach((product) => {
        const matchCoverage = coverage
          ?.split(',')
          .includes(String(product?.orderNumber))
        if (matchCoverage) {
          product.orderSelections.forEach((orderSelection) => {
            const quantityRequested = orderSelection.quantityRequested
            counterEntries = counterEntries + quantityRequested
          })
        }
      })
    })
    return counterEntries
  }

  getCurrentVehicleIndex = (vehicle: Vehicle): number => {
    const index = this.data.vehicles.findIndex(
      (item) =>
        item?.vehicle?.year?.id === vehicle?.year?.id &&
        item?.vehicle?.make?.id === vehicle?.make?.id &&
        item?.vehicle?.model?.id === vehicle?.model?.id &&
        item?.vehicle?.engine?.id === vehicle?.engine?.id &&
        // TODO: check if comparing ID is enough.
        item?.vehicle?.id === vehicle?.id
    )
    return index
  }

  findFirstSimilarVehicleIndex = (vehicle: Vehicle): number => {
    const index = this.data.vehicles.findIndex(
      (item) =>
        item?.vehicle?.year?.id === vehicle?.year?.id &&
        item?.vehicle?.make?.id === vehicle?.make?.id &&
        item?.vehicle?.model?.id === vehicle?.model?.id &&
        item?.vehicle?.engine?.id === vehicle?.engine?.id
    )
    return index
  }

  findCartVehicle = (vehicle: Vehicle): CartVehicle => {
    const vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    if (vehicleIdx >= 0) return this.data.vehicles[vehicleIdx]
    return undefined
  }

  findFirstSimilarCartVehicle = (vehicle: Vehicle): CartVehicle => {
    const vehicleIdx = this.findFirstSimilarVehicleIndex(vehicle)
    return vehicleIdx > -1 ? this.data.vehicles[vehicleIdx] : undefined
  }

  findCartVehicleById = (vehicleId: string): CartVehicle => {
    return this.data.vehicles.find((v) => v.vehicle?.id === vehicleId)
  }

  private findOrAddCartProduct = (
    product: ProductModel,
    locationId: string,
    vehicle: Vehicle
  ): ShoppingCartProduct => {
    const match = this.findProductMatch(product, vehicle)

    if (match) {
      return match
    }

    const cartProduct: ShoppingCartProduct = {
      ...product,
      activeLocationId: locationId,
      orderSelections: [],
    }
    this.data.vehicles[this.getCurrentVehicleIndex(vehicle)].products.push(
      cartProduct
    )
    return this.findProductMatch(cartProduct, vehicle)
  }

  public setVehicle = (vehicle: Vehicle): void => {
    this.data.vehicle = vehicle
  }

  public removeAllVehicles = (): void => {
    this.data.vehicles.forEach((v) =>
      v.products.forEach((p) => this.trackRemoveFromCart(p, v.vehicle))
    )
    this.data.vehicles = []
    this.cartValidations = {}
  }

  get vehicles(): Array<CartVehicle> {
    return this.data.vehicles
  }

  get vehicle(): Vehicle {
    if (!this.data.vehicle) {
      return {
        year: { id: undefined, value: '' },
        make: { id: undefined, value: '' },
        model: { id: undefined, value: '' },
        engine: { id: undefined, value: '' },
      }
    }
    return this.data.vehicle
  }

  public getDataForTransfer = (): ShoppingCartData => {
    return this.data
  }

  public save = (): void => {
    localStorage.setItem(getCartStorageKey(), JSON.stringify(this.data))
  }

  public restore = (): void => {
    const savedData = localStorage.getItem(getCartStorageKey())
    if (!savedData) {
      this.data = { version: Config.cartVersion, vehicles: [] }
      return
    }

    const parsedData = JSON.parse(savedData)
    // For now, only restore if the versions match, to avoid any errors related to schema changes
    if (parsedData.version === this.data.version) {
      this.data = {
        ...parsedData,
        vehicles: (parsedData as ShoppingCartData).vehicles.map((vehicle) => ({
          ...vehicle,
          locations: vehicle.locations ? vehicle.locations : [],
        })),
      }
    }
    this.cleanEmptyCarts()
    this.updateShipmentEstimates()
  }

  /**
   * Populate the cart with data from an incoming PartsBasket.  Currently only used for 'cart only' mode.
   * @param data
   */
  public fromEmbedded = (data: ShoppingCartOnlyData): void => {
    this.data = data
  }

  public setCheckAvailabilityLoaded = (loaded: boolean): void => {
    this.checkAvailabilityLoaded = loaded
  }

  /**
   * Vehicles match if the id, year, make, model, and engine all match
   */
  private vehiclesMatch = (v1: Vehicle, v2: Vehicle): boolean => {
    return (
      v1?.year?.id === v2?.year?.id &&
      v1?.make?.id === v2?.make?.id &&
      v1?.model?.id === v2?.model?.id &&
      v1?.engine?.id === v2?.engine?.id &&
      v1?.id === v2?.id
    )
  }

  public initReactions = (): void => {
    /** Save to local storage any time a value in the shopping cart data changes */
    reaction(
      () => StoreInstances.userStore.userHasLoaded,
      async () => {
        if (
          StoreInstances.userStore.userHasLoaded === true &&
          Config.isNotCartOnlyMode
        ) {
          await Promise.all([
            this.fetchTransports(),
            this.fetchShipToAddresses(),
          ])
          this.restore() // Load from local storage
        }
      }
    )

    /** Save to local storage any time a value in the shopping cart data changes */
    reaction(
      () => StoreInstances.cart.totalCartQty,
      () => {
        StoreInstances.cart.save()
      }
    )

    /** Save to local storage any time a vehicle is added or removed */
    reaction(
      () => StoreInstances.cart.data.vehicles.length,
      () => {
        StoreInstances.cart.save()
      }
    )
  }

  public setCartPartDetails = (
    shoppingCartProduct: ShoppingCartProduct,
    yourCost?: number,
    selectedLocation?: ProductLocationModel,
    total?: number
  ): void => {
    this.cartPartDetails = {
      shoppingCartProduct,
      yourCost,
      selectedLocation,
      total,
    }
  }

  public getCartProductSelectedLocation = (
    product: ProductModel,
    cartVehicleSelection?: Vehicle
  ): ProductLocationModel => {
    const selectedCartVehicle = StoreInstances.searchStore.currentVehicle
      ?.engine
      ? StoreInstances.searchStore.currentVehicle
      : miscellaneousVehicle

    const vehicleIdx = this.getCurrentVehicleIndex(
      cartVehicleSelection ? cartVehicleSelection : selectedCartVehicle
    )
    const products = this.data.vehicles?.[vehicleIdx].products

    const commonPartOrderSelections = products.find((item) =>
      productsMatch(item, product)
    )?.orderSelections

    const selectedLocation = getLocationById(
      product,
      commonPartOrderSelections?.[0]?.locationId
    )
    return selectedLocation
  }

  public getAvailableTransports = (
    locationId: string,
    orderType: string
  ): Transport[] => {
    const matchingTransport = (this.availableTransports || []).find(
      (lt) => Number(lt.locationId) === Number(locationId)
    )
    if (!matchingTransport) {
      return []
    }
    return matchingTransport.transports.filter(
      (t) => t.deliveryMethod === orderType
    )
  }

  public getAvailableOrderTypes = (locationId: string): string[] => {
    const orderTypes = this.availableTransports
      ?.find((lt) => Number(lt.locationId) === Number(locationId))
      ?.transports.map((transport) => transport.deliveryMethod)
    //returning unique orderTypes
    return Array.from(new Set(orderTypes))
  }

  public getAllLocationsFromTheCart = (
    vehicle: CartVehicle
  ): ProductLocationModel[] => {
    const allLocations: ProductLocationModel[] = []
    vehicle.products?.forEach((p) =>
      p.location?.forEach((l) => {
        if (
          l.locationId.toString() ===
          p.orderSelections?.[0]?.locationId.toString()
        )
          allLocations.push(l)
      })
    )
    const uniqueLocations = allLocations.filter(
      (l, index, arr) =>
        arr.findIndex(
          (l2) => l2.locationId.toString() === l.locationId.toString()
        ) === index
    )
    return uniqueLocations
  }

  // public hasPartsFromBuyDirectLocations = (vehicle: CartVehicle): boolean => {
  //   const uniqueLocations = this.getAllLocationsFromTheCart(vehicle)
  //   return uniqueLocations.findIndex(isBuyDirectLocation) > -1
  // }

  public getPurolatorEligibility = async (
    locationIds: number[]
  ): Promise<void> => {
    const locationPurolatorEligibility =
      (await ShipmentServiceProvider.fetchPurolatorEligibility(locationIds)) ||
      []
    this.purolatorEligibileLocations = locationPurolatorEligibility
      .filter((location) => location.eligible === true)
      .map((location) => location.sellnwLocation)
  }

  public checkPurolatorEligibilityOfCartVehicle = (
    locationIds: number[]
  ): boolean => {
    return (
      this.purolatorEligibileLocations?.find((location) =>
        locationIds.includes(location)
      ) !== undefined
    )
  }

  public checkPurolatorEligibilityOfLocation = (
    locationId: number
  ): boolean => {
    return this.purolatorEligibileLocations?.includes(Number(locationId))
  }

  public hashTransport = (transport: Transport): string => {
    const seqNo = transport.seqNo?.toString()?.replace(/\s/g, '')?.toLowerCase()
    const carrier = transport.carrier?.replace(/\s/g, '')?.toLowerCase()
    const shipCode = transport.shipCode?.replace(/\s/g, '')?.toLowerCase()
    const releaseCode = transport.releaseCode?.replace(/\s/g, '')?.toLowerCase()
    return `${carrier}_${shipCode}_${releaseCode}_${seqNo}`
  }

  public updateShipmentEstimates(): Promise<unknown> {
    return Promise.all(
      this.data.vehicles.map((vehicle) =>
        this.updateShipmentEstimatesForCart(vehicle)
      )
    )
  }

  getPuralatorEstimateForCartLocations(
    cartVehicle: CartVehicle,
    eligibleLocations: ProductLocationModel[]
  ): IEstimateReqData[] {
    return eligibleLocations.map((location) => {
      const { locationId } = location
      const { products } = cartVehicle
      const parts = products.filter(
        (product) =>
          product.orderSelections.find(
            (orderSelection) => orderSelection.locationId === locationId
          ) !== undefined
      )

      const estimatePartData = parts.map((part) => {
        const { partNumber, lineCode, height, weight, width } = part
        const { quantityRequested } = part.orderSelections.find(
          (orderSelection) => orderSelection.locationId === locationId
        )
        return {
          partNumber,
          linecode: lineCode,
          reqQty: quantityRequested,
          height,
          weight,
          length: 0,
          width,
        }
      })
      return {
        sellnwLocation: Number(locationId),
        estimatePartData,
      }
    })
  }

  public updateShipmentEstimatesForCart(
    cartVehicle: CartVehicle
  ): Promise<boolean> {
    if (!cartVehicle.purolator) {
      cartVehicle.purolator = {
        loadingEstimates: true,
      }
    }
    cartVehicle.purolator.loadingEstimates = true
    // Check if Puralator is selected for any location in this cart
    const uniqueLocation = this.getAllLocationsFromTheCart(cartVehicle)
    const puralatorAddedLocations: ProductLocationModel[] =
      cartVehicle.locations
        .filter((location) => location.transportId === PUROLATOR)
        .map((location) => location.locationId)
        .map((locationId) =>
          uniqueLocation.find(
            (location) =>
              location.locationId.toString() === locationId.toString()
          )
        )
        .filter((productLocation) => productLocation !== undefined)

    if (puralatorAddedLocations.length === 0) {
      cartVehicle.purolator.loadingEstimates = false
      return Promise.resolve(false)
    }
    // Get the list of parts from those locations.
    // Call getEstimates with the parts info
    const estimateRequest = this.getPuralatorEstimateForCartLocations(
      cartVehicle,
      puralatorAddedLocations
    )

    return ShipmentServiceProvider.fetchPurolatorEstimates(estimateRequest)
      .then((purolatorEstimates) => {
        // Update the locations with new estimates.
        purolatorEstimates.estimateResponseData.forEach((estimate) => {
          const cartLocation = (cartVehicle.locations || []).find(
            (location) =>
              location.locationId.toString() ===
              estimate.sellnwLocation.toString()
          )
          cartLocation.carrierService = estimate.shipmentEstimates.find(
            (shipmentEstimate) =>
              shipmentEstimate.serviceId ===
              cartLocation.carrierService.serviceId
          )
        })
        return true
      })
      .catch(() => {
        return false
      })
      .finally(() => {
        cartVehicle.purolator.loadingEstimates = false
      })
  }

  /*
    Returns true if for atleast one cart we are fetching purolator estimates. i.e., API call is in progress state
  */
  public getStatusOfPurolatorEstimates = (): boolean => {
    return (
      this.vehicles.find(
        (vehicle) => vehicle.purolator.loadingEstimates === true
      ) !== undefined
    )
  }

  public getProductsFromLocation = (
    vehicle: Vehicle,
    locationId: string
  ): ShoppingCartProduct[] => {
    const allValidProducts: ShoppingCartProduct[] =
      this.vehicleProducts(vehicle)
    const productsFromLocation = allValidProducts.filter(
      (product) => findOrderSelection(product, locationId) !== undefined
    )
    return productsFromLocation
  }

  public removeAnOrderLocation = (
    vehicle: Vehicle,
    locationId: string
  ): void => {
    const cartVehicle = this.findCartVehicle(vehicle)
    const locationIndex = cartVehicle.locations.findIndex(
      (location) => location.locationId === locationId
    )
    cartVehicle.locations.splice(locationIndex, 1)
  }

  /**
   * This below code block gets the ship to address shown in the cart page.
   */
  public fetchShipToAddresses = (): void => {
    UserDataServiceProvider.getShipToAddresses(
      StoreInstances.userStore?.preferences.orgId
    ).then((resp) => {
      this.addresses = (resp || []).map((address) => {
        return {
          id: address.postalCode.toString(), //If postal code can't be a unique key, then generate a unique key by combining multiple fields.
          ...address,
        }
      })
    })
  }

  public getAddresses = (): Address[] => {
    return this.addresses
  }

  public getAddressesByID = (ID: string): Address => {
    return this.addresses.find((address) => address.id === ID)
  }

  public transferAllCarts = (
    token: string
  ): Promise<TransferPartsDataResponse> => {
    const shoppingCartProducts: ShoppingCartProduct[] = []

    this.data.vehicles.forEach((v) => {
      return v.products.forEach((p) => shoppingCartProducts.push(p))
    })

    const transferParts: TransferPart[] = shoppingCartProducts.map((p) => {
      const selectedLocation = p.location.find(
        (loc) => loc.locationId === p.activeLocationId
      )

      return {
        unitPrice: selectedLocation.cost.toString(),
        manufacturerPartId: '',
        selectedLoc: selectedLocation.called,
        classificationDomain: 'UNSPSC',
        unitCore: selectedLocation.coreCost.toString(),
        categoryCodeIdentifier: 'SUPPLIER',
        manufacturerName: p.manufacturerName,
        lineCode: p.lineCode,
        uom:
          selectedLocation.originalUom === ''
            ? 'EA'
            : selectedLocation.originalUom,
        packNumUnits: selectedLocation.packNumUnits.toString(),
        partDescription: p.description,
        lineType: 'GOODS',
        qty: p.orderSelections[0]?.quantityRequested.toString(),
        partNumber: p.partNumber,
        currency:
          StoreInstances.userStore.country.countryId === 1 ? 'CAD' : 'USD',
      }
    })

    const transferPartsDataResponse: Promise<TransferPartsDataResponse> =
      OsnServiceProvider.transferCartParts({ partsdata: transferParts }, token)

    return transferPartsDataResponse
  }
}
