import { Add, Delete, Edit } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Grow } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { Overlay } from 'react-bootstrap'
import { useClickOutsideDetector } from 'src/hooks'
import { I18n } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import ListNode from 'src/store/lists/ListNode'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'
import { breakpoints } from 'src/theme/breakpoints'
import { zIndex } from 'src/theme/zIndex'

interface CategoryOptionsProps {
  show: boolean
  parentRef: React.RefObject<HTMLElement>
  setShow: (show: boolean) => void
  category: ListNode
  disableActionIcon: boolean
}

export const CategoryOptions: React.FC<CategoryOptionsProps> = observer(
  ({ show, parentRef, setShow, category, disableActionIcon }) => {
    const overlayRef = useRef(null)
    const renameRef = useRef(null)
    const deleteRef = useRef(null)
    const addListRef = useRef(null)
    const listsStore = useListsStore()
    const {
      setCategoryToRename,
      setCategoryNameModal,
      setCategoryNameModalType,
      setCategoryNameField,
      setDeleteCategoryModal,
      deleteCategoryModal,
      setDeleteSelectedCategory,
      setSelectedCategory,
      nameListModal,
      setNameListModal,
      setListNameModalType,
      setNameSelectedList,
    } = listsStore

    useClickOutsideDetector(
      [overlayRef, parentRef, renameRef, deleteRef, addListRef],
      show,
      () => setShow(false)
    )

    const handleRenameCategory = () => {
      if (!disableActionIcon) {
        setCategoryNameModal(true)
        setCategoryNameModalType('rename')
        setCategoryToRename({
          id: category.id.toString(),
          value: category.value,
        })
        setCategoryNameField(category.value)
      }
    }

    const handleDeleteCategory = () => {
      if (!disableActionIcon) {
        setDeleteCategoryModal(!deleteCategoryModal)
        setDeleteSelectedCategory({
          id: category.id,
          label: category.value,
        })
      }
    }

    const handleNewList = () => {
      if (!disableActionIcon) {
        setNameListModal(!nameListModal)
        setSelectedCategory(category)
        setNameSelectedList({
          category: category.value,
        })
        setListNameModalType('new')
      }
    }

    return (
      <>
        <Overlay
          show={show}
          placement="bottom-end"
          popperConfig={{ strategy: 'absolute' }}
          target={parentRef.current}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars -- Bulk disabling. Fix if possible. */}
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <OuterWrapper
              {...props}
              style={{
                ...props.style,
              }}
            >
              <Box>
                <Grow in={show}>
                  <Wrapper
                    ref={overlayRef}
                    disableActionIcon={disableActionIcon}
                  >
                    <SelectItem
                      ref={renameRef}
                      disableActionIcon={disableActionIcon}
                      onClick={handleRenameCategory}
                    >
                      <StyledEdit disableActionIcon={disableActionIcon} />
                      <SelectItemText disableActionIcon={disableActionIcon}>
                        <I18n name="rename" />
                      </SelectItemText>
                    </SelectItem>
                    <SelectItem
                      ref={deleteRef}
                      disableActionIcon={disableActionIcon}
                      onClick={handleDeleteCategory}
                    >
                      <StyledDelete disableActionIcon={disableActionIcon} />
                      <SelectItemText disableActionIcon={disableActionIcon}>
                        <I18n name="delete" />
                      </SelectItemText>
                    </SelectItem>
                    <SelectItem
                      ref={addListRef}
                      disableActionIcon={disableActionIcon}
                      style={{
                        borderTop: `1px solid ${defaultTheme.colors.greyBorder}`,
                        height: 50,
                      }}
                      onClick={handleNewList}
                    >
                      <StyledAdd disableActionIcon={disableActionIcon} />
                      <SelectItemText disableActionIcon={disableActionIcon}>
                        <I18n name="newList" />
                      </SelectItemText>
                    </SelectItem>
                  </Wrapper>
                </Grow>
              </Box>
            </OuterWrapper>
          )}
        </Overlay>
        <ResponsiveDrawer
          anchor="bottom"
          open={show}
          onClose={() => setShow(false)}
          PaperProps={{
            square: false,
          }}
          style={{
            width: '800px',
          }}
        >
          <Container ref={overlayRef}>
            <HeaderContainer>
              <I18n name={category.value} />

              <StyledCloseIcon onClick={() => setShow(false)} />
            </HeaderContainer>
            <ItemsContainer>
              <SelectItem
                disableActionIcon={disableActionIcon}
                onClick={handleRenameCategory}
              >
                <StyledEdit disableActionIcon={disableActionIcon} />
                <SelectItemText disableActionIcon={disableActionIcon}>
                  <I18n name="renameCategory" />
                </SelectItemText>
              </SelectItem>
              <SelectItem
                disableActionIcon={disableActionIcon}
                onClick={handleDeleteCategory}
              >
                <StyledDelete disableActionIcon={disableActionIcon} />
                <SelectItemText disableActionIcon={disableActionIcon}>
                  <I18n name="deleteCategory" />
                </SelectItemText>
              </SelectItem>
            </ItemsContainer>
            <ItemsContainer
              style={{
                borderTop: `1px solid ${defaultTheme.colors.greyBorder}`,
              }}
            >
              <SelectItem
                disableActionIcon={disableActionIcon}
                onClick={handleNewList}
              >
                <StyledAdd disableActionIcon={disableActionIcon} />
                <SelectItemText disableActionIcon={disableActionIcon}>
                  <I18n name="newList" />
                </SelectItemText>
              </SelectItem>
            </ItemsContainer>
          </Container>
        </ResponsiveDrawer>
      </>
    )
  }
)

interface DisableListTabProps {
  disableActionIcon: boolean
}

const OuterWrapper = styled.div`
  display: inline-block;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const Wrapper = styled.div<DisableListTabProps>`
  position: absolute;
  width: 150px;
  right: 0;
  border: 1px solid ${defaultTheme.colors.border};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: ${defaultTheme.colors.componentsBackground};
  display: flex;
  flex-direction: column;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `
    &:hover {
    cursor: not-allowed;
  } `}
`
const SelectItem = styled.div<DisableListTabProps>`
  width: 100%;
  height: 45px;
  cursor: pointer;
  &:hover {
    background: ${defaultTheme.colors.rowFeaturedBackground};
  }
  display: flex;
  align-items: center;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
    &:hover {
    color: ${defaultTheme.colors.greyMedium};
    background: ${defaultTheme.colors.white};
    cursor: not-allowed;
  } `}
`

const SelectItemText = styled.span<DisableListTabProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Black */

  color: ${defaultTheme.colors.primaryText};
  margin-left: 7px;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
    &:hover {
      color: ${defaultTheme.colors.greyMedium};
      cursor: not-allowed;
  } `}
`

const StyledDelete = styled(Delete)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  margin-left: 7px;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
     cursor: not-allowed;
    &:hover {
      color: ${defaultTheme.colors.greyMedium};
    cursor: not-allowed;
  } `}
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin: 0px;
  }
`
const StyledAdd = styled(Add)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  margin-left: 7px;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
     cursor: not-allowed;
    &:hover {
      color: ${defaultTheme.colors.greyMedium};
  } `}
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin: 0px;
  }
`
const StyledEdit = styled(Edit)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  margin-left: 7px;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
     cursor: not-allowed;
    &:hover {
      color: ${defaultTheme.colors.greyMedium};
  } `}
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin: 0px;
  }
`

const ResponsiveDrawer = styled(Drawer)`
  @media screen and (min-width: ${breakpoints.laptop}px) {
    display: none;
  }
`

interface ContainerProps {
  height?: string
  width?: string
}

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  /* padding: 24px; */
  overflow-y: auto;
  border-radius: 8px 8px 0px 0px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0px;
    width: 500px;
    min-height: 228px;
    padding: 24px 32px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: ${defaultTheme.colors.primaryText};
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px 24px 24px;
    border-bottom: 1px solid ${defaultTheme.colors.greyBorder};
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-top: 5.5px;
`

const ItemsContainer = styled.div`
  margin: 24px;
`
