import { chunk, flatten } from 'lodash-es'
import { ValidateListPart, ValidatePartsRequest } from './interfaces'
import ListServiceProvider from 'src/services/ListServiceProvider'

export const getValidatedPartsList = async ({
  partIds,
}: ValidatePartsRequest): Promise<ValidateListPart[]> => {
  // sending chunks of partIds for validation, as validate end point handles not more than 30 items per request
  const chunks = chunk(partIds, 20)
  return Promise.all(
    chunks.map((partsChunk) =>
      ListServiceProvider.validateParts({
        partIds: partsChunk,
      })
    )
  ).then((updatedPartChunks) =>
    flatten<ValidateListPart>(
      updatedPartChunks.map((partChunk) => partChunk.parts || [])
    )
  )
}
