import { LinearProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import styled from 'styled-components'
import { defaultTheme } from 'src/theme'
import { Checkbox } from 'src/ui-components/forms/Checkbox'
import { useListsStore } from 'src/store/lists/ListsStore'
import { ListGroupPartRow } from './ListGroupPartRow'
import { BottomDrawer } from './BottomDrawer'
import { CartEditItemDrawer } from './CartEditItemDrawer'
import { MoveGroupPartsDrawer } from './MoveGroupPartsDrawer'
import { MovePartsSelectionDrawer } from './MovePartsSelectionDrawer'
import { breakpoints } from 'src/theme/breakpoints'
import PartExtServiceProvider from 'src/services/PartExtServiceProvider'
import { ListSortDropdown } from './ListSortDropdown'
import { KeyboardArrowUp } from '@mui/icons-material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import CloseIcon from '@mui/icons-material/Close'
import { GroupPart } from 'src/store/lists/interfaces'
import Pager from 'src/ui-components/table/pagination/'
import EmptyGroupScreen from '../EmptyGroupScreen'

const listModulePages = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '50',
    label: '50',
  },
  {
    value: '100',
    label: '100',
  },
]

export const ListGroupPartsTable = observer((): React.ReactElement => {
  const {
    groupPartsLoading,
    selectedGroupParts,
    selectedPartsList,
    selectedGroupPartsCount,
    selectingAllGroupParts,
    setShowBottomDrawer,
    setSelectedItems,
    setShowSelectedItemsList,
    getListGroupParts,
    handlePaginationLimit,
    setThumbnails,
    getThumbnailUrl,
    selectedCategory,
    loadingCategories,
    onCategoryDeleted,
    onListDeleted,
    itemsPerPage,
  } = useListsStore()

  const [checkBoxSelected, setCheckBoxSelected] = useState(false)
  const [isManuallyDeselected, setIsManuallyDeselected] = useState(false)
  const sortButtonDesktopRef = useRef<HTMLDivElement>(null)
  const [showSortBoxDesktop, setShowSortBoxDesktop] = useState(false)

  useEffect(() => {
    if (checkBoxSelected === true) {
      selectingAllGroupParts(true)
    } else {
      if (isManuallyDeselected) selectingAllGroupParts(false)
    }
  }, [checkBoxSelected, isManuallyDeselected, selectingAllGroupParts])

  useEffect(() => {
    const atleastOnePartSelected = selectedPartsList.some(
      (part) => part.isSelected === true
    )

    if (atleastOnePartSelected === true) {
      setShowBottomDrawer(true)
    } else {
      setShowBottomDrawer(false)
      setCheckBoxSelected(false)
    }

    const isAllPartsSelected = selectedGroupParts.every((o1) =>
      selectedPartsList.some((o2) => o1.id === o2.id)
    )
    if (isAllPartsSelected) {
      setCheckBoxSelected(isAllPartsSelected)
    } else {
      setCheckBoxSelected(isAllPartsSelected)
      setIsManuallyDeselected(false)
    }
  }, [selectedGroupParts, selectedPartsList, setShowBottomDrawer])

  useEffect(() => {
    setSelectedItems(
      selectedGroupParts.filter((item) => item.isSelected === true).length
    )
    setShowSelectedItemsList(selectedGroupParts as GroupPart[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupParts])

  useEffect(() => {
    if (
      loadingCategories &&
      selectedCategory &&
      onCategoryDeleted === false &&
      onListDeleted === false
    ) {
      getListGroupParts()
    }
  }, [
    selectedCategory,
    getListGroupParts,
    loadingCategories,
    onCategoryDeleted,
    onListDeleted,
  ])

  const onCheckboxChange = (checkBoxSelected?: boolean) => {
    setCheckBoxSelected(!checkBoxSelected)
    if (checkBoxSelected) {
      setIsManuallyDeselected(true)
    } else {
      setIsManuallyDeselected(false)
    }
  }

  const ListHeader = (
    <ListHeaderDesktopView>
      <CheckboxWrapper>
        {!checkBoxSelected ? (
          <SelectAll>
            <Checkbox
              value=""
              label=""
              active={checkBoxSelected}
              onClick={() => setCheckBoxSelected(!checkBoxSelected)}
            />
            <p>
              <I18n name="selectAll" />
            </p>
          </SelectAll>
        ) : (
          <DeselectAll onClick={() => onCheckboxChange(checkBoxSelected)}>
            <CloseIconStyled
              onClick={() => onCheckboxChange(checkBoxSelected)}
            />
            <p>
              <I18n name="deselectAll" />
            </p>
          </DeselectAll>
        )}
      </CheckboxWrapper>
      <PartCountSection>
        <Title>
          <I18n name="group" />
        </Title>
        {`${selectedGroupPartsCount} ${Translate('parts')}`}
      </PartCountSection>
      <HeaderSegment>
        <ActionWrapper
          onClick={() => setShowSortBoxDesktop(!showSortBoxDesktop)}
          ref={sortButtonDesktopRef}
        >
          <ActionText>
            <I18n name="sortGroup" />
          </ActionText>
          {showSortBoxDesktop ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </ActionWrapper>
        <ListSortDropdown
          parentRef={sortButtonDesktopRef}
          show={showSortBoxDesktop}
          setShow={() => setShowSortBoxDesktop(false)}
        />
      </HeaderSegment>
    </ListHeaderDesktopView>
  )

  const tableHeaderRow = (
    <THead>
      <Row>
        <TCell
          style={{
            width: '60px',
            paddingLeft: '25px',
          }}
        >
          <Checkbox
            value=""
            label=""
            active={checkBoxSelected}
            onClick={() => onCheckboxChange(checkBoxSelected)}
          />
        </TCell>
        <TCell
          style={{
            width: '120px',
          }}
        >
          <TitleContainer>
            <I18n name="brand" />
          </TitleContainer>
        </TCell>
        <TCell
          style={{
            width: '100px',
          }}
        >
          <TitleContainer>
            <I18n name="mfr" />
          </TitleContainer>
        </TCell>
        <TCell width="15%">
          <TitleContainer>
            <I18n name="partNum" />
          </TitleContainer>
        </TCell>
        <TCell width="90px">
          <TitleContainer>
            <I18n name="qty" />
          </TitleContainer>
        </TCell>
        <TCell style={{ width: '15%' }}>
          <TitleContainer>
            <I18n name="description" />
          </TitleContainer>
        </TCell>
        <TCell style={{ width: '15%' }}>
          <TitleContainer>
            <I18n name="comments" />
          </TitleContainer>
        </TCell>
        <TCell>
          <TitleContainer>
            <I18n name="lastUsed" />
          </TitleContainer>
        </TCell>
        <TCell style={{ width: '90px' }} />
      </Row>
    </THead>
  )

  const isGroupEmpty = useMemo(
    () => !selectedGroupParts?.length || selectedGroupParts?.length <= 0,
    [selectedGroupParts]
  )

  useEffect(() => {
    if (selectedGroupParts?.length && selectedGroupParts?.length > 0) {
      const requestBody = []
      selectedGroupParts.forEach((item) => {
        requestBody.push({
          partNumber: item.partNumber,
          lineCode: item.lineCode,
        })
      })

      PartExtServiceProvider.getPartAttributesByMultiplesParts(
        requestBody
      ).then((resp) => {
        if (resp && resp.length > 0) {
          setThumbnails(resp)
        }
      })
    }
  }, [selectedGroupParts, setThumbnails])

  const handlePageChange = (page: number, currentPageSize: number) => {
    handlePaginationLimit(page - 1, currentPageSize) // -1 because the API starts counting from 0
    getListGroupParts()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Container>
      {groupPartsLoading === true && <LinearProgress />}
      {isGroupEmpty && <EmptyGroupScreen />}
      {!isGroupEmpty && (
        <TableContainer>
          <>
            {ListHeader}
            <Table>
              {tableHeaderRow}
              <tbody>
                {selectedGroupParts?.map((part) => {
                  return (
                    <ListGroupPartRow
                      part={part}
                      key={part.id}
                      isSelected={part.isSelected}
                      thumbnail={getThumbnailUrl(
                        part?.partNumber,
                        part?.lineCode
                      )}
                    />
                  )
                })}
              </tbody>
            </Table>
            <Footer>
              <Pager
                totalItems={selectedGroupPartsCount}
                itemsPerPageScale={itemsPerPage}
                currentPageSize={itemsPerPage}
                onPageChange={handlePageChange}
                paginationTitle="products"
                pageOptions={listModulePages}
                filterByPage
              />
            </Footer>

            <MoveGroupPartsDrawer />

            <MovePartsSelectionDrawer />

            <CartEditItemDrawer />
          </>
        </TableContainer>
      )}
      <BottomDrawer />
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
`
const TableContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  overflow-x: hidden;
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`
const THead = styled.thead`
  background: ${defaultTheme.colors.rowFeaturedBackground};
  border-top: 1px solid ${defaultTheme.colors.borderTable};
  width: 100%;
  height: 50px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

interface TCellProps {
  width?: string
}

const TCell = styled.td<TCellProps>`
  text-align: left;
  width: ${(props) => (props.width ? props.width : 'auto')};
`

const Row = styled.tr`
  width: 100%;
  box-shadow: inset 0px -1px 0px ${defaultTheme.colors.greyMedium};
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-family: Roboto;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: ${defaultTheme.colors.columnBorder};
`

const Footer = styled.div`
  margin: 20px;
`
const ListHeaderDesktopView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    border-bottom: 1px solid ${defaultTheme.colors.border};
  }
`
const ActionWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${defaultTheme.colors.border};
  border-right: 1px solid ${defaultTheme.colors.border};
  padding-left: 33px;
  padding-right: 33px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    padding: 12px;
  }
`

const ActionText = styled.span`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 5px;
  color: #000000;
  user-select: none;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    text-transform: capitalize;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
`
const HeaderSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

const PartCountSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    display: none;
  }
`
const Title = styled.div`
  font-size: 18px;
  font-family: Oswald, serif;
  font-weight: bold;
  padding: 12px 12px 12px 24px;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    padding: 12px 12px 12px 0px;
  }
`

const CheckboxWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    padding-left: 25px;
  }
`

const SelectAll = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  cursor: pointer;
  p {
    margin: 0px 0px 0px -5px;
    width: 100%;
    /* color: #000; */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`

const DeselectAll = styled(SelectAll)`
  p {
    margin: 0px 0px 0px 0px;
    width: 100%;
  }
`

const CloseIconStyled = styled(CloseIcon)`
  display: none;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    color: #6b6b6b;
  }
`
