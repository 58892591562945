import React, { useEffect, useRef, useState } from 'react'
import { useListsStore } from 'src/store//lists/ListsStore'
import styled from 'styled-components'
import { Overlay } from 'react-bootstrap'
import { Box, Grow } from '@mui/material'
import { useClickOutsideDetector } from 'src/hooks'
import { I18n, Translate } from 'src/i18n'
import { SortOrder, SortOrderItem } from 'src/store/models/SearchModels'
import { zIndex } from 'src/theme/zIndex'
import { isMobile } from 'react-device-detect'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { ReactSelect } from 'src/ui-components/forms/ReactSelect'

interface ListSortDropdownProps {
  show: boolean
  parentRef: React.RefObject<HTMLElement>
  setShow: (show: boolean) => void
}
interface Option {
  label: string
  value: string
}
export const ListSortDropdown: React.FC<ListSortDropdownProps> = ({
  show,
  parentRef,
  setShow,
}) => {
  const [sortBy, setSortBy] = useState<SortOrderItem>()
  const [thenBy, setThenBy] = useState<SortOrderItem>()

  const listStore = useListsStore()
  const { setSortOrder } = listStore

  const overlayRef = useRef(null)
  const sortByRef = useRef(null)
  const thenByRef = useRef(null)

  const sortOptions: SortOrder = [
    { label: Translate('mfrAZ'), property: 'Mfr', dir: 'asc' },
    { label: Translate('mfrZA'), property: 'Mfr', dir: 'desc' },
    { label: Translate('partNumberAZ'), property: 'PartNumber', dir: 'asc' },
    { label: Translate('partNumberZA'), property: 'PartNumber', dir: 'desc' },
    { label: Translate('qtyAZ'), property: 'Qty', dir: 'asc' },
    { label: Translate('qtyZA'), property: 'Qty', dir: 'desc' },
    { label: Translate('lastUsedAZ'), property: 'LastUsed', dir: 'asc' },
    { label: Translate('lastUsedZA'), property: 'LastUsed', dir: 'desc' },
  ]

  useClickOutsideDetector(
    [overlayRef, sortByRef, thenByRef, parentRef],
    show,
    () => setShow(false)
  )

  useEffect(() => {
    const { v3listSortOrder } = StoreInstances.userStore.preferences
    const defaultSortValue =
      v3listSortOrder && v3listSortOrder !== 'null'
        ? v3listSortOrder
        : 'Mfr_0,PartNumber_0'

    const sortDefaultOptions = defaultSortValue
      .split(',')
      .map((it) => it.split('_'))

    if (sortDefaultOptions?.length > 0) {
      const sortPartial = []

      const makeSortByObj = {
        property: sortDefaultOptions[0][0],
        dir: sortDefaultOptions[0][1] === '0' ? 'asc' : 'desc',
      }

      const sortByObj = sortOptions.find(
        (sp) =>
          sp.property === makeSortByObj.property && sp.dir === makeSortByObj.dir
      )

      setSortBy(sortByObj)
      sortPartial.push(sortByObj)

      if (sortDefaultOptions.length > 1) {
        const makeThenByObj = {
          property: sortDefaultOptions[1][0],
          dir: sortDefaultOptions[1][1] === '0' ? 'asc' : 'desc',
        }

        const thenByObj = sortOptions.find(
          (sp) =>
            sp.property === makeThenByObj.property &&
            sp.dir === makeThenByObj.dir
        )

        setThenBy(thenByObj)
        sortPartial.push(thenByObj)
      }
      setSortOrder(sortPartial)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSortByOptions = (): SortOrderItem[] => {
    if (thenBy) {
      const data = sortOptions.filter(
        (option) =>
          !(
            thenBy &&
            option.dir === thenBy.dir &&
            option.property === thenBy.property
          )
      )
      return data
    }
    return []
  }

  const getThenByOptions = (): SortOrderItem[] => {
    if (sortBy) {
      const data = sortOptions.filter(
        (option) =>
          !(
            sortBy &&
            option.dir === sortBy.dir &&
            option.property === sortBy.property
          )
      )
      return data
    }
    return []
  }

  const onChangeSortBy = (value) => {
    setSortBy(value)
    const sortPartial = []
    sortPartial.push(value)
    if (thenBy) {
      sortPartial.push(thenBy)
    }
    setSortOrder(sortPartial)
  }

  const onChangeThenBy = (value) => {
    setThenBy(value)
    const sortPartial = []
    if (sortBy) {
      sortPartial.push(sortBy)
    }
    if (value) {
      sortPartial.push(value)
    }
    setSortOrder(sortPartial)
    // saveSortOrderToCloud(sortPartial)
  }
  return (
    <Overlay
      show={show}
      placement="bottom-end"
      popperConfig={{
        strategy: 'absolute',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: () => {
                if (!isMobile) {
                  return [0, 0]
                } else {
                  return [130, 0]
                }
              },
            },
          },
        ],
      }}
      target={parentRef.current}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars -- Bulk disabling. Fix if possible. */}
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <OuterWrapper
          {...props}
          style={{
            ...props.style,
            zIndex: zIndex.dropdown,
          }}
        >
          <Box>
            <Grow in={show}>
              <Wrapper ref={overlayRef}>
                <FieldWrapper>
                  <Label>
                    <I18n name="sortBy" />
                  </Label>
                  <ReactSelectWrapper>
                    <ReactSelect
                      defaultValue={sortBy}
                      onChange={onChangeSortBy}
                      options={getSortByOptions()}
                      getOptionLabel={(option: unknown) =>
                        (option as Option).label
                      }
                      getOptionValue={(option: unknown) =>
                        (option as Option).label
                      }
                    />
                  </ReactSelectWrapper>
                </FieldWrapper>
                <FieldWrapper>
                  <Label>
                    <I18n name="thenBy" />
                  </Label>
                  <ReactSelectWrapper>
                    <ReactSelect
                      defaultValue={thenBy}
                      onChange={onChangeThenBy}
                      options={getThenByOptions()}
                      getOptionLabel={(option: unknown) =>
                        (option as Option).label
                      }
                      getOptionValue={(option: unknown) =>
                        (option as Option).label
                      }
                    />
                  </ReactSelectWrapper>
                </FieldWrapper>
              </Wrapper>
            </Grow>
          </Box>
        </OuterWrapper>
      )}
    </Overlay>
  )
}

const OuterWrapper = styled.div`
  display: inline-block;
`

const Wrapper = styled.div`
  position: absolute;
  width: 348px;
  right: 0;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  :after {
    content: '';
    width: 214px;
    height: 1px;
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
  }
`

const FieldWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  border-bottom: 1px solid #d3d2cf;
  align-items: center;
`
const Label = styled.span`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #050505;
  margin-right: 5px;
  width: 60px;
`
const ReactSelectWrapper = styled.div`
  width: 240px;
`
